import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  form: FormGroup;
  errorFront:string;
  response:string;

  constructor(public fb: FormBuilder,
              private usersService: UsersService,
              private router:Router) {
    this.form = this.fb.group({
      email: ['']
    })
  }

  submitForm() {
    if(this.form.value.email === ''){
      this.errorFront = 'Por favor complete el e-mail';
      return;
    }

    var formData: any = new FormData();
    formData.append("email", this.form.get('email').value);
    
    this.usersService.forgotPassword(formData).subscribe(
      (response) => {
        this.response = 'Te hemos enviado un e-mail con tu nueva contraseña. Serás redirigido al login.',
        setTimeout(() => {
          this.router.navigate(['/login'])
        }, 5000)
      },
      (error) => {
        this.errorFront = 'E-mail no registrado.';
      }
    )
  }

}
