import { Component, OnInit } from '@angular/core';
import { CartItemDetail } from '../../../models/car.models';
import { Router } from '@angular/router';

//----- services ------//
import { CartService } from '../../../services/cart.service';
import { ProductsService } from 'src/app/services/products.service';
import { Sucursal } from 'src/app/models/sucursal.model';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  public carts    : any;
  public cart     : any;
  public drink_id : number;
  public brands: Array<Sucursal> = [];

  constructor(
    private cart_service : CartService,
    private router: Router,
    private productServices: ProductsService
  ) { }

  ngOnInit(): void {
    let id_saved_temp = []
    let carts         = []

    this.cart_service.getHistorialCart(localStorage.getItem('client_id')).subscribe( data => {
      this.getBrands();
      this.carts = data;
      this.carts.forEach(element => {
        if (!(id_saved_temp.includes(element.cart.id))) { 
          id_saved_temp.push(element.cart.id)
          let item = element;
          let products = [];
          let total    = 0;
          if (item.product){ item.product.quantity = item.quantity ; products.push(item.product); total = item.product.price }
          if (item.combo)  { item.combo.quantity   = item.quantity; products.push(item.combo)  ; total = item.combo.price }
          item.products = products;
          item.total = total;
          carts.push(item) 
        }else{
          
          carts.forEach(item => {
            if (item.cart.id == element.cart.id){
              if (element.product){ 

                if (item.products.filter(e => e.name === element.product.name).length > 0) {
                    item.products.forEach(e => {
                    if (e.name === element.product.name){
                      e.quantity += item.quantity;
                    } 
                    });
                }else{
                  element.product.quantity  = item.quantity;
                  item.products.push(element.product);
                }

                  item.total += element.product.price 
                
              }
              if (element.combo){  

                if (item.products.filter(e => e.name === element.combo.name).length > 0) {
                   item.products.forEach(e => {
                    if (e.name === element.combo.name){
                      e.quantity += item.quantity;
                    } 
                   });
                }else{
                  element.combo.quantity  = item.quantity;
                  item.products.push(element.combo);
                }

                item.total += element.combo.price 
              }
            }
          })

        }
      });
      this.carts = carts;
    });

  }

  private getBrands() {
    this.productServices.getSucursales().subscribe(data =>{
      this.brands = data;
    });
  }

  getApiWhatsapp(phone: string) {
    return  "https://api.whatsapp.com/send?phone=" + phone;
  }

  getDeliveryType(type: number): string {
    return (type === 1)? 'Delivery' : 'Pickup';
  }

  getDeliveryHour(hour: string): string {
    return ((hour.split('.')).length > 1)? this.getHourFormat(hour.split('.')[0]) : this.getHourFormat(hour);
  }

  private getHourFormat(hour: string): string {
    const time = hour.split(':');
    return `${time[0]}:${time[1]}`;
  }

  repeatCart(id_cart){
    
    /*
     description: repeat cart from historial
     only cart, no ingredientes and sauces
    */
    
    let items = []

    this.cart_service.getDetailCartRepeat(id_cart).subscribe(data => {
      this.cart = data;
      
      this.cart.forEach(element => {
        let item  = {}
        item['precio_total'] = 0.0
          if (element['product']){
            item['type']         =  'products';
            item['item_id']      =  element['product']['id'];
            item['nombre']       =  element['product']['name'];
            item['descripcion']  =  element['product']['description'];
            item['precio']       =  element['product']['price'];
            item['quantity']     =  element['quantity'];
            item['observacion']  =  element['cart']['comment'];
            item['drink_id']     =  this.drink_id;
            item['comment']      =  element['cart']['comment'];
            item['precio_total'] =  (element['product']['price'] * element['quantity']);
            items.push(item); // add item to array
          }else{
            item['type'] =  'combos';
            item['item_id']      =  element['combo']['id'];
            item['nombre']       =  element['combo']['name'];
            item['descripcion']  =  element['combo']['description'];
            item['precio']       =  element['combo']['price'];
            item['quantity']     =  element['quantity'];
            item['observacion']  =  element['cart']['comment'];
            item['drink_id']     =  this.drink_id;
            item['comment']      =  element['cart']['comment'];
            item['precio_total'] =  (element['combo']['price'] * element['quantity']);
            items.push(item); // add item to array
          }
          localStorage.setItem('branch', element['cart']['branch']);
          this.cart_service.saveLocalStorage(items, [], []);
          this.router.navigate(['carrito']);  
      });
    })
  
  }




}
