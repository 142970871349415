import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-items-ingredients',
  templateUrl: './items-ingredients.component.html',
  styleUrls: ['./items-ingredients.component.scss']
})
export class ItemsIngredientsComponent implements OnInit {

  @Input() items    : [];
  public items_local: [];

  constructor() {}

  ngOnInit(): void {
    this.items_local = this.items;
    this.items.forEach(element => {
      if (element['ingredient'] == '6'){
        this.items_local = [];
        this.items_local.push(element);
      }
    })
  }

}
