/**
 * Validate
 * @param nameRe
 */
import { FormGroup } from '@angular/forms';

export const checkPasswords = (group: FormGroup) => {
  const passwordControl = group.get('password');
  const confirmPasswordControl = group.get('password_confirm');

  if (!passwordControl || !confirmPasswordControl) {
    return null;
  }

  if (
    confirmPasswordControl.errors &&
    !confirmPasswordControl.errors.passwordMismatch
  ) {
    return null;
  }

  if (passwordControl.value !== confirmPasswordControl.value) {
    confirmPasswordControl.setErrors({ passwordMismatch: true });
    return { passwordMismatch: true };
  } else {
    confirmPasswordControl.setErrors(null);
  }
};
