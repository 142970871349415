import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { checkPasswords } from 'src/app/validators/passwordsMatch.validator';
import { DialogConfirmationComponent } from '../shared/dialog-confirmation/dialog-confirmation.component';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  private id: string;
  private token: string;

  public form: FormGroup;

  public validForm = false;
  public hide = {
    password: true,
    password_confirm: true
  }

  constructor(
    private activatedRouter: ActivatedRoute,
    private userService: UsersService,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.validateToken();
  }

  private initForm() {
    this.form = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        password_confirm: ['', [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: [ checkPasswords ]
      }
    );
  }

  private validateToken() {
    const id = this.activatedRouter.snapshot.paramMap.get('id');
    const token = this.activatedRouter.snapshot.paramMap.get('token');

    this.id = id;
    this.token = token;

    this.userService.recoverPassword(id, token).subscribe(
      (response) => {
        console.log(response);
      },
      () => {
        this.dialogInvalidToken();
      }
    );
  }

  private dialogInvalidToken() {
    const dialog = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'Token invalido',
        message: 'El token ingresado no es valido',
        btnCancel: false
      },
    });

    dialog.afterClosed()
    .subscribe((state: Boolean) => {
      this.router.navigate(['/login/']);
    });
  }

  private dialogPasswordRecovery() {
    const dialog = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'Contraseña actualizada',
        message: 'Tu contraseña ha sido actualizada satisfactoriamente',
        btnCancel: false
      },
    });

    dialog.afterClosed()
    .subscribe((state: Boolean) => {
      this.router.navigate(['/login']);
    });
  }

  submitForm() {
    this.validForm = false;
    if (!this.form.valid) {
      this.validForm = true;
      return;
    }

    var formData: any = new FormData();
    formData.append("password_1", this.form.get('password').value);
    formData.append("password_2", this.form.get('password_confirm').value);

    this.userService.updatePasswordRecovery(this.id, this.token, formData).subscribe(
      (response) => {
        this.dialogPasswordRecovery();
      },
      (error) => {
        this.dialogInvalidToken();
      }
    )
  }
}
