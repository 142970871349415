
<div class="titulo py-1 mb-5">
  <div class="container">
    <h1>{{titulo}}</h1>
  </div>
</div>

<div class="container">
  <div class="row sucursales justify-content-center">
      <div class="col-sm-4 col-12" *ngFor="let sucursal of sucursales">
        <mat-divider></mat-divider>
        <br>
        <h2>{{sucursal.name}} <a target="_blank" href="{{sucursal.url_route}}" class="comollegar"><fa-icon [icon]="faDirections"></fa-icon>(cómo llegar)</a></h2>

        <iframe class="mt-3" [src]="sucursal.url_zone_delivery_map | safe" width="100%" height="200"></iframe>

        <p>{{sucursal.address}}, {{sucursal.location_name}} </p>
        <p><b>Contactanos:</b></p>
        <p>
            <fa-icon *ngIf="sucursal.phone1" [icon]="faWhatsapp"></fa-icon><a target="_blank" href="https://api.whatsapp.com/send?phone={{sucursal.phone1}}&text=Hola%20quisiera%20hacer%20un%20pedido">{{sucursal.phone1}} </a> &nbsp;
            &nbsp;&nbsp;<fa-icon *ngIf="sucursal.phone2" [icon]="faPhone"></fa-icon>{{sucursal.phone2}}
        </p>
        <p>
          <b>Horarios:</b>
        </p>
        
        <p class="dias dias_dissapear" *ngFor="let number of numbers">
            <u>{{sucursal.turnos[number].name}}</u> : de {{ sucursal.turnos[number].hour_from }} a {{ sucursal.turnos[number].hour_to }} los días
            <span *ngIf="sucursal.turnos[number].monday == true">
              Lunes
            </span>
            <span *ngIf="sucursal.turnos[number].tuesday == true">
              Martes
            </span>
            <span *ngIf="sucursal.turnos[number].wednesday == true">
              Miércoles
            </span>
            <span *ngIf="sucursal.turnos[number].thursday == true">
              Jueves
            </span>
            <span *ngIf="sucursal.turnos[number].friday == true">
              Viernes
            </span>
            <span *ngIf="sucursal.turnos[number].saturday == true">
              Sábados
            </span>
            <span *ngIf="sucursal.turnos[number].sunday == true">
              Domingos
            </span>
        </p>  

        <div class="dias_mobile_dissapear" >
          <table class="table table-borderless" >
            <thead> </thead>
            <tbody>
              <tr *ngIf="sucursal.turnos[0].monday == true">
                <th scope="row">Lunes</th>
                <td *ngIf="sucursal.turnos[0]">{{ sucursal.turnos[0].hour_from.split(':')[0] + ':' + sucursal.turnos[0].hour_from.split(':')[1] }} - {{ sucursal.turnos[0].hour_to.split(':')[0] + ':' + sucursal.turnos[0].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[1]">{{ sucursal.turnos[1].hour_from.split(':')[0] + ':' + sucursal.turnos[1].hour_from.split(':')[1] }} - {{ sucursal.turnos[1].hour_to.split(':')[0] + ':' + sucursal.turnos[1].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[2]">{{ sucursal.turnos[2].hour_from.split(':')[0] + ':' + sucursal.turnos[2].hour_from.split(':')[1] }} - {{ sucursal.turnos[2].hour_to.split(':')[0] + ':' + sucursal.turnos[2].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[3]">{{ sucursal.turnos[3].hour_from.split(':')[0] + ':' + sucursal.turnos[3].hour_from.split(':')[1] }} - {{ sucursal.turnos[3].hour_to.split(':')[0] + ':' + sucursal.turnos[3].hour_to.split(':')[1] }}</td>
              </tr>
              <tr *ngIf="sucursal.turnos[0].tuesday == true">
                <th scope="row">Martes</th>
                <td *ngIf="sucursal.turnos[0]">{{ sucursal.turnos[0].hour_from.split(':')[0] + ':' + sucursal.turnos[0].hour_from.split(':')[1] }} - {{ sucursal.turnos[0].hour_to.split(':')[0] + ':' + sucursal.turnos[0].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[1]">{{ sucursal.turnos[1].hour_from.split(':')[0] + ':' + sucursal.turnos[1].hour_from.split(':')[1] }} - {{ sucursal.turnos[1].hour_to.split(':')[0] + ':' + sucursal.turnos[1].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[2]">{{ sucursal.turnos[2].hour_from.split(':')[0] + ':' + sucursal.turnos[2].hour_from.split(':')[1] }} - {{ sucursal.turnos[2].hour_to.split(':')[0] + ':' + sucursal.turnos[2].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[3]">{{ sucursal.turnos[3].hour_from.split(':')[0] + ':' + sucursal.turnos[3].hour_from.split(':')[1] }} - {{ sucursal.turnos[3].hour_to.split(':')[0] + ':' + sucursal.turnos[3].hour_to.split(':')[1] }}</td>
              </tr>
              <tr *ngIf="sucursal.turnos[0].wednesday == true">
                <th scope="row">Miércoles</th>
                <td *ngIf="sucursal.turnos[0]">{{ sucursal.turnos[0].hour_from.split(':')[0] + ':' + sucursal.turnos[0].hour_from.split(':')[1] }} - {{ sucursal.turnos[0].hour_to.split(':')[0] + ':' + sucursal.turnos[0].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[1]">{{ sucursal.turnos[1].hour_from.split(':')[0] + ':' + sucursal.turnos[1].hour_from.split(':')[1] }} - {{ sucursal.turnos[1].hour_to.split(':')[0] + ':' + sucursal.turnos[1].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[2]">{{ sucursal.turnos[2].hour_from.split(':')[0] + ':' + sucursal.turnos[2].hour_from.split(':')[1] }} - {{ sucursal.turnos[2].hour_to.split(':')[0] + ':' + sucursal.turnos[2].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[3]">{{ sucursal.turnos[3].hour_from.split(':')[0] + ':' + sucursal.turnos[3].hour_from.split(':')[1] }} - {{ sucursal.turnos[3].hour_to.split(':')[0] + ':' + sucursal.turnos[3].hour_to.split(':')[1] }}</td>
              </tr>
              <tr *ngIf="sucursal.turnos[0].thursday == true">
                <th scope="row">Jueves</th>
                <td *ngIf="sucursal.turnos[0]">{{ sucursal.turnos[0].hour_from.split(':')[0] + ':' + sucursal.turnos[0].hour_from.split(':')[1] }} - {{ sucursal.turnos[0].hour_to.split(':')[0] + ':' + sucursal.turnos[0].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[1]">{{ sucursal.turnos[1].hour_from.split(':')[0] + ':' + sucursal.turnos[1].hour_from.split(':')[1] }} - {{ sucursal.turnos[1].hour_to.split(':')[0] + ':' + sucursal.turnos[1].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[2]">{{ sucursal.turnos[2].hour_from.split(':')[0] + ':' + sucursal.turnos[2].hour_from.split(':')[1] }} - {{ sucursal.turnos[2].hour_to.split(':')[0] + ':' + sucursal.turnos[2].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[3]">{{ sucursal.turnos[3].hour_from.split(':')[0] + ':' + sucursal.turnos[3].hour_from.split(':')[1] }} - {{ sucursal.turnos[3].hour_to.split(':')[0] + ':' + sucursal.turnos[3].hour_to.split(':')[1] }}</td>
              </tr>
              <tr *ngIf="sucursal.turnos[0].friday == true">
                <th scope="row">Viernes</th>
                <td *ngIf="sucursal.turnos[0]">{{ sucursal.turnos[0].hour_from.split(':')[0] + ':' + sucursal.turnos[0].hour_from.split(':')[1] }} - {{ sucursal.turnos[0].hour_to.split(':')[0] + ':' + sucursal.turnos[0].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[1]">{{ sucursal.turnos[1].hour_from.split(':')[0] + ':' + sucursal.turnos[1].hour_from.split(':')[1] }} - {{ sucursal.turnos[1].hour_to.split(':')[0] + ':' + sucursal.turnos[1].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[2]">{{ sucursal.turnos[2].hour_from.split(':')[0] + ':' + sucursal.turnos[2].hour_from.split(':')[1] }} - {{ sucursal.turnos[2].hour_to.split(':')[0] + ':' + sucursal.turnos[2].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[3]">{{ sucursal.turnos[3].hour_from.split(':')[0] + ':' + sucursal.turnos[3].hour_from.split(':')[1] }} - {{ sucursal.turnos[3].hour_to.split(':')[0] + ':' + sucursal.turnos[3].hour_to.split(':')[1] }}</td>
              </tr>
              <tr *ngIf="sucursal.turnos[0].saturday == true">
                <th scope="row">Sábado</th>
                <td *ngIf="sucursal.turnos[0]">{{ sucursal.turnos[0].hour_from.split(':')[0] + ':' + sucursal.turnos[0].hour_from.split(':')[1] }} - {{ sucursal.turnos[0].hour_to.split(':')[0] + ':' + sucursal.turnos[0].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[1]">{{ sucursal.turnos[1].hour_from.split(':')[0] + ':' + sucursal.turnos[1].hour_from.split(':')[1] }} - {{ sucursal.turnos[1].hour_to.split(':')[0] + ':' + sucursal.turnos[1].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[2]">{{ sucursal.turnos[2].hour_from.split(':')[0] + ':' + sucursal.turnos[2].hour_from.split(':')[1] }} - {{ sucursal.turnos[2].hour_to.split(':')[0] + ':' + sucursal.turnos[2].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[3]">{{ sucursal.turnos[3].hour_from.split(':')[0] + ':' + sucursal.turnos[3].hour_from.split(':')[1] }} - {{ sucursal.turnos[3].hour_to.split(':')[0] + ':' + sucursal.turnos[3].hour_to.split(':')[1] }}</td>
              </tr>
              <tr *ngIf="sucursal.turnos[0].sunday == true">
                <th scope="row">Domingo</th>
                <td *ngIf="sucursal.turnos[0]">{{ sucursal.turnos[0].hour_from.split(':')[0] + ':' + sucursal.turnos[0].hour_from.split(':')[1] }} - {{ sucursal.turnos[0].hour_to.split(':')[0] + ':' + sucursal.turnos[0].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[1]">{{ sucursal.turnos[1].hour_from.split(':')[0] + ':' + sucursal.turnos[1].hour_from.split(':')[1] }} - {{ sucursal.turnos[1].hour_to.split(':')[0] + ':' + sucursal.turnos[1].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[2]">{{ sucursal.turnos[2].hour_from.split(':')[0] + ':' + sucursal.turnos[2].hour_from.split(':')[1] }} - {{ sucursal.turnos[2].hour_to.split(':')[0] + ':' + sucursal.turnos[2].hour_to.split(':')[1] }}</td>
                <td *ngIf="sucursal.turnos[3]">{{ sucursal.turnos[3].hour_from.split(':')[0] + ':' + sucursal.turnos[3].hour_from.split(':')[1] }} - {{ sucursal.turnos[3].hour_to.split(':')[0] + ':' + sucursal.turnos[3].hour_to.split(':')[1] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        
    </div>
  </div>
</div>


<app-button-cart></app-button-cart>
