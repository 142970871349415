import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html'
})
export class SendCodeComponent {
  form: FormGroup;
  error:any; 
  errorPhone:string = '';
  errorFront:string = '';
  response:string;

  constructor(public fb: FormBuilder,
              public router: Router,
              private usersService: UsersService) {
    this.form = this.fb.group({
      phone1_areacode: [''],
      phone1: ['']
    })
  }

  submitForm() {
    if(this.errorPhone.length > 0) {
      return;
    }
    var formData: any = new FormData();
    formData.append("phone1_areacode", this.form.get('phone1_areacode').value);
    formData.append("phone1", this.form.get('phone1').value);

    this.usersService.sendCode(formData).subscribe(
      (response) => {
        this.response = 'Recibirás un código en tu celular.',
        localStorage.setItem('emailRegister', response['email']),
        this.router.navigate(['/verificacion'])
      },
      (error) => {
        this.error = error.error;
        if(error.error.error === 'Phone not found') {
          this.errorFront = 'Número de celular no encontrado.';
        }
      }
    )
  }

  //Phone validation
  areaCode() {
    let input = this.form.controls.phone1_areacode.value;
    if(input[0] === '0') {
      this.errorPhone = 'El código de área no puede empezar con 0.'
    }else if (isNaN(input)){
      this.errorPhone = 'Ingrese caracteres válidos.';
    }else if(input.length < 3) {
      this.errorPhone = 'El código de área no puede ser menor a 3 números.';
    }else {
      this.errorPhone = '';
    }
  }

  cellPhone() {
    let input = this.form.controls.phone1.value;

    if(input[0] === '1' && input[1] == '5') {
      this.errorPhone = 'El número de celular no puede empezar con 15.'
    }else if(isNaN(input)){
      this.errorPhone = 'Ingrese caracteres válidos.';
    }else if(input.length < 7) {
      this.errorPhone = 'El número de celular no puede ser menor a 7 números.';
    }else {
      this.errorPhone = '';
    }
  }

}
