import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product, Category } from '../models/product.model';
import { Observable } from 'rxjs';
import { Sucursal } from '../models/sucursal.model';
import { ProductSingle, Combo, ComboArmado } from '../models/product-single.model';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private httpClient: HttpClient) { }

  //baseUrl:string = "http://127.0.0.1/api/v1"; 

  getAllCategories():Observable<Category[]> {
    return this.httpClient.get<Category[]>(`${environment.baseUrl}/categories`);
  }

  getProductsByCategory(id:number, suc:number):Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${environment.baseUrl}/categories/products-combos/${id}/${suc}`);
  }

  getProductsBySucursal(id:number):Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${environment.baseUrl}/categories/products-combos/0/${id}`);
  }

  getProduct(id:number):Observable<ProductSingle> {
    return this.httpClient.get<ProductSingle>(`${environment.baseUrl}/product/${id}/`);
  }

  getCombo(id:number):Observable<ComboArmado[]> {
    return this.httpClient.get<ComboArmado[]>(`${environment.baseUrl}/combo/${id}/`);
  }

  getSucursales():Observable<Sucursal[]> {
    return this.httpClient.get<Sucursal[]>(`${environment.baseUrl}/sucursales`);
  }

}
