<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Olvidé mi contraseña</h1>
    </div>
</div>
<div class="container mt-5 d-flex justify-content-center">
    <div class="col-12 col-md-7">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="form-group input-group-lg">
                <input type="email" class="form-control" placeholder="E-mail" formControlName="email">
            </div>
            <div class="form-group">
                <div class="error" *ngIf="errorFront">{{errorFront}}</div>
                <div class="my-2 text-success" *ngIf="response">{{response}}</div>
                <button class="btn-rojo">Enviar e-mail de recuperación</button>
                <a class="signup-links" [routerLink]="'/registro'">
                    Aún no tengo cuenta
                </a>
                <a class="signup-links" [routerLink]="'/login'">
                    Iniciar sesión
                </a>
            </div>
        </form>
    </div>
</div>