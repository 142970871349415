import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

/*---------- services -------------*/
import { UsersService } from '../../services/users.service';
import { ClientsService } from '../../services/clients.service';
/*---------------------------------*/

@Component({
  selector: 'app-phones',
  templateUrl: './phones.component.html',
  styleUrls: ['./phones.component.scss']
})
export class PhonesComponent  {

  verify_code: Boolean= false;
  errorPhone:string = '';
  errorCode:string = '';

  phonesForm = new FormGroup({
    phone1_areacode : new FormControl('', [Validators.required]),
    phone1          : new FormControl('', [Validators.required])
  })

  codeForm = new FormGroup({
    code           : new FormControl('', [Validators.required])
  });

  public disableClose: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PhonesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private user_service: UsersService,
    private client_service: ClientsService
  ) {
    if (this.data.disableClose) {
      this.disableClose = this.data.disableClose;
    }

    this.phonesForm.controls['phone1_areacode'].patchValue(this.data['code']);
    this.phonesForm.controls['phone1'].patchValue(this.data['phone']);
    this.verify_code = false;

   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(){
    if(this.errorPhone.length > 0) {
      return;
    }
    if (this.phonesForm.valid){
      let formData = new FormData();
      formData.append('id', localStorage.getItem('client_id'));
      formData.append('index', this.data['index_phones']);
      formData.append('phone1_areacode', this.phonesForm.controls['phone1_areacode'].value)
      formData.append('phone1', this.phonesForm.controls['phone1'].value)
      this.client_service.setClientPhones(formData).subscribe(data => {
        this.verify_code = true;
      },
      error => { this.errorPhone = 'Número de celular ya registrado.'; })
    }else{
      this.phonesForm.controls['phone1_areacode'].markAsDirty();
      this.phonesForm.controls['phone1'].markAsDirty();
    }
  }

  onVerify(){
    if (this.codeForm.valid){
      let formData = new FormData();
      formData.append('email', this.data['email']);
      formData.append('code', this.codeForm.controls['code'].value);
      this.user_service.verify(formData).subscribe(data => {
        window.location.reload(); //se vuelve a recargar la web
      },
      error => { this.errorCode = 'El código no es el correcto'; })
    }
  }

  // ----- Phone validation
  areaCode() {
    let input = this.phonesForm.controls.phone1_areacode.value;
    if(input[0] === '0') {
      this.errorPhone = 'El código de área no puede empezar con 0.'
    }else if (isNaN(input)){
      this.errorPhone = 'Ingrese caracteres válidos.';
    }else if(input.length < 3) {
      this.errorPhone = 'El código de área no puede ser menor a 3 números.';
    }else {
      this.errorPhone = '';
    }
  }

  cellPhone() {
    let input = this.phonesForm.controls.phone1.value;

    if(input[0] === '1' && input[1] == '5') {
      this.errorPhone = 'El número de celular no puede empezar con 15.'
    }else if(isNaN(input)){
      this.errorPhone = 'Ingrese caracteres válidos.';
    }else if(input.length < 7) {
      this.errorPhone = 'El número de celular no puede ser menor a 7 números.';
    }else {
      this.errorPhone = '';
    }
  }

}
