<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Mi cuenta</h1>
    </div>
</div>

<div class="container mt-8 ">
  <h4 class="text-center subtitle title_dissapear" >¡Hola {{ name }} {{ last_name }}! <i class="far fa-thumbs-up"></i> </h4>
    <p class="mt-3 mb-5 text-center title_dissapear">
        Acá podés ver tus pedidos, gestionar tus direcciones de envío y editar tu contraseña y los detalles de tu cuenta.
    </p>

    <div class="menu_mobile_dissapear">
      <button type="button" *ngIf="!my_get && !my_address && !my_history && !my_password" (click)="ShowHistory()" class="btn btn-primary btn-lg btn-block"><i class="fa fa-shopping-cart" aria-hidden="true"></i>
        Mis Pedidos</button>
      <button type="button" *ngIf="!my_get && !my_address && !my_history && !my_password" (click)="ShowAddress()" class="btn btn-primary btn-lg btn-block"><i class="fa fa-map-marker" aria-hidden="true"></i>
        Domicilios</button>
      <button type="button" *ngIf="!my_get && !my_address && !my_history && !my_password" (click)="ShowGet()" class="btn btn-primary btn-lg btn-block"><i class="fa fa-user" aria-hidden="true"></i>
        Mis Datos</button>
      <button type="button" *ngIf="!my_get && !my_address && !my_history && !my_password" (click)="ShowPassword()" class="btn btn-primary btn-lg btn-block"><i class="fa fa-key" aria-hidden="true"></i>
        Contraseña</button>
      <button type="button" *ngIf="my_get || my_address || my_history || my_password" (click)="ShowBack()" class="btn btn-primary btn-lg btn-block"><i class="fa fa-arrow-left"></i>
        Volver</button>
    </div>

    <div class="row menu_access">

      <div class="col">
        <button type="button" (click)="ShowHistory()" class="btn btn-danger"><i class="fa fa-shopping-cart" aria-hidden="true"> </i> Mis Pedidos</button>
      </div>
      <div class="col">
        <button type="button" (click)="ShowAddress()" class="btn btn-danger"><i class="fa fa-map-marker" aria-hidden="true"> </i> Domicilios</button>
      </div>
      <div class="col">
        <button type="button" (click)="ShowGet()"  class="btn btn-danger"><i class="fa fa-user" aria-hidden="true"> </i> Mis Datos</button>
      </div>
       <div class="col">
        <button type="button" (click)="ShowPassword()" class="btn btn-danger"><i class="fa fa-key"> </i> Contraseña</button>
      </div> 

    </div>




    <app-personal *ngIf="my_get"></app-personal>
    <app-address-account *ngIf="my_address"></app-address-account>
    <br><br>   
    <app-change-password *ngIf="my_password"></app-change-password>  
    <app-history *ngIf="my_history"></app-history>
    <!-- <mat-tab-group >

      <mat-tab label="Datos personales">
        <app-personal></app-personal>
      </mat-tab>

      <mat-tab label="Domicilios"> 
        <app-address-account></app-address-account>
      </mat-tab>

      <mat-tab label="Pedidos"> 
        <app-history></app-history>
      </mat-tab>

    </mat-tab-group> -->

    <div class="row justify-content-end button_dissapear">
      <div class="col-12 col-sm-4 mt-5">
        <button class="btn-rojo" id="id_cerrar_session" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Cerrar sesión</button>
      </div>
    </div>
</div>

<div class="d-flex justify-content-center">
  
</div>