<div class="titulo py-1 mb-5">
  <div class="container">
    <h1>Generar nueva contraseña</h1>
  </div>
</div>
<div class="container mt-5 d-flex justify-content-center">
  <div class="col-12 col-md-7">
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <p>Su nueva contraseña debe ser diferente de sus contraseñas anteriores.</p>

        <div class="form-group">
          <div class="input-group input-group-lg mb-3">
            <input formControlName="password" [type]="hide.password ? 'password' : 'text'" class="form-control border-right-0" placeholder="Contraseña">
            <div class="input-group-append">
              <span (click)="hide.password = !hide.password" class="input-group-text bg-transparent">
                <i class="fa fa-eye"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="error" *ngIf="validForm">
          <span *ngIf="form.get('password').hasError('required')">
            El campo contraseña es requerido
          </span>
          <span *ngIf="form.get('password').hasError('minlength')">
            La longitud minima del campo es de 8
          </span>
        </div>


        <div class="form-group">
          <div class="input-group input-group-lg mb-3">
            <input formControlName="password_confirm" [type]="hide.password_confirm ? 'password' : 'text'" class="form-control border-right-0" placeholder="Repetir Contraseña">
            <div class="input-group-append">
              <span (click)="hide.password_confirm = !hide.password_confirm" class="input-group-text bg-transparent">
                <i class="fa fa-eye"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="error" *ngIf="validForm">
          <span *ngIf="form.get('password_confirm').hasError('required')">
            El campo contraseña es requerido
          </span>
          <span *ngIf="form.get('password_confirm').hasError('minlength')">
            La longitud minima del campo es de 8
          </span>
          <span *ngIf="form.get('password_confirm').hasError('passwordMismatch')">
            Las contraseñas deben ser iguales
          </span>
        </div>


        <div class="form-group">
          <button type="submit" class="btn-rojo">Cambiar contraseña</button>
        </div>
      </form>
  </div>
</div>