import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

/*-----services ----- */
import { ClientsService } from '../../services/clients.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {

  /*
  id                    = request.data.get('id', None)
  index                 = request.data.get('index', None)
  address               = request.data.get('address', None)
  dto                   = request.data.get('dto', None)
  floor                 = request.data.get('floor', None)
  address_extra_comment = request.data.get('address_extra_comment', None)
  */

  addressForm = new FormGroup({
    name     : new FormControl(''),
    lastname : new FormControl(''),
    address  : new FormControl('', [Validators.required]),
    dto      : new FormControl(''),
    floor    : new FormControl(''),
    address_extra_comment : new FormControl(''),
    alias: new FormControl('')
  })

  constructor(public dialogRef: MatDialogRef<AddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientsService: ClientsService,
    private _snackBar: MatSnackBar 
    ) { }

  ngOnInit(): void {
    if (this.data['address'] != undefined){
        this.addressForm.controls.address.patchValue(this.data['address'])
        this.addressForm.controls.dto.patchValue(this.data['dto'])
        this.addressForm.controls.floor.patchValue(this.data['floor'])
        this.addressForm.controls.alias.patchValue(this.data['alias'])
        this.addressForm.controls.address_extra_comment.patchValue(this.data['address_extra_comment'])
    }
    
  }


  get controlAddress() {
    return this.addressForm.controls.address;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(){
    if (this.addressForm.valid){
      let formData = new FormData();
      formData.append('id',       localStorage.getItem('client_id'));
      formData.append('index',    this.data['index_address']);
      formData.append('address',  this.addressForm.controls.address.value);
      formData.append('dto'    ,  this.addressForm.controls.dto.value);
      formData.append('floor'  ,  this.addressForm.controls.floor.value);
      formData.append('address_extra_comment'  ,  this.addressForm.controls.address_extra_comment.value);
      formData.append('address_alias'  ,  this.addressForm.controls.alias.value);

      this.clientsService.setClientAddress(formData).subscribe(data => {
        this._snackBar.open(`Tu dirección se cargo con éxito`, 'Cerrar');
        this.dialogRef.close();
      })
    }else{
      this.addressForm.controls['name'].markAsDirty();
      this.addressForm.controls['lastname'].markAsDirty();
      this.addressForm.controls['address'].markAsDirty();
    }
  }

}
