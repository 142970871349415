<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>¡Gracias, tu pedido fue recibido con éxito!</h1>
    </div>
    
</div>

<div class="container mt-10 d-flex justify-content-center ">
    <div class="card shadow-lg my-2 p-3 detail_dissapear" *ngIf="getSubTotal()" >

        <div *ngIf="mp_confirmed" class="row">
            <div class="col-12 col-md-12">
                <div class="alert alert-success" role="alert">
                   Abonado con MercadoPago
                </div>
            </div>
        </div>
       
        <div class="row">
            <div class="col-6 col-md-6">
                <div class="form-group input-group-lg">
                    <h2></h2>
                </div>
                <p class="form-group">
                    <label>Numero de pedido: </label>
                    <b class="product-quantity"> {{ id_cart }}</b>
                </p>
                <p class="form-group">
                    <label>Fecha: </label>
                    <b class="product-quantity"> {{ date_cart  | date: 'shortDate' }} </b>
                </p>
                <p class="form-group">
                    <label>Email: </label>
                    <b class="product-quantity"> {{ email }}</b>
                </p>
            </div>
            <div class="col-6 col-md-6">
                <div class="form-group input-group-lg" style="width: 94%;">
                    <h2>Ante cualquier duda, podés comunicarte con tu sucursal</h2>
                    <a [href]="branch_phone" class="btn btn-success"><i class="fab fa-whatsapp"></i> Contactar con la sucursal</a>
                </div>
                <div class="form-group input-group-lg" style="width: 98%;">
                    <button type="button" (click)="redirect()" class="btn btn-block btn-danger"><i class="fa fa-shopping-cart" aria-hidden="true"> </i> Mis Pedidos</button>
                  </div>
            </div>
        </div>
        <hr style="height:2px;border-width:0;color:gray;background-color:gray"> <!-- line divisor -->
        <div class="row">
            <div class="col-6 col-md-6">
                <div class="form-group input-group-lg">
                    <h2>Detalle del pedido</h2>
                </div>
                <p class="form-group">
                    <b class="product-quantity">Productos</b>
                </p>
                <p *ngFor="let item of items" class="form-group">
                    <b class="product-quantity nameItemweb" *ngIf="item"> {{ item.quantity }} -  {{ item.name }}  </b>
                </p>
               
                <p class="form-group">
                    <b class="product-quantity">Subtotal</b>
                </p>
               
                <p class="form-group" >
                    <b class="product-quantity">Método de pago</b>
                </p>

                <p class="form-group" >
                    <b class="product-quantity">Delivery</b>
                </p>
                
                <p class="form-group">
                    <b class="product-quantity">Total</b>
                </p>

                <p class="form-group"  *ngIf="pay_with != '0'" >
                    <b class="product-quantity detail_items">Abono con</b>
                </p>

                <p class="form-group"  *ngIf="pay_with == '0'" >
                    <b class="product-quantity detail_items">Cambio justo</b>
                </p> 

                <p class="form-group"  >
                    <b class="product-quantity detail_items">Descuento</b>
                </p> 
                
                <p class="form-group"  >
                    <b class="product-quantity detail_items">Hora</b>
                </p> 

                <p class="form-group"  >
                    <b class="product-quantity detail_items">Hora de entrega</b>
                </p> 

                <p *ngIf="comment" class="form-group">
                    <b class="product-quantity">Nota</b>
                </p>
            </div>
            <div class="col-6 col-md-6">
                <div class="form-group input-group-lg">
                    <div class="form-group input-group-lg">
                        <h2><br></h2>
                    </div>

                    <p class="form-group">
                        <b class="product-quantity"><br></b>
                    </p>
                    <p *ngFor="let item of items" class="form-group">
                        <b class="product-quantity nameItemweb" *ngIf="item"> ${{ getCalculatePrice(item.quantity, item.price) }} </b>
                    </p>
                    
                    <p class="form-group" >
                        <b class="product-quantity"> ${{ getSubTotal() }}</b>
                    </p>
                    
                    <p class="form-group" >
                        <b class="product-quantity">{{ payment_method }}</b>
                    </p>

                    <p class="form-group" >
                        <b class="product-quantity">${{ price_delivery }}</b>
                    </p>

                    <p class="form-group" >
                        <b class="product-quantity">${{ getTotal() }}</b>
                    </p>

                    <p class="form-group" *ngIf="pay_with != '0'">
                        <b class="product-quantity"> ${{ pay_with }}</b>
                    </p>

                    <p class="form-group" *ngIf="pay_with == '0'">
                        <b class="product-quantity"> &nbsp;</b>
                    </p>

                    <p class="form-group">
                        <b class="product-quantity">${{  discount  }}
                        </b>
                    </p>

                    <p class="form-group">
                        <b class="product-quantity"> {{ hour }}</b>
                    </p>

                    <p class="form-group">
                        <b class="product-quantity"> {{ hour_delivery }}</b>
                    </p>

                    <p *ngIf="comment" class="form-group">
                        <b class="product-quantity">{{ comment }}
                        </b>
                    </p>
                </div>
            </div>
        </div>
    </div>


    <!-- mobile start-->

    <div *ngIf="getSubTotal()" class="card shadow-lg my-2 p-3 detail_mobile_dissapear">
        <div *ngIf="mp_confirmed" class="row">
            <div class="col-12 col-md-12">
                <div class="alert alert-success" role="alert">
                   Abonado con MercadoPago
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="form-group input-group-lg">
                    <h2></h2>
                </div>
                <p class="form-group">
                    <label>Numero de pedido: </label>
                    <b class="product-quantity"> {{ id_cart }}</b>
                </p>
                <p class="form-group">
                    <label>Fecha: </label>
                    <b class="product-quantity"> {{ date_cart  | date: 'shortDate' }} </b>
                </p>
                <p class="form-group">
                    <label>Email: </label>
                    <b class="product-quantity"> {{ email }}</b>
                </p>
            </div>
            <div class="col-12 col-md-12">
                <div class="form-group input-group-lg" style="width: 50%;">
                    <h2>Ante cualquier duda, podés comunicarte con tu sucursal</h2>
                    <a [href]="branch_phone" class="btn btn-success"><i class="fab fa-whatsapp"></i> Contactarse</a>
                </div>
                <div class="form-group input-group-lg" >
                    <button type="button" (click)="redirect()" class="btn btn-danger"><i class="fa fa-shopping-cart" aria-hidden="true"> </i> Mis Pedidos</button>
                  </div>
            </div>
        </div>
        <hr style="height:2px;border-width:0;color:gray;background-color:gray"> <!-- line divisor -->
        <div class="row">
            <div class="col-9 col-md-6">
                <div class="form-group input-group-lg">
                    <h2>Detalle del pedido</h2>
                </div>
                <p class="form-group">
                    <b class="product-quantity">Productos</b>
                </p>
                <p *ngFor="let item of items" class="form-group">
                    <b class="product-quantity detail_items" *ngIf="item">  {{ item.quantity }} -  {{ item.name }}  </b>
                </p>
               
                <p class="form-group">
                    <b class="product-quantity detail_items">Subtotal</b>
                </p>
               
                <p class="form-group" >
                    <b class="product-quantity detail_items">Método de pago</b>
                </p>

                <p class="form-group"  >
                    <b class="product-quantity detail_items">Delivery</b>
                </p>
                
                <p class="form-group">
                    <b class="product-quantity detail_items">Total</b>
                </p>

                <p class="form-group"  *ngIf="pay_with != '0'" >
                    <b class="product-quantity detail_items">Abono con</b>
                </p>

                <p class="form-group"  *ngIf="pay_with == '0'" >
                    <b class="product-quantity detail_items">Cambio justo</b>
                </p>

                <p class="form-group"  >
                    <b class="product-quantity detail_items">Descuento</b>
                </p>

                <p class="form-group"  >
                    <b class="product-quantity detail_items">Hora</b>
                </p>

                <p class="form-group"  >
                    <b class="product-quantity detail_items">Hora de entrega</b>
                </p>

                <p *ngIf="comment" class="form-group">
                    <b class="product-quantity detail_items">Nota</b>
                </p>
            </div>
            <div class="col-3 col-md-6">
                <div class="form-group input-group-lg">
                    <div class="form-group input-group-lg">
                        <h2><br></h2>
                    </div>

                    <p class="form-group">
                        <b class="product-quantity"><br></b>
                    </p>
                    <p *ngFor="let item of items" class="form-group">
                        <b class="product-quantity detail_items" *ngIf="item"> ${{ getCalculatePrice(item.quantity, item.price) }}  </b>
                    </p>
                    
                    <p class="form-group" >
                        <b class="product-quantity detail_items"> ${{ getSubTotal() }}</b>
                    </p>
                    
                    <p class="form-group" >
                        <b class="product-quantity detail_items">{{ payment_method  }}</b>
                    </p>

                    <p class="form-group" >
                        <b class="product-quantity detail_items"> ${{ price_delivery }}</b>
                    </p>

                    <p class="form-group" *ngIf="getTotal()">
                        <b class="product-quantity detail_items"> ${{ getTotal() }}</b>
                    </p>

                    <p class="form-group" *ngIf="pay_with != '0'">
                        <b class="product-quantity detail_items"> ${{ pay_with }}</b>
                    </p>

                    <p class="form-group" *ngIf="pay_with == '0'">
                        <b class="product-quantity detail_items"> &nbsp;</b>
                    </p>


                    <p class="form-group">
                        <b class="product-quantity">${{  discount  }}
                        </b>
                    </p>
                    

                    <p class="form-group">
                        <b class="product-quantity detail_items">{{ hour }}
                        </b>
                    </p>

                    <p class="form-group">
                        <b class="product-quantity detail_items">{{ hour_delivery }}
                        </b>
                    </p>

                    <p *ngIf="comment" class="form-group">
                        <b class="product-quantity detail_items">{{ comment }}
                        </b>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- mobile end -->



</div>




