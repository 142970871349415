<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Verificación por SMS</h1>
    </div>
</div>
<div class="container mt-5 d-flex justify-content-center">
    <div class="col-12 col-md-7">
        <p>Ingresá tu código de área + número de celular para recibir el código de verificación:</p>
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="row">
                <div class="form-group input-group-lg col-12 col-sm-4">
                    <input pattern="[0-9]{3}" (input)="areaCode()" type="text" maxlength="3" minlength="3" class="form-control" placeholder="Código de área" formControlName="phone1_areacode">
                </div>
                <div class="form-group input-group-lg col-12 col-sm-8">
                    <input (input)="cellPhone()" type="text" maxlength="7" minlength="7" class="form-control" placeholder="Teléfono" formControlName="phone1">
                </div>
            </div>
            <div class="form-group">
                <div class="my-2 text-success" *ngIf="response">{{response}}</div>
                <div class="error" *ngIf="errorPhone">{{errorPhone}}</div>
                <div class="error" *ngIf="error">{{errorFront}}</div>
                <button class="btn-rojo">Enviar código</button>
                <a class="signup-links" [routerLink]="'/registro'">
                    Aún no tengo cuenta
                </a>
            </div>
        </form>
    </div>
</div>