import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

/*----------------phones form --------*/
import { PhonesComponent } from '../../phones/phones.component';


//----- services ------//
import { UsersService } from '../../../services/users.service';
import { ClientsService } from '../../../services/clients.service';


@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {
  
  hide = true;
  hide_pass = false;

  /*------- phones -------*/
  phones = [];
  index_phones = 0;
  /*----------------------*/

  /*----- form -------------------*/
  name_control            = new FormControl('', [Validators.required]);
  last_name_control       = new FormControl('', [Validators.required]);
  email_control           = new FormControl('', [Validators.required, Validators.email]);
  phone1_areacode_control = new FormControl('');
  phone1_control          = new FormControl('');

  breakpoint: number;



  /*------------------------------*/

  /*----- attribute public ------*/
  public client_id:string = '';
  error_message_pass = false;
  error_message_pass_valid = false;
  /*----------------------------*/

  constructor(
    private user_service   : UsersService,
    private client_service : ClientsService,
    private _snackBar      : MatSnackBar,
    public  dialog         : MatDialog
  ) { }

  ngOnInit(): void {
    this.client_id = localStorage.getItem('client_id');
    this.populateInformation(); //populate information basic
    this.breakpoint = (window.innerWidth <= 900) ? 1 : 2;
  }

  populateInformation(){
    this.user_service.getUserInformation(this.client_id).subscribe( data => {
      this.name_control.setValue(data['name'])
      this.last_name_control.setValue(data['lastname'])
      this.email_control.setValue(data['email'])
      this.phone1_areacode_control.setValue(data['phone1_areacode'])
      this.phone1_control.setValue(data['phone1'])
      this.populatePhones(data);
    })
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 900) ? 1 : 1;
    this.breakpoint = (window.innerWidth <= 900) ? 1 : 2;
  }

  populatePhones(data){
      this.phones = []; // clear all address 
      /*if (data.phone3){ 
        this.phones.push({'phone': data.phone3, 'code': data.phone3_areacode}) 
      }else{
        this.index_phones = 3; // save the index - set address
      }*/

      /*if (data.phone2){ 
        this.phones.push({'phone': data.phone2, 'code': data.phone2_areacode}) 
      }else{
        this.index_phones = 2; // save the index - set address
      }*/

      if (data.phone1){ 
        this.phones.push({'phone': data.phone1, 'code': data.phone1_areacode}) 
      }else{
        this.index_phones = 1; // save the index - set address
      }
  }

  savePersonalData(){  
    /*
     "name": "asdasd",
     "lastname": "hermosilla",
     "email": "anonimocenter@gmail.com",
    */
    let formData = new FormData();
    formData.append('name', this.name_control.value);
    formData.append('lastname', this.last_name_control.value);
    formData.append('email', this.email_control.value)
    this.client_service.setClientUpdateDataAccount(formData, localStorage.getItem('client_id'))
      .subscribe(data => {
        this._snackBar.open(`Sus datos se cambiaron con éxito`, 'Cerrar', { duration: 2000 })
        this.populateInformation();
      })
  }

  

  openEdit(index, code, phone): void {
    index += 1;
    const dialogRef = this.dialog.open(PhonesComponent, {
      width: '710px',
      height: '320px',
      data: {
        index_phones: index, 
        code: code, 
        phone: phone, 
        email: this.email_control.value
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openAdd(): void {
    const dialogRef = this.dialog.open(PhonesComponent, {
      width: '710px',
      height: '320px',
      data: {
        index_phones: this.index_phones,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
