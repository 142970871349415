<mat-grid-list [cols]="breakpoint" rowHeight="1:1" (window:resize)="onResize($event)">
    <mat-grid-tile>


      <!-- <mat-card class="img-photo-user">
        <img src="https://www.pngfind.com/pngs/m/212-2129419_png-file-svg-people-change-icon-png-transparent.png" alt="Actualización de datos" width="150" height="150">
      </mat-card>-->
      
      <form class="form_position" >
      
        <mat-form-field  class="example-full-width">
          <mat-label>Nombres</mat-label>
          <input  matInput [formControl]="name_control" [class.is-invalid]="!name_control.valid && name_control.dirty">
          <mat-error *ngIf="name_control.hasError('required')">
            Este campo no puede estar vacio
          </mat-error>
        </mat-form-field>

         <mat-form-field  class="example-full-width">
          <mat-label>Apellido</mat-label>
          <input  matInput [formControl]="last_name_control" [class.is-invalid]="!last_name_control.valid && last_name_control.dirty">
          <mat-error *ngIf="last_name_control.hasError('required')">
            Este campo no puede estar vacio
          </mat-error>
        </mat-form-field>

        <mat-form-field  class="example-full-width">
          <mat-label>Email</mat-label>
          <input  matInput [formControl]="email_control" [class.is-invalid]="!email_control.valid && email_control.dirty">
          <mat-error *ngIf="email_control.hasError('email') && !email_control.hasError('required')">
            Por favor ingrese una dirección valida
          </mat-error>
          <mat-error *ngIf="email_control.hasError('required')">
            Email es <strong>requiredo</strong>
          </mat-error>
        </mat-form-field>  


        <p>Mis teléfonos: </p>   
        <hr style="width:100%;border-width:2;text-align:left;margin-left:0">

        <div class="row">

          <div class="col" *ngFor="let phone of phones; index as i;">
            <label (click)="openEdit(i, phone.code, phone.phone)" style="cursor: pointer;">
                {{ phone.code }} {{ phone.phone }}
            <i class="fa fa-edit icon" ></i>
            </label>
          </div>

        </div>
        
        <br>

    
        <div class="col-6 button_dissapear">
          <button (click)="savePersonalData()"  [disabled]="(!email_control.valid) || (!last_name_control.valid) || (!name_control.valid)" class="btn-rojo">Guardar Datos </button>
        </div>

        <div class="col-12 button_mobile_dissapear">
          <button (click)="savePersonalData()"  [disabled]="(!email_control.valid) || (!last_name_control.valid) || (!name_control.valid)" class="btn-rojo">Guardar Datos </button>
        </div>
        
      </form>


    </mat-grid-tile>
    
    <mat-grid-tile>

       

        
       

    </mat-grid-tile>


  </mat-grid-list>