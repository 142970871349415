<footer  [class.footerAbsolute]="router.url === '/'" class="py-3">
	<div class="container">
			<div class="row d-flex align-items-center">
				<div class="col-4 col-sm-6  border-right">
					<a class="nav-link" [routerLink]="['/subscripcion']"><h4 class="text-right"> Suscribite al newsletter</h4></a>
						  
				</div>
				<div class="col-8 col-sm-6 d-flex justify-content-start">
					<h4 class="text-left text-white mr-3">Seguinos en las redes</h4>
					<ul>
						<li class="list-inline-item"><a href="https://facebook.com/durumarg/" target="_blank"><fa-icon [icon]="faFacebook"></fa-icon></a></li>
						<li class="list-inline-item"><a href="https://www.instagram.com/durumarg/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a></li>
						<li class="list-inline-item"><a href="mailto:durumdelivery@gmail.com" target="_blank"><fa-icon [icon]="faEnvelope"></fa-icon></a></li>
                    </ul>
                </div>
			</div>
		</div>
</footer>