<!-- salsas -->
<dt class="alg-pif-dt multicheck"> &times; Salsas </dt>
<!--
    (1, 'Coleslaw'),
    (2, 'Cebolla con Perejil'),
    (3, 'Tomate'),
    (4, 'Lechuga'),
    (5, 'Papas Fritas'),
    (6, 'Todas')
-->
<div *ngFor="let sauce of items_local">
    <dd class="alg-pif-dd multicheck" *ngIf="sauce.sauce == '5'">Todas</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="sauce.sauce == '4'">Hummus</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="sauce.sauce == '3'">Garlic</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="sauce.sauce == '2'">Spicy</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="sauce.sauce == '1'">Sésamo</dd> 
</div>
