<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Verificación por SMS</h1>
    </div>
</div>
<div class="container mt-5 d-flex justify-content-center">
    <div class="col-12 col-md-7">
        <p>Te enviamos un código de verificación a tu celular, por favor ingresalo a continuación:</p>
        <div *ngIf="loading"  class="fa-3x row justify-content-center align-items-center">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div *ngIf="!loading" class="form-group input-group-lg">
                <input type="hidden" class="form-control" placeholder="E-mail" formControlName="email" [value]="email">
            </div>
            <div *ngIf="!loading"  class="form-group input-group-lg">
                <input type="text" class="form-control" placeholder="Código" formControlName="code">
            </div>
            
            <div class="form-group">
                <div class="my-2 text-success" *ngIf="response">{{response}}</div>
                <div class="error" *ngIf="error">{{errorFront}}</div>
                <button class="btn-rojo">Verificar cuenta</button>
                <a class="signup-links" [routerLink]="'/envio-codigo'">
                    No recibí el código
                </a>
            </div>
        </form>
    </div>
</div>