import { createReducer, on, Action } from '@ngrx/store';

import { 
        load_product, 
        load_product_no_confirmed, 
        remove_product_no_confirmed,
        remove_all_product_no_confirmed
      } from './products.actions';

/*----- models ------*/
import { ProductSingle } from '../../models/product-single.model';

export const initialState     : ProductSingle[] = []
export const initialStateCart : any[] = []


const _productReducer = createReducer(
    initialState,
    on(load_product, (state, { product }) => {
      return product
    }),
);

const _productCartReducer = createReducer(
  initialStateCart,
  on(load_product_no_confirmed, (state, {product}) => {
    return [...state, product]
  }),
  on(remove_product_no_confirmed, (state, {index}) => {
    /*---- remove one item from cart ----*/
    let newState = [...state];
    newState.splice(index, 1);
    return newState
  }),
  on(remove_all_product_no_confirmed, (state) => {
    let newState = [];
    return newState
  })
)


export function productReducer(state, action) {
    return _productReducer(state, action);
}

export function productCartReducer(state, action){
  return _productCartReducer(state, action)
}
