<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Iniciar sesión</h1>
    </div>
</div>
<div class="container mt-5 d-flex justify-content-center">
    <div class="col-12 col-md-7">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="form-group input-group-lg">
                <input type="email" class="form-control" placeholder="Usuario o Email" formControlName="username">
            </div>
            <div class="form-group input-group-lg">
                <input [type]="type" class="form-control" placeholder="Contrasena" formControlName="password">
                <span *ngIf="pass" class="eye" (click)="changePass()"><i class="fa fa-eye-slash"  ></i></span>
                <span *ngIf="!pass" class="eye" (click)="changePass()"><i class="fa fa-eye"  ></i></span>
            </div>
            <div class="form-group">
                <div class="error" *ngIf="errorVerify">Cuenta no verificada, <a [routerLink]="'/envio-codigo'">hacé click acá</a> para verificarla.</div>
                <div class="error" *ngIf="error">{{error}}</div>
                <button class="btn-rojo">Iniciar sesion</button>
                <a class="signup-links" [routerLink]="'/registro'">
                    Aún no tengo cuenta
                </a>
                <a class="signup-links" [routerLink]="'/recuperar'">
                    Olvidé mi contraseña
                </a>
            </div>
        </form>
    </div>
</div>