                       
 <div *ngIf="item.product">

    <mat-card class="example-card detail_item_dissapear">

        <mat-card-header >
          <mat-card-title>{{ item.quantity }} - {{ item.product.name }}</mat-card-title>
          <mat-card-subtitle>{{ item.quantity }} - {{ item.product.name }} - {{ item.product.description }}</mat-card-subtitle>
          <dl>Precio: ${{ item.product.price }}</dl>
        </mat-card-header>
        
        <mat-card-content>
            <div class="form-group input-group-lg">
                <h2>Detalles <span class="small text-muted ml-2 cursor-pointer"></span></h2>
            </div>
            <dl style="font-size:smaller;" >
                <b>  {{ item.product.name }} <p *ngIf="item.product.description">- {{ item.product.description }} </p></b>
                <!-- Salsas -->
                
                <app-items-sauce *ngIf="item.product.sauces" [items]="item.product.sauces" ></app-items-sauce>
                <!-- Ingredientes -->
                <app-items-ingredients *ngIf="item.product.ingredients" [items]="item.product.ingredients"></app-items-ingredients>
        
                <!-- bebidas -->
               <!--<dt class="alg-pif-dt select"> &times; Bebida</dt>
                <dd class="alg-pif-dd select">Coca Cola</dd>&nbsp; -->
            </dl>
        </mat-card-content>

        <mat-card-actions></mat-card-actions>
        <mat-card-footer></mat-card-footer>

    </mat-card>

    <mat-card class="example-card detail_item_mobile_dissapear">

        <mat-card-header >
          <mat-card-title>{{ item.quantity }} {{ item.product.name }}</mat-card-title>
          <mat-card-subtitle>Precio: ${{ item.product.price }}</mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="form-group input-group-lg">
                <h2>Detalles <span class="small text-muted ml-2 cursor-pointer"></span></h2>
            </div>
            <dl style="font-size:smaller;" >
                <b>Producto:  {{ item.product.name }} <p *ngIf="item.product.description">- {{ item.product.description }}</p></b>
                <!-- Salsas -->
                
                <app-items-sauce *ngIf="item.product.sauces" [items]="item.product.sauces" ></app-items-sauce>
                <!-- Ingredientes -->
                <app-items-ingredients *ngIf="item.product.ingredients" [items]="item.product.ingredients"></app-items-ingredients>
        
                <!-- bebidas -->
               <!--<dt class="alg-pif-dt select"> &times; Bebida</dt>
                <dd class="alg-pif-dd select">Coca Cola</dd>&nbsp; -->
            </dl>
        </mat-card-content>

        <mat-card-actions></mat-card-actions>
        <mat-card-footer></mat-card-footer>

    </mat-card>

</div>
<div *ngIf="item.combo">

        <mat-card class="example-card detail_item_dissapear">

            <mat-card-header>
                <mat-card-title>{{ item.quantity }} - {{ item.combo.name }}</mat-card-title>
                <mat-card-subtitle>{{ item.combo.quantity }} - {{ item.combo.name }} <p *ngIf="item.combo.description">- {{ item.combo.description }}</p></mat-card-subtitle>
                <dl>Precio: ${{ item.combo.price }}</dl>
            </mat-card-header>
            
            <mat-card-content>
                <div class="form-group input-group-lg">
                    <h2>Detalles <span class="small text-muted ml-2 cursor-pointer"></span></h2>
                </div>
                <dl style="font-size:smaller;" *ngFor="let i of item.combo.products">
                    <b>Producto:  {{ i.product.name }} <p *ngIf="i.product.description">- {{ i.product.description }}</p></b>
                    <!-- Salsas -->
                    
                    <app-items-sauce *ngIf="i.product.sauces" [items]="i.product.sauces" ></app-items-sauce>
                    <!-- Ingredientes -->
                    <app-items-ingredients *ngIf="i.product.ingredients" [items]="i.product.ingredients"></app-items-ingredients>
        
                    <!-- bebidas -->
                    <!--<dt class="alg-pif-dt select"> &times; Bebida</dt>
                    <dd class="alg-pif-dd select">Coca Cola</dd>&nbsp; -->
                </dl>
            </mat-card-content>

            <mat-card-actions></mat-card-actions>
            <mat-card-footer></mat-card-footer>

        </mat-card>


        <mat-card class="example-card detail_item_mobile_dissapear">

            <mat-card-header>
                <mat-card-title>{{ item.quantity }} - {{ item.combo.name }}</mat-card-title>
                <mat-card-subtitle>Precio: ${{ item.combo.price }}</mat-card-subtitle>
            </mat-card-header>
            
            <mat-card-content>
                <div class="form-group input-group-lg">
                    <h2>Detalles <span class="small text-muted ml-2 cursor-pointer"></span></h2>
                </div>
                <dl style="font-size:smaller;" *ngFor="let i of item.combo.products">
                    <b> {{ i.product.name }} <p *ngIf="i.product.description">- {{ i.product.description }}</p></b>
                    <!-- Salsas -->
                    
                    <app-items-sauce *ngIf="i.product.sauces" [items]="i.product.sauces" ></app-items-sauce>
                    <!-- Ingredientes -->
                    <app-items-ingredients *ngIf="i.product.ingredients" [items]="i.product.ingredients"></app-items-ingredients>
        
                    <!-- bebidas -->
                    <!--<dt class="alg-pif-dt select"> &times; Bebida</dt>
                    <dd class="alg-pif-dd select">Coca Cola</dd>&nbsp; -->
                </dl>
            </mat-card-content>

            <mat-card-actions></mat-card-actions>
            <mat-card-footer></mat-card-footer>

        </mat-card>

</div>

