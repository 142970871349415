// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://panel.staging.com.ar/api/v1',
  //baseUrl: 'http://68.183.104.160/api/v1',
  token_mp: 'TEST-8424311469215060-100109-b4dc15c944496d357b51a73efe5814b7-167340066',
  // baseUrl: 'http://127.0.0.1/api/v1',
  //baseUrl: 'http://sistema.staging.com.ar/api/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
