import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-google-address',
  templateUrl: './google-address.component.html',
  styleUrls: ['./google-address.component.scss']
})
export class GoogleAddressComponent implements OnInit {

  @Input() label: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() restrictCity = true;

  // Rosario Santa fe, Argentina
  private countryCode = 'ar';
  private limitCity = 0.06;
  private centerCity = {
    lat: -32.951223,
    lng: -60.669484
  };

  public optionsAutocomplete: any = {};

  public changeAddresAuto = false;
  public locationSelected = false;

  public changeDefaultValue = false;

  constructor() { }

  ngOnInit(): void {
    if (this.restrictCity) {
      this.setBounds();
    }
  }

  private setBounds() {
    const center = this.centerCity;

    const defaultBounds = {
      north: center.lat + this.limitCity,
      south: center.lat - this.limitCity,
      east: center.lng + this.limitCity + 0.02,
      west: center.lng - this.limitCity,
    };

    this.optionsAutocomplete = {
      componentRestrictions: { country: this.countryCode },
      bounds: defaultBounds,
      strictBounds: true,
    }
  }

  onAddressChange(address: any) {
    if (address.formatted_address) {
      const addressFormated = this.setFormatAddress(address);
      this.control.setValue(addressFormated);
    }
  }

  private setFormatAddress(address: any): string {
    let newFormat = address.name;

    const locality = address.address_components.find((component: any) => {
      return !!component.types.find(localityType => localityType === 'locality');
    });

    const area = address.address_components.find((component: any) => {
      return !!component.types.find(localityType => localityType === 'administrative_area_level_1');
    });

    if (locality) {
      newFormat += ` ${locality.short_name}`;
    }

    if (area) {
      newFormat += `, ${area.short_name}`;
    }

    return newFormat;
  }

}


