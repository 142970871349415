import { createAction, props } from '@ngrx/store';
import { ProductSingle } from '../../models/product-single.model'

export const load_product = createAction(
    '[PRODUCT] load product',
    props<{product:ProductSingle[] }>()
)

export const load_product_no_confirmed = createAction(
    '[PRODUCT] load product_no_confirmed',
    props<{product:any}>()
)

export const remove_product_no_confirmed = createAction(
    '[PRODUCT] remove product_no_confirmed',
    props<{index:number}>()
)

export const remove_all_product_no_confirmed = createAction(
    '[PRODUCT] remove all_product_no_confirmed'
)
