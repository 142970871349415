import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

/*----------------address form --------*/
import {AddressComponent} from '../../address/address.component';

//----- services ------//
import { ClientsService } from '../../../services/clients.service';
// -------------------//
// ------ models ------//
import { Client } from '../../../models/client.models';
import { DialogConfirmationComponent } from '../../shared/dialog-confirmation/dialog-confirmation.component';
// ------------------ //


@Component({
  selector: 'app-address-account',
  templateUrl: './address-account.component.html',
  styleUrls: ['./address-account.component.scss']
})
export class AddressAccountComponent implements OnInit {

  public client:Client;
  private addressDelete: any = null;
  /*-----------------*/
  index_address = 0;
  addresses = [];
  /*---------------------*/

  breakpoint: number;
  
  constructor(
    private client_service : ClientsService,
    private _snackBar: MatSnackBar,
    public  dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.populateAddress();
    this.breakpoint = (window.innerWidth <= 700) ? 1 : 2;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 700) ? 1 : 1;
    this.breakpoint = (window.innerWidth <= 700) ? 1 : 2;
  }

  populateAddress(){
    this.client_service.getClient(localStorage.getItem('client_id')).subscribe( data => {
      this.client = data;
      console.log(data);
      this.addresses = []; // clear all address 
      if (data.address3){ 
        this.addresses.push({'address': data.address3, 'alias': data.address_alias3, 'address_extra_comment': data.address_extra_comment3, 'dto': data.dto3, 'floor': data.floor3, 'email': data.email, 'lastname': data.lastname, 'name': data.name, 'phone': data.phone3,  'areacode': data.phone3_areacode,  'type': data.phone3_type, 'index_address': 3}) 
      }else{
        this.index_address = 3; // save the index - set address
      }

      if (data.address2){ 
        this.addresses.push({'address': data.address2, 'alias': data.address_alias2, 'address_extra_comment': data.address_extra_comment2, 'dto': data.dto2, 'floor': data.floor2, 'email': data.email, 'lastname': data.lastname, 'name': data.name, 'phone': data.phone2,  'areacode': data.phone2_areacode,  'type': data.phone2_type,  'index_address': 2}) 
      }else{
        this.index_address = 2; // save the index - set address
      }

      if (data.address1){ 
        this.addresses.push({'address': data.address1, 'alias': data.address_alias1, 'address_extra_comment': data.address_extra_comment1, 'dto': data.dto1, 'floor': data.floor1, 'email': data.email, 'lastname': data.lastname, 'name': data.name, 'phone': data.phone1,  'areacode': data.phone1_areacode,  'type': data.phone1_type,  'index_address': 1}) 
      }else{
        this.index_address = 1; // save the index - set address
      }

    });
  }

  getAddress(address , floor , dto, alias: string){
    let add = address;
    if (floor != null && floor != 'null' && floor != ''){ add += ' Piso: ' + floor }
    if (dto   != null && dto   != 'null' && dto   != ''){ add += ' Dpto.: ' + dto }
    if (alias   != null && alias   != 'null' && alias   != ''){ add += ' - ' + alias }
    return add;
  }

  editAddress(index: number, address:string, alias: string, dto, floor, address_extra_comment){

    
    const dialogRef = this.dialog.open(AddressComponent, {
      width: '910px',
      height: '470px',
      data: {
        index_address: index,
        alias: alias,
        address      : address,
        dto: dto,
        floor: floor,
        address_extra_comment: address_extra_comment
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateAddress();
    });

  }

  openDialog(): void {

    const dialogRef = this.dialog.open(AddressComponent, {
      width: '910px',
      height: '470px',
      data: {
        index_address: this.index_address
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateAddress();
    });
  }

  openDialogDelete(address: any) {
    const dialog = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'Eliminar domicilio',
        message: '¿Realmente desea elminar el domicilio?'
      },
    });

    dialog.afterClosed()
    .subscribe((state: Boolean) => {
      if (state) {
        this.addressDelete = address;
        this.deleteAddress();
      } else {
        console.log('False');
      }
    });
  }

  private deleteAddress(): void {
    let formData = new FormData();

    formData.append('index', this.addressDelete.index_address);
    formData.append('address', 'test');
    formData.append('id', this.client.id.toString());

    this.client_service.updateClientAddress(formData).subscribe(data => {
      this._snackBar.open(`Dirección eliminada con exito`, 'Cerrar');
      this.populateAddress();
    })
  }

}
