import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// ---- Material
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

// ----- Forms
import { FormControl, FormGroup, Validators } from '@angular/forms';

// ----- Services
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  /*----- attribute public ------*/
  public client_id:string = '';
  error_message_pass = false;
  error_message_pass_valid = false;
  /*----------------------------*/

  hide = true;
  hide_pass = false;


  userForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      password_new: new FormControl('', [Validators.required]),
      password_confirmed: new FormControl('', [Validators.required])
  }); 

  constructor(
    private user_service   : UsersService,
    private _snackBar      : MatSnackBar,
    public  dialog         : MatDialog
  ) { }
  

  ngOnInit(): void {
  }

  submitChangePassword(){
    if (this.userForm.valid){
      if (this.userForm.controls.password_new.value == this.userForm.controls.password_confirmed.value){
        let formData = new FormData();
        formData.append('username',     localStorage.getItem('currentUser'));
        formData.append('password',     this.userForm.controls.password.value);
        formData.append('password_new', this.userForm.controls.password_new.value);

        this.user_service.changePassword(formData).subscribe(data => {

          this.userForm.reset();
          this.error_message_pass       = false;
          this.error_message_pass_valid = false;

          Object.keys(this.userForm.controls).forEach(key => {
            this.userForm.get(key).setErrors(null) ;
          });

          this._snackBar.open(`Tu contraseña se cambio con éxito`, 'Cerrar', { duration: 2000 })
        },
        (error) => {
          //this.error_message_pass_valid = true;
          if (error.error.error == 'Invalid Credentials') {
            this.error_message_pass_valid = true;
          }
        })
    }else{
      this.error_message_pass = true;
    }
  }
}

}
