import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

//----- services ------//
import { ClientsService } from '../../services/clients.service';
import { UsersService } from '../../services/users.service';

/*----- ngrx --------*/
import { Store } from '@ngrx/store';
import { clear_token } from '../../store/auth/auth.actions';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.scss']
})
export class CuentaComponent implements OnInit{

  /*---- get information --------*/
  public client_id  :string;
  public name       :string;
  public last_name  :string;
  /*-----------------------------*/

  /*----- tabs buttons ---------*/
  public my_get     = false;
  public my_address = false;
  public my_history = false;
  public my_password= false;
  /*----------------------------*/

  public disableTabs = false;

  constructor(
    private router         : Router,
    private router_activate: ActivatedRoute,
    fb                     : FormBuilder,
    private client_service : ClientsService,
    private user_service   : UsersService,
    private store          : Store
  ) { }

  ngOnInit(){
    this.client_id = localStorage.getItem('client_id')

    this.user_service.getUserInformation(this.client_id).subscribe( data => {
      this.name      = data['name'];
      this.last_name = data['lastname'];
    });
    this.ShowBack();
    this.router_activate.queryParams.subscribe( (params: any) => {
      if (params.hasOwnProperty("id")){  this.my_history = true;  }

    });
  }

  ShowBack(){
    this.my_address  = false;
    this.my_get      = false;
    this.my_history  = false;
    this.my_password = false;
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');                   // remove token
    localStorage.removeItem('client_id');
    localStorage.clear();
    this.store.dispatch(clear_token());                // remove token from storage
    this.router.navigate(['/'])                        // redirect to home
  }

  ShowGet(){
    this.my_get = true;
    this.my_address  = false;
    this.my_history  = false;
    this.my_password = false;
  }

  ShowAddress(){
    this.my_get = false;
    this.my_address = true;
    this.my_history = false;
    this.my_password = false;
  }

  ShowHistory(){
    this.my_get = false;
    this.my_address = false;
    this.my_history = true;
    this.my_password = false;
  }

  ShowPassword(){
    this.my_get = false;
    this.my_address = false;
    this.my_history = false;
    this.my_password = true;
  }

}
