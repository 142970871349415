import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goPage(url){
    switch (url) {
      case ('carta'):
        this.router.navigate(['/carta/'+ localStorage.getItem('branch')])
        break;
      case ('carrito'):
        this.router.navigate(['/carrito'])
        break;  
      default:
        this.router.navigate(['/finalizar-compra'])
        break;
  }
}

}
