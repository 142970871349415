<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>{{nombre}}</h1>
    </div>
  </div>
<div class="container">
  <div class="row">
    <div class="col-12 col-sm-6">
      <img class="img-fluid" src={{foto}} alt="Click aquí" />
      {{descripcion}}
    </div>
    <div class="col-12 col-sm-5 offset-sm-1 detalles">
        <p class="price">${{precio}}</p>

        <div *ngIf="isProduct">
          <div *ngIf="ingredientes">
            <p><b>Vegetales</b></p>
            
            <ul>
              <mat-checkbox class="example-margin"
              [checked]="allComplete"
              [(ngModel)]="todos_ingredientes"
              (change)="setAll($event.checked, product.id)">
                Todas
              </mat-checkbox>
              <li *ngFor="let ingrediente of ingredientes">
               
                <mat-checkbox 
                *ngIf="ingrediente[0] != 6"
                [(ngModel)]="ingrediente.completed"
                color="warn"
                [value]="ingrediente[0]"
                (ngModelChange)="updateAllComplete()"
                >
                {{ingrediente[1]}}
              </mat-checkbox>
                  
                  </li>
                 
            </ul>
          </div>
          <div *ngIf="salsas">
            <p><b>Salsas</b></p>
            <span>
              <!-- <mat-checkbox
              [checked]="allCompleteSalsas"
              (change)="setAllSalsas($event.checked, product.id)">
                Todas
              </mat-checkbox>-->
            </span>
            <ul>
              <!--<li *ngFor="let salsa of salsas">
                <mat-checkbox 
                  *ngIf="salsa[1] != 'Todas'"
                  [(ngModel)]="salsa.completed"
                  color="warn"
                  [value]="salsa[0]"
                  (ngModelChange)="updateAllComplete()"
                >
                  {{salsa[1]}}
                </mat-checkbox>
               
              </li>-->
              <mat-checkbox
              [(ngModel)]="todos_salsas"
              (change)="setAllSalsas($event.checked, product.id)">
                Todas
              </mat-checkbox>
              <li *ngFor="let salsa of salsas">
                <mat-checkbox  *ngIf="salsa[1] != 'Todas'"
                  [(ngModel)]="salsa.completed"
                  color="warn"
                  [value]="salsa[0]"
                  (ngModelChange)="updateAllComplete()"
                >
                  {{salsa[1]}}
                </mat-checkbox>
               
              </li>
             
            </ul>
          </div>
          <div *ngIf="bebidas">
            <p><b>Bebida</b></p>
            <mat-form-field appearance="outline"  >
              <mat-select  [(ngModel)]="bebida_id" placeholder="Bebida" required>
                <mat-option *ngFor="let bebida of bebidas" [value]="bebida[0]">
                  {{bebida[1]}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!valid_bebida" >
                <strong>Debe seleccionar una bebida</strong>
             </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="is_mixto">
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="varieties_id" placeholder="Variedades" required>
                <mat-option *ngFor="let sabores of sabores_mixto" [value]="sabores[0]">
                  {{sabores[1]}}
                </mat-option>
              </mat-select>
             
            </mat-form-field>
            <mat-error *ngIf="!valid_varieties" >
              <strong>Debe seleccionar una variedad</strong>
           </mat-error>
          </div>
        </div>
        
        <div *ngIf="isCombo">
          <div *ngFor="let product of comboArmado">
            <div *ngIf="product.product.ingredientes">
              <p><b>Vegetales para {{product.product.name}}</b></p>
              <span>
               
              </span>
              <ul>
                <mat-checkbox class="example-margin"
                [checked]="allComplete"
                [(ngModel)]="todos_ingredientes"
                (change)="setAll($event.checked, product.product.id)">
                  Todas
                </mat-checkbox>
                <li *ngFor="let ingrediente of product.product.ingredientes">
                  
                  <mat-checkbox 
                    *ngIf="ingrediente[0] != 6"
                    [(ngModel)]="ingrediente.completed"
                    color="warn"
                    [value]="ingrediente[0]"
                    (ngModelChange)="updateAllComplete()"
                  >
                    {{ingrediente[1]}}
                  </mat-checkbox>
                 
                </li>
               
              </ul>
            </div>
            <div *ngIf="product.product.salsas">
              <p><b>Salsas para {{product.product.name}}</b></p>
              <span>
               
              </span>
              <ul>
                <mat-checkbox
                [(ngModel)]="todos_salsas"
                (change)="setAllSalsas($event.checked, product.product.id)">
                  Todas
                </mat-checkbox>
                <li *ngFor="let salsa of product.product.salsas">
                  <mat-checkbox  *ngIf="salsa[1] != 'Todas'"
                    [(ngModel)]="salsa.completed"
                    color="warn"
                    [value]="salsa[0]"
                    (ngModelChange)="updateAllComplete()"
                  >
                    {{salsa[1]}}
                  </mat-checkbox>
                 
                </li>
               
              </ul>
            </div>
          </div> 
          <div *ngIf="bebidas">
            <p><b>Bebida</b></p>
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="bebida_id" placeholder="Bebida" required>
                <mat-option *ngFor="let bebida of bebidas" [value]="bebida[0]">
                  {{bebida[1]}}
                </mat-option>
              </mat-select>
             
            </mat-form-field>
            <p style="color: #cc3428; position: relative; top: -12px; left: 2px;" *ngIf="!valid_bebida" >
              <strong>Debe seleccionar una bebida</strong>
            </p>
          </div>
          <div *ngIf="is_mixto">
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="varieties_id"  placeholder="Variedades" required>
                <mat-option *ngFor="let sabores of sabores_mixto" [value]="sabores[0]">
                  {{sabores[1]}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <p style="color: #cc3428; position: relative; top: -12px; left: 2px;" *ngIf="!valid_varieties" >
              <strong>Debe seleccionar una variedad</strong>
            </p>

          </div>       
        </div>
        <div class="cantidad my-3 d-flex">
            <span class="more" (click)="more()">+</span>
            <input type="number"  min="1" value="1" readonly #inputN>
            <span class="less" (click)="less()">-</span>
        </div>
        <textarea [(ngModel)]="observacion" rows="2" maxlength="150" placeholder="¿Quieres aclarar algo sobre este producto?"></textarea>
        <button (click)="addCart()" class="btn-rojo mt-3">Añadir al carrito</button>
    </div>
  </div>
</div>

<app-button-cart></app-button-cart>
