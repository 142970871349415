import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-sms-verification',
  templateUrl: './sms-verification.component.html',
  styleUrls: ['../register/register.component.scss']
})
export class SmsVerificationComponent implements OnInit {
  form: FormGroup;
  error:any; 
  errorFront:string;
  email:string;
  response:string;

  loading = false;

  constructor(public fb: FormBuilder,
              public router: Router,
              private usersService: UsersService) {
    this.form = this.fb.group({
      email: [localStorage.getItem('emailRegister')],
      code: ['']
    })
  }

  ngOnInit(): void {
    if(localStorage.getItem('emailRegister')) {
      this.email = localStorage.getItem('emailRegister');
    }else {
      this.router.navigate(['/']);
    }  
  }

  submitForm() {
    var formData: any = new FormData();
    formData.append("email", this.form.get('email').value);
    formData.append("code", this.form.get('code').value);
    this.loading = true;
    this.usersService.verify(formData).subscribe(
      (response) => {
        this.response = 'Cuenta verificada con éxito. Serás redirigido al login.',
        localStorage.removeItem('emailRegister'),
        setTimeout(() => {
          this.router.navigate(['/login'])
        }, 5000)
      },
      (error) => {
        this.error = error.error.error;
        if(error.error.error === 'Code not match.') {
          this.errorFront = 'Código incorrecto.';
          this.loading = false;
        }
      }
    )
  }

}
