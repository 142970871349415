
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { Location } from '@angular/common';

/*--- store ngrx -----*/
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { set_token } from '../../store/auth/auth.actions';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
export class Login2Component implements OnInit {
  
  form: FormGroup;
  error:any;
  errorVerify:boolean;

  type = 'password';
  class_eye = 'fa fa-eye'

  pass:boolean=true;

  constructor(
              public fb: FormBuilder,
              private usersService: UsersService,
              private router:Router,
              private location: Location,
              private store: Store
              ) {
    this.form = this.fb.group({
      username: [''],
      password: [''],
    })
  }
  ngOnInit(): void {
    
  }

  changePass(){
    if (this.pass){
      this.type = 'text'
      this.class_eye = 'fa fa-eye-slash'
      this.pass = false;
    }else{
      this.type = 'password'
      this.class_eye = 'fa fa-eye'
      this.pass = true;
    }
  }

  submitForm() {

    if(this.form.value.username == ''){
      this.error = 'Por favor complete el campo usuario';
      return;
    }else if(this.form.value.password == ''){
      this.error = 'Por favor complete el campo contraseña';
      return;
    }else {
      this.error = '';
    }

    var formData: any = new FormData();
    formData.append("username", this.form.get('username').value);
    formData.append("password", this.form.get('password').value);
    
    this.usersService.login(formData).subscribe(
      (response) => {
        let payload = { token: response['token'], username: this.form.get('username').value }
        this.store.dispatch(set_token({auth: payload}))                      // set auth in ngrx
        localStorage.setItem('token', response['token'])                     // add token local storage
        localStorage.setItem('client_id', response['client_id'])             // add client id local storage
        localStorage.setItem('currentUser', this.form.get('username').value) // add username local storage
        this.router.navigate(['/'])                                          // redirect to home
      },
      (error) => {
        if(error.error.error_msg) {
          this.errorVerify = true;
        }else{
          this.error = 'Usuario y/o contraseña inválidas.';
        }
      }
    )
  }

  

}
