<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Finalizar Compra</h1>
    </div>
</div>

<div class="container mt-8 d-flex justify-content-center" id="scrollId" (window:resize)="onResize($event)">


<div class="card shadow-lg my-2 p-3">

    <app-submenu></app-submenu>

    <div *ngIf="mp_reject" class="row">
        <div class="col-12 col-md-12">
            <div class="alert alert-danger" role="alert">
               Pago rechazado, intente por otro medio.
            </div>
        </div>
    </div>

<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">


<!-- form web start -->

<div class="row"  *ngIf="breakpoint >= 700">
    
    
    <div class="col-6 col-md-6">
            <div class="form-group input-group-lg">
                <h2>Mis direcciones</h2>
            </div>

            <p class="form-row">
                <label>Seleccione una direccion</label>
                
                <select #refSelectAddress (change)="setValuesForm(refSelectAddress.value)" class="form-control" [class.is-invalid]="error_address" data-type="billing" id="" name="">
                        <option value="-1">--- Seleccione una opción ---</option>			
                       		
                        <option *ngFor="let item of addresses; let i = index" [selected]="i == index" [value]="i">{{ getAddress(item.address , item.floor , item.dto, item.alias) }}</option>			
                </select>
                
                <!-- #wcmca_custom_addresses -->
                <br><br><br><h2><a  style="cursor: pointer;" *ngIf="addresses.length < 3 && edit==false"  (click)="openDialog()" data-associated-selector="wcmca_address_select_menu_billing" class ="button wcmca_add_new_address_button"> <i class="fas fa-plus"></i> Agregar nueva direccion </a></h2>
                <br><br><br><h2><a  style="cursor: pointer;" *ngIf="edit==true"  (click)="editAddress()" data-associated-selector="wcmca_address_select_menu_billing" class ="button wcmca_add_new_address_button"> <i class="fas fa-edit"></i> Editar dirección </a></h2>

                <span class="error_address" *ngIf="error_address">{{ error_address_text }}</span>
                <!--<div class="form-group input-group-lg">
                    <label for="wcmca_billing_last_name" class="wcmca_form_label">Nombre&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                    <input type="text" [class.is-invalid]="!checkoutForm.controls.name.valid && checkoutForm.controls.name.dirty" formControlName="name" class="form-control" placeholder="Nombre">
                </div> -->

                <!--<div class="form-group input-group-lg">
                    <label for="wcmca_billing_last_name" class="wcmca_form_label">Apellido&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                    <input type="text" [class.is-invalid]="!checkoutForm.controls.lastname.valid && checkoutForm.controls.lastname.dirty" formControlName="lastname" class="form-control" placeholder="Apellido">
                </div>-->
                
                <!--<div class="form-group input-group-lg">
                    <label for="wcmca_billing_last_name" class="wcmca_form_label">Domicilio&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                    <input type="text" [class.is-invalid]="!checkoutForm.controls.address_extra_comment.valid && checkoutForm.controls.address_extra_comment.dirty" formControlName="address_extra_comment" class="form-control" placeholder="Domicilio">
                </div>-->
                <!--<div class="form-group input-group-lg">
                    <label for="wcmca_billing_last_name" class="wcmca_form_label">Teléfono&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                    <input type="text" [class.is-invalid]="!checkoutForm.controls.phone.valid && checkoutForm.controls.phone.dirty" formControlName="phone" class="form-control" placeholder="Teléfono">
                </div>-->
               
    </div>
    

    <div class="col-6 col-md-6" *ngIf="items">

            <div class="form-group input-group-lg">
                <h2>Tu Pedido <span class="small text-muted ml-2 cursor-pointer"><a  style="cursor: pointer;" (click)="redirectCart()" >MODIFICAR</a></span></h2>
            </div>
            <div class="row"  *ngFor="let item of items"> 
                <div class="col-9">
                    
                    <b class="product-quantity">&times; {{ item.quantity }} </b> {{ item.nombre }} 
                    
                    <dl style="font-size:smaller;">
                        <!-- <dt class="alg-pif-dt multicheck">Elegi hasta 3 Salsas </dt>
                        <dd class="alg-pif-dd multicheck">Spicy (Picante Casero)</dd>
                        <dt class="alg-pif-dt select">Bebida</dt>
                        <dd class="alg-pif-dd select">Coca Cola</dd>&nbsp;-->
                    </dl>
                </div>
                <div class="col-3 pr-0">
                    <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ calculateQuantityTotal(item.precio, item.quantity) }}</span>
                </div>
            </div>

            <div class="row pt-3 mt-3">
                <div class="col-8">SubTotal</div>
                <div class="col-4">
                    <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ removeDecimal(subtotal) }}</span>
                </div>
            </div>

            <div class="row pt-3 mt-3 ">
                <div class="col-12">
                    <input type="text" [(ngModel)]="voucher"  (ngModelChange)="applyVoucherChange()" [ngModelOptions]="{standalone: true}"   class="form-control" placeholder="Cupón de descuento">
                    <div class="error" *ngIf="error_voucher">{{ error_voucher_text }}</div>
                </div>
            </div>

            <div class="row pt-3 mt-3 ">
                <div class="col-8">Descuento</div>
                <div class="col-4">
                    <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ removeDecimal(discount_number_or_percent) }}</span>
                </div>
            </div>

            
                <h2 class="mt-3">Entrega</h2>
                <div class="row p-0">
                    <div class="col-12 envio-r">
                        <tr class="woocommerce-shipping-totals shipping">
                            <th>Envío</th>
                            <td data-title="Envío">
                                <ul id="shipping_method" class="woocommerce-shipping-methods">
                                    <li>
                                        <input type="radio" name="type" (change)="add_or_removeDeliveryPrice(price_delivery)" formControlName="type" value="1" checked="checked" class="shipping_method"  /><label for="shipping_method_0_flat_rate1">  &nbsp; Envío a domicilio: <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ price_delivery }}</span></label>
                                    </li>
                                    <li>
                                        <input type="radio" name="type" (change)="add_or_removeDeliveryPrice(price_delivery)" formControlName="type" value="2"  class="shipping_method"  /><label for="shipping_method_0_local_pickup2"> &nbsp;  Retiro en sucursal: $00.00</label>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </div>
                </div>

                <div class="row pt-3 mt-3 ">
                    <div class="col-8">Total</div>
                    <div class="col-4">
                        <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ removeDecimal(total) }}</span>
                    </div>
                </div>

                <h2 class="mt-3"> Pago</h2>


                <mat-radio-group aria-label="Select an option" [ngModelOptions]="{standalone: true}" [(ngModel)]="paywith_mercadopago_flag" >

                    <div class="row pt-3 mt-3 ">
                        <div class="col-8">
                            <mat-radio-button [value]="false">Efectivo</mat-radio-button>
                        </div>
                    </div>

                    <div class="row pt-3 mt-3 ">
                        <div class="col-8">
                            <mat-radio-button [value]="true">Paga con el medio de pago que prefieras</mat-radio-button>
                        </div>
                        <div class="col-4">
                            <img src="../../../assets/img/mercadopago/mercadopago.png" alt="">
                        </div>
                    </div>
                   
                   
                  </mat-radio-group>


                <app-mercadopago @fadeSlideInOut *ngIf="paywith_mercadopago_flag"></app-mercadopago>




                <div class="row pt-3 mt-3 " *ngIf="!paywith_mercadopago_flag">
                    <div class="col-8">
                        <mat-checkbox class="example-margin"
                        (change)="setPayWith($event.checked)">
                        Abona con cambio justo
                        </mat-checkbox>
                    </div>
                    <div class="col-4">

                    </div>
                </div>

                

                <div class="form-group input-group-lg" >
                    <input *ngIf="!paywith_flag && !paywith_mercadopago_flag" type="number" (keypress)="checkPin($event)" type="number" min="1" step="1"  formControlName="paywith" [class.is-invalid]="!checkoutForm.controls.paywith.valid && checkoutForm.controls.paywith.dirty" class="form-control" placeholder="Con cuanto abonarás (pedido en efectivo)">
                    <mat-error *ngIf="!checkoutForm.controls.paywith.valid && checkoutForm.controls.paywith.dirty" >
                       <strong>Debe ingresar un monto mayor al total</strong>
                    </mat-error>
                </div>

                <div class="form-group input-group-lg">
                    <input type="text" formControlName="comment" class="form-control" placeholder="Notas sobre tu pedido o entrega">
                </div>

                <button type="submit" class="btn-rojo">Realizar el pedido</button>

    </div>

</div>

<!-- form web end -->
</form>

<!-- form mobile start -->
<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
    <div class="row" *ngIf="breakpoint <= 700">
        
        
        <div class="col-12 col-md-12">
                <div class="form-group input-group-lg">
                    <h2>Mis direcciones</h2>
                </div>

                <p class="form-row">
                    <label>Seleccione una direccion</label>
                    
                    <select #refSelectAddress (change)="setValuesForm(refSelectAddress.value)" class="form-control" [class.is-invalid]="error_address" data-type="billing" id="" name="" >
                        <option value="-1" >--- Seleccione una opción ---</option>  
                        <option *ngFor="let item of addresses; let i = index" [selected]="i == index" [value]="i">{{ getAddress(item.address , item.floor , item.dto, item.alias) }}</option>			
                    </select>
                    <span class="error_address_mobile" *ngIf="error_address">{{ error_address_text }}</span>

                    <!-- #wcmca_custom_addresses -->
                    <br><br><br><h2><a  style="cursor: pointer;" *ngIf="addresses.length < 3 && edit==false"  (click)="openDialog()" data-associated-selector="wcmca_address_select_menu_billing" class ="button wcmca_add_new_address_button"> <i class="fas fa-plus"></i> Agregar nueva direccion </a></h2>
                    <br><br><br><h2><a  style="cursor: pointer;" *ngIf="edit==true"  (click)="editAddress()" data-associated-selector="wcmca_address_select_menu_billing" class ="button wcmca_add_new_address_button"> <i class="fas fa-edit"></i> Editar dirección </a></h2>
                </p>

                


                    <!--<div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Nombre&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                        <input type="text" [class.is-invalid]="!checkoutForm.controls.name.valid && checkoutForm.controls.name.dirty" formControlName="name" class="form-control" placeholder="Nombre">
                    </div> -->

                    <!--<div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Apellido&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                        <input type="text" [class.is-invalid]="!checkoutForm.controls.lastname.valid && checkoutForm.controls.lastname.dirty" formControlName="lastname" class="form-control" placeholder="Apellido">
                    </div>-->
                    
                    <!--<div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Domicilio&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                        <input type="text" [class.is-invalid]="!checkoutForm.controls.address_extra_comment.valid && checkoutForm.controls.address_extra_comment.dirty" formControlName="address_extra_comment" class="form-control" placeholder="Domicilio">
                    </div>-->
                    <!--<div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Teléfono&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                        <input type="text" [class.is-invalid]="!checkoutForm.controls.phone.valid && checkoutForm.controls.phone.dirty" formControlName="phone" class="form-control" placeholder="Teléfono">
                    </div>-->
                
        </div>


        <div class="col-12 col-md-12" *ngIf="items">

                <div class="form-group input-group-lg">
                    <h2>Tu Pedido <span class="small text-muted ml-2 cursor-pointer"><a  style="cursor: pointer;" (click)="redirectCart()" >MODIFICAR</a></span></h2>
                </div>
                <div class="row"  *ngFor="let item of items"> 
                    <div class="col-9">
                        
                        <b class="product-quantity">&times; {{ item.quantity }} </b> {{ item.nombre }}
                        
                        <dl style="font-size:smaller;">
                            <!-- <dt class="alg-pif-dt multicheck">Elegi hasta 3 Salsas </dt>
                            <dd class="alg-pif-dd multicheck">Spicy (Picante Casero)</dd>
                            <dt class="alg-pif-dt select">Bebida</dt>
                            <dd class="alg-pif-dd select">Coca Cola</dd>&nbsp;-->
                        </dl>
                    </div>
                    <div class="col-3 pr-0">
                        <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ calculateQuantityTotal(item.precio, item.quantity) }}</span>
                    </div>
                </div>

                <div class="row pt-3 mt-3">
                    <div class="col-8">Subtotal</div>
                    <div class="col-4">
                        <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ removeDecimal(subtotal) }}</span>
                    </div>
                </div>
                

                <div class="row pt-3 mt-3 p">
                    <div class="col-12">
                        <input type="text" [(ngModel)]="voucher" (input)="applyVoucherChange()" [ngModelOptions]="{standalone: true}"   class="form-control" placeholder="Cupón de descuento">
                        <div class="error" *ngIf="error_voucher">{{ error_voucher_text }}</div>
                    </div>
                </div>

                <div class="row pt-3 mt-3 ">
                    <div class="col-8">Descuento</div>
                    <div class="col-4">
                        <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ removeDecimal(discount_number_or_percent) }}</span>
                    </div>
                </div>

                    <div class="col-12">
                        <h2 class="row pt-3 mt-3">Entrega</h2>
                        <div class="row p-0">
                            <div class="col-12 envio-r">
                                <tr class="woocommerce-shipping-totals shipping">
                                    <th>Envío</th>
                                    <td data-title="Envío">
                                        <ul id="shipping_method" class="woocommerce-shipping-methods">
                                            <li>
                                                <input type="radio" name="type" (change)="add_or_removeDeliveryPrice(price_delivery)" formControlName="type" value="1" checked="checked" class="shipping_method"  /><label for="shipping_method_0_flat_rate1">Envío a domicilio: <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ price_delivery }}</span></label>
                                            </li>
                                            <li>
                                                <input type="radio" name="type" (change)="add_or_removeDeliveryPrice(price_delivery)" formControlName="type" value="2"  class="shipping_method"  /><label for="shipping_method_0_local_pickup2">Retiro en sucursal: $00.00</label>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-3 mt-3">
                        <div class="col-8">Total</div>
                        <div class="col-4">
                            <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">&#36;</span>{{ removeDecimal(total) }}</span>
                        </div>
                    </div>

                    
                    <div class="row pt-3 mt-3"></div>

                    <h2 class="mt-3">Pago</h2>

      
                    <mat-radio-group aria-label="Select an option" [ngModelOptions]="{standalone: true}" [(ngModel)]="paywith_mercadopago_flag" >

                        <div class="row pt-3 mt-3 ">
                            <div class="col-8">
                                <mat-radio-button [value]="false">Efectivo</mat-radio-button>
                            </div>
                        </div>
    
                        <div class="row pt-3 mt-3 ">
                            <div class="col-8">
                                <mat-radio-button [value]="true">MercadoPago</mat-radio-button>
                            </div>
                            <div class="col-4">
                                <img src="../../../assets/img/mercadopago/mercadopago.png" alt="">
                            </div>
                        </div>
                       
                       
                      </mat-radio-group>
    
    
                    <app-mercadopago @fadeSlideInOut *ngIf="paywith_mercadopago_flag"></app-mercadopago>

                    <div class="row pt-3 mt-3"></div>

                        <mat-checkbox class="example-margin"
                        (change)="setPayWith($event.checked)" *ngIf="!paywith_mercadopago_flag">
                        Abona con cambio justo
                        </mat-checkbox>


                    <div class="form-group input-group-lg"  *ngIf="!paywith_mercadopago_flag">
                        <input *ngIf="!paywith_flag" type="number" (keypress)="checkPin($event)"   formControlName="paywith" type="number" min="1" step="1" [class.is-invalid]="!checkoutForm.controls.paywith.valid && checkoutForm.controls.paywith.dirty" class="form-control" placeholder="Con cuanto abonarás (pedido en efectivo)">
                        <mat-error *ngIf="!checkoutForm.controls.paywith.valid && checkoutForm.controls.paywith.dirty" >
                        <strong>Debe ingresar un monto mayor al total</strong>
                        </mat-error>
                    </div>

                    <div class="form-group input-group-lg">
                        <input type="text" formControlName="comment" class="form-control" placeholder="Notas sobre tu pedido o entrega">
                    </div>

                    <button type="submit"  class="btn-rojo">Realizar el pedido</button>

        </div>

    </div>

<!-- form mobile end -->
            
    </form>

    </div>
</div>
