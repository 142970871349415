<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Registro</h1>
    </div>
</div>

<div class="container mt-5 d-flex justify-content-center">
    <div class="col-12 col-md-7">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="form-group input-group-lg">
                <input type="text" class="form-control" placeholder="Nombre" formControlName="name">
            </div>
            <div class="form-group input-group-lg">
                <input type="text" class="form-control" placeholder="Apellido" formControlName="lastname">
            </div>
            <div class="form-group input-group-lg">
                <input type="email" class="form-control" placeholder="E-mail" formControlName="email">
            </div>

            <div class="row">
                <div class="col-12 col-md-1 form-group input-group-lg">
                    <h4 style="margin-top: 13px; margin-left: 9px;">0</h4>
                </div>
                <div class="col-12 col-md-3 form-group div_h1" >
                    <input (input)="areaCode"  (input)="areaCode()" style="height: 46px;" type="text" class="form-control code" placeholder="Código de área" formControlName="phone1_areacode">
                </div>
                <div class="col-12 col-md-1 form-group input-group-lg div_h2" >
                    <h4 style="margin-top: 9px; margin-left: 9px;">15</h4>
                </div>
                <div class="col-12 col-md-7 form-group input-group-lg div_phone" >
                    <input (input)="cellPhone()"  type="text" class="form-control phone" placeholder="Teléfono" formControlName="phone1">
                </div>
            </div>

            <div class="form-group input-group-lg div_username" >
                <input type="text" class="form-control" placeholder="Usuario" formControlName="username">
            </div>

            <div class="form-group input-group-lg div_password" >
                <input [type]="type" class="form-control" placeholder="Contraseña" formControlName="password">
                <span *ngIf="pass" class="eye" (click)="changePass()"><i class="fa fa-eye-slash"  ></i></span>
                <span *ngIf="!pass" class="eye" (click)="changePass()"><i class="fa fa-eye"  ></i></span>
            </div>
           
            <div class="form-group div_button_submit" >
                <div class="error" *ngIf="errorPhone">{{errorPhone}}</div>
                <div class="error" *ngIf="errorEmpty">{{errorEmpty}}</div>
                <div class="error" *ngIf="error">{{error}}</div>
                <div class="error" *ngIf="errorFront">{{errorFront}}</div>
                <button class="btn-rojo">Registrarme</button>
                <a class="signup-links" [routerLink]="'/login'">
                    Ya tengo cuenta
                </a>
            </div>
        </form>
    </div>
</div>