<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Carrito</h1>
    </div>
</div>
<div class="container mt-5 cart">
    

    <!-- form web -->
    <mat-card class="form_dissapear">
        <app-submenu *ngIf="token"></app-submenu>
        <div class="row">
            <div class="col-12 col-md-6">
                <h2>Tu pedido</h2>


                <div *ngFor="let item of items; index as i" class="row align-items-center my-2 item-product">
                    <div class="col-3">
                        <p class="product">{{ item.nombre }} </p>
                    </div>
                    <div class="col-2">
                        <p class="cost">
                            ${{ item.precio }}
                        </p>
                    </div>
                    <div class="col-2">
                        <p class="cost">
                            ${{ calculate_sub(item.precio, item.quantity) }} 
                        </p>
                    </div>
                    <div class="col-1 delete">
                        <fa-icon  style="cursor: pointer;" (click)="delete(i)" [icon]="faTrashAlt"></fa-icon>
                    </div>
                    <div class="col-4">
                        <div class="cantidad my-3 d-flex">
                            <span class="more"  (click)="more(i)">+</span>
                            <input type="number" [value]="item.quantity" readonly #inputN>
                            <span class="less"  (click)="less(i)">-</span>
                        </div>
                    </div>
                  
                </div>

               

                <div *ngIf="!items" class="col-12 col-md-8">
                    <p> No tiene pedidos en su Carrito </p>
                </div>
                


            </div>
            <div class="col-12 col-md-6">
                <!-- <h2>Cupón</h2>
                <form>
                    <div class="form-group input-group-lg">
                        <input type="text" [(ngModel)]="voucher" (ngModelChange)="applyVoucherChange()" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Ingresa acá tu código de cupón">
                        <div class="error" *ngIf="error_voucher">{{ error_voucher_text }}</div>
                    </div>
                    <div class="form-group">
                         [hidden]="voucher_applicated"
                        <button (click)="applyVoucher()" [hidden]="voucher_applicated" class="btn-rojo">Aplicar cupón</button>
                    </div>
                </form> -->
                <h2>Total</h2>
                <div class="row">
                    <div class="col-2">
                        <p><b>Subtotal</b></p>
                    </div>
                    <div class="col-8">
                        <p>${{ removeDecimal(total) }}</p>
                    </div>
                </div>
                <button *ngIf="token && items.length > 0" (click)="confirmCart()" class="btn-rojo">Finalizar compra</button>
                <button *ngIf="!token" [routerLink]="['/login2']"  class="btn-rojo">Iniciar sesión o registrarse</button>
            </div>
        </div>
    </mat-card>
     <!-- form web -->


     <!-- form mobile -->
     <mat-card class="form_mobile_dissapear">
        <div class="row mx-auto justify-content-center text-center">
            <div class="col-12 mt-3 ">
                <nav aria-label="breadcrumb" class="second ">
                    <ol class="breadcrumb color-ol">
                        <li  style="cursor: pointer;" class="breadcrumb-item font-weight-bold "><button class="btn-rojo" style="position: relative; top: -6px;" (click)="goPage('carta')" >  <i class="far fa-hand-point-left"></i> <span class="mr-md-3 mr-1"> &nbsp;Volver a la carta</span></button></li>
                        
                    </ol>
                </nav>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12 col-md-6">
                <h2>Tu pedido</h2>


                <div *ngFor="let item of items; index as i" class="row align-items-center my-2 item-product">
                    <div class="col-2">
                        <p class="" style="font-weight: 9px; font-size: 12px;" >{{ item.nombre }} </p>
                    </div>
                    <div class="col-2">
                        <p style="font-weight: 9px; font-size: 12px;">
                            ${{ item.precio }}
                        </p>
                    </div>
                    <div class="col-2">
                        <p style="font-weight: 9px; font-size: 12px;">
                            ${{ calculate_sub(item.precio, item.quantity) }} 
                        </p>
                    </div>

                    <div class="col-1"></div>

                    <div class="col-1 delete" >
                        <fa-icon  style="cursor: pointer;" (click)="delete(i)" [icon]="faTrashAlt"></fa-icon>
                    </div>
                    
                    <div class="col-2">
                        <div class="cantidad my-3 d-flex">
                            <span class="more"  (click)="more(i)">+</span>
                            <input type="number" class="number" [value]="item.quantity" readonly #inputN>
                            <span class="less"  (click)="less(i)">-</span>
                        </div>
                    </div>

                    
                  
                </div>

               

                <div *ngIf="!items" class="col-12 col-md-8">
                    <p> No tiene pedidos en su Carrito </p>
                </div>
                


            </div>
            <div class="col-12 col-md-6">
                <!-- <h2>Cupón</h2>
                <form>
                    <div class="form-group input-group-lg">
                        <input type="text" [(ngModel)]="voucher" (ngModelChange)="applyVoucherChange()" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Ingresa acá tu código de cupón">
                        <div class="error" *ngIf="error_voucher">{{ error_voucher_text }}</div>
                    </div>
                    <div class="form-group">
                         [hidden]="voucher_applicated"
                        <button (click)="applyVoucher()" [hidden]="voucher_applicated" class="btn-rojo">Aplicar cupón</button>
                    </div>
                </form> -->
                <h2>Total</h2>
                <div class="row">
                    <div class="col-2">
                        <p><b>Subtotal</b></p>
                    </div>
                    <div class="col-8">
                        <p style="position: relative; left: 35px;">${{ removeDecimal(total) }}</p>
                    </div>
                </div>
                <button *ngIf="token && items.length > 0" (click)="confirmCart()" class="btn-rojo">Finalizar compra</button>
                <button *ngIf="!token" [routerLink]="['/login2']"  class="btn-rojo">Iniciar sesión o registrarse</button>
            </div>
        </div>
    </mat-card>

    <!-- form mobile -->



</div>

