
   <div class="card shadow-lg my-2 p-3">
      <button *ngIf="!disableClose" (click)="onNoClick()"  class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>

        <form [formGroup]="phonesForm" (ngSubmit)="onSubmit()" [hidden]="verify_code">

            <div class="row">

                <div class="col-12 col-md-12">

                        <div class="form-group input-group-lg">
                            <label for="wcmca_billing_last_name" class="wcmca_form_label">Cód. Área&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                            <input pattern="[0-9]{3}" (input)="areaCode()" type="text" maxlength="4" minlength="3" type="text" [class.is-invalid]="!phonesForm.controls.phone1_areacode.valid && phonesForm.controls.phone1_areacode.dirty" formControlName="phone1_areacode" class="form-control" placeholder="Código de área">
                        </div>

                        <div class="form-group input-group-lg">
                            <label for="wcmca_billing_last_name" class="wcmca_form_label">Número&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                            <input type="text" (input)="cellPhone()" maxlength="7" minlength="7" [class.is-invalid]="!phonesForm.controls.phone1.valid && phonesForm.controls.phone1.dirty" formControlName="phone1" class="form-control" placeholder="Número">
                        </div>

                        <div class="form-group input-group-lg">
                            <div class="error" *ngIf="errorPhone">{{errorPhone}}</div>
                        </div>

                        <button type="submit" style="width: 150px; height: 40px;" class="btn-rojo">Guardar</button>

                </div>

            </div>

        </form>

        <form [formGroup]="codeForm" (ngSubmit)="onVerify()" [hidden]="!verify_code" >

            <div class="row">

                <div class="col-12 col-md-12">

                        <div class="form-group input-group-lg">
                            <label for="wcmca_billing_last_name" class="wcmca_form_label">Código&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>
                            <input type="text" [class.is-invalid]="!codeForm.controls.code.valid && codeForm.controls.code.dirty" formControlName="code" class="form-control" placeholder="Ingrese el código">
                        </div>

                        <div class="form-group input-group-lg">
                            <div class="error" *ngIf="errorCode">{{ errorCode }}</div>
                        </div>

                        <button type="submit" style="width: 150px; height: 40px;" class="btn-rojo">Verificar</button>

                </div>

            </div>

        </form>

    </div>
