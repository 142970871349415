import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CartService } from '../../services/cart.service';
import { BranchesService } from '../../services/branches.service';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-finish-cart',
  templateUrl: './finish-cart.component.html',
  styleUrls: ['./finish-cart.component.scss']
})
export class FinishCartComponent implements OnInit {

  /*--- data cart ---*/
  id_cart:        any    = 0 ;  // id from cart 
  date_cart:      any    = '';  // date from cart  
  email:          string = '';  // email from client
  pay_with:       string = '';  // pay with from client
  payment_method: string = '';  // method pay with from client
  id_branch:      number = 0 ;  // id from branches 
  sub_total:      any    = 0 ;
  total_history:  number = 0 ; // this get history price from ticket
  comment:        string = '';
  price_delivery         = 0.0; // price for delivery
  
  total_price:    any = 0.0; // total price 
  hour:           string = '';
  hour_delivery:  string = '';
  discount:       any = 0.0;
  /*------*/

  /*--- data branch ---*/
  branch_phone = '';  
  /*------*/

  /*--- products or combo ---*/
  product_or_combo: any = '';
  quantity:         number = 0;
  /*------*/

  /*---- items detail ----*/
  items = [];
  items_discount = [];
  /*---- items detail ----*/

  /*---- mercadoPago -----*/
  mp_confirmed: boolean = false;
  /*----------------------*/

  constructor(
    private activated        :  ActivatedRoute,
    private cart_service     :     CartService,
    private branches_service : BranchesService,
    private settings_service : SettingsService,
    private cartService      : CartService,
    private router           : Router
  ) { }

  ngOnInit(): void {
    
    this.activated.params.subscribe(params => {
      this.id_cart = params['id'];
      this.populateCart(); // populate cart principal 
    })

    this.activated.queryParams.subscribe(params => {
          
          if (params.collection_status == 'approved'){
            this.mp_confirmed = true
            this.cartService.mercadoPagoConfirm(
              this.id_cart, 
              params.payment_id
              ).subscribe(data => {  })
          }

          this.cartService.removeLocalStorage();
    });
  }

  populateCart(){
    this.settings_service.getPriceDelivery().subscribe(data => {
      this.price_delivery = data['price_delivery'];
      
    })

    this.cart_service.getDetailCart(this.id_cart).subscribe(data => {
     
      this.date_cart        = data[0]['created']
      this.email            = data[0]['cart_item']['cart']['client']['email'];
      this.pay_with         = data[0]['cart_item']['cart']['paywith'];
      this.discount         = data[0]['cart_item']['cart']['discount'];
      this.payment_method   = data[0]['cart_item']['cart']['payment_method'];
      this.id_branch        = data[0]['cart_item']['cart']['branch'];
      this.comment          = data[0]['cart_item']['cart']['comment'];
      this.hour             = data[0]['created'].split('T')[1].split('.')[0]
      this.total_history    = data[0]['cart_item']['cart']['total_history']
      this.product_or_combo = data;

      var currentDate    = new Date();
      var futureDate     = new Date(currentDate.getTime() + parseInt(localStorage.getItem('delivery_time'))*60000);
      this.hour_delivery = futureDate.getHours() + ':' + futureDate.getMinutes() + ':' + futureDate.getSeconds();

     this.product_or_combo.forEach(element => {
       if (element.cart_item.product){
        let exists = false;
        this.items_discount.push({name: element.cart_item.product.name, quantity: element.cart_item.quantity, precio_total : element.cart_item.product.price * (element.cart_item.quantity)  , categories : element.cart_item.product.categories }) 
        this.items.forEach(item => {if (item.name == element.cart_item.product.name) exists = true;})
        if (!(exists)) { 
          this.items.push({name: element.cart_item.product.name, quantity: element.cart_item.quantity, price : element.cart_item.product.price  }) 
          this.sub_total += element.cart_item.product.price * element.cart_item.quantity;
        }
       }else{
        let exists = false;
        
        this.items_discount.push({name: element.cart_item.combo.name, quantity: element.cart_item.quantity, precio_total : element.cart_item.combo.price * (element.cart_item.quantity) , categories : element.cart_item.combo.categories   })
        
        this.items.forEach(item => { if (item.name == element.cart_item.combo.name) exists = true; })
        if (!(exists)) { 
          this.items.push({name: element.cart_item.combo.name, quantity: element.cart_item.quantity, price : element.cart_item.combo.price  })
          this.sub_total += element.cart_item.combo.price * element.cart_item.quantity;
        }
       }
     });

     this.populateBranch();
    })
  }

  redirect(){
    this.router.navigate(['/mi-cuenta'], {queryParams: {id:  localStorage.getItem('client_id')}})
  }

  getCalculatePrice(quantity, price){
    let result = quantity * price;
    return parseFloat(result.toString()).toFixed(2)
  }

  populateBranch(){
    this.branches_service.getDetailBranch(this.id_branch).subscribe(data => {
      this.branch_phone =  "https://api.whatsapp.com/send?phone=" + data['phone1'];
    })
  }


  getSubTotal(){
    if (this.sub_total){
      return parseFloat(this.sub_total.toString()).toFixed(2)
    }
  }

  getTotal(){
    this.total_price = (parseFloat(this.sub_total) + parseFloat(this.price_delivery.toString())) - parseFloat(this.discount)
    return parseFloat(this.total_price.toString()).toFixed(2)
  }

}
