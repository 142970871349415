import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent implements OnInit {

  constructor(
    public dialogo: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.btnCancel === undefined) {
      this.data.btnCancel = true;
    }
   }

  onClose(): void {
    this.dialogo.close(false);
  }

  onAccept(): void {
    this.dialogo.close(true);
  }

  ngOnInit() {
  }

}
