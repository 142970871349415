

<div class="row pt-3 mt-3 ">
    <div class="col-8">TARJETAS DE CRÉDITO</div>
    <div class="col-4 label_subtitle form_dissapear">
        HASTA 24 CUOTAS
    </div>
</div>


<div class="row pt-3 mt-3 row_card">
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/visa.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/master.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/amex.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/naranja.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/nativa.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/tarshop.gif">
    </div>
</div>



<div class="row pt-3 mt-3 row_card">
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/cabal.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/cencosud.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/diners.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/argencard.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/1064.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/credito/cordobesa.gif">
    </div>
</div>



<div class="row pt-3 mt-3 ">
    <div class="col-8">TARJETAS DE DÉBITO</div>
    <div class="col-4">
    </div>
</div>


<div class="row pt-3 mt-3 row_card">
    <div class="col-2">
        <img width="25px" height="25px" src="../../../assets/img/mercadopago/debito/1.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/debito/2.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/debito/3.gif">
    </div>
    <div class="col-2">
        <img src="../../../assets/img/mercadopago/debito/4.gif">
    </div>
   
</div>


<div class="row pt-3 mt-3 row_card row_image form_dissapear">
    <div class="col-3"></div>
    <div class="col-8">
        <img  src="../../../assets/img/mercadopago/redirect_checkout.png">
    </div>
    <div class="col-1"></div>
    <label class="sub_title_mp">Te Llevamos a nuestro sitio para completar el pago</label>
</div>