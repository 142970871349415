import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MercadopagoComponent } from './mercadopago.component';

@NgModule({
  declarations: [
    MercadopagoComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MercadopagoComponent
  ]
})
export class MercadopagoModule { }
