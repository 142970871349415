<mat-grid-list [cols]="breakpoint" rowHeight="1:1" (window:resize)="onResize($event)">
    <mat-grid-tile>
      
      
      <mat-list role="list" class="list_address">
       
        <mat-list-item role="listitem" *ngFor="let address of addresses; let i = index">
          <label class="label-address">
            {{ getAddress(address.address , address.floor , address.dto, address.alias) }}
            <div class="d-inline-block ml-3" (click)="editAddress(address.index_address, address.address, address.alias, address.dto, address.floor, address.address_extra_comment)">
              <i class="fa fa-edit icon" ></i>
            </div>
            <div class="d-inline-block ml-2" (click)="openDialogDelete(address)">
              <i class="fa fa-trash icon" ></i>
            </div>
            <div>
              <p class="p_down">{{ address.address_extra_comment }}</p>
            </div>
          </label>
        
        </mat-list-item>
        
        <mat-list-item role="listitem">
          <label class="label-address button_mobile_dissapear"> <a *ngIf="addresses.length < 3" (click)="openDialog()" data-associated-selector="wcmca_address_select_menu_billing" class ="button wcmca_add_new_address_button"> <i class="fas fa-plus icon"></i> Agregar nueva dirección</a></label>
        </mat-list-item>

      </mat-list>

    </mat-grid-tile>
    
    <mat-grid-tile>
      <h4 class="label-address-title button_dissapear"> <a *ngIf="addresses.length < 3" (click)="openDialog()" data-associated-selector="wcmca_address_select_menu_billing" class ="button wcmca_add_new_address_button"> <i class="fas fa-plus icon"></i> Agregar nueva dirección</a></h4>
    </mat-grid-tile>

  </mat-grid-list>