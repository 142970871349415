import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient) { }

  //baseUrl:string = "http://127.0.0.1/api/v1/clients/"; 

  login(formData) {
    return this.httpClient.post(`${environment.baseUrl}/clients/login`, formData);
  }  
  register(formData) {
    return this.httpClient.post(`${environment.baseUrl}/clients/register`, formData);
  }
  verify(formData) {
    return this.httpClient.post(`${environment.baseUrl}/clients/verify-phone-code`, formData);
  }
  sendCode(formData) {
    return this.httpClient.post(`${environment.baseUrl}/clients/send-verify-phone-code`, formData);
  }
  forgotPassword(formData) {
    return this.httpClient.post(`${environment.baseUrl}/clients/forgot-password`, formData);
  }

  recoverPassword(id: string, token: string) {
    return this.httpClient.get(`${environment.baseUrl}/clients/change-password/${id}/${token}/`);
  }

  updatePasswordRecovery(id: string, token: string, formData: any) {
    return this.httpClient.post(`${environment.baseUrl}/clients/change-password/${id}/${token}/`, formData);
  }

  getUserInformation(id_client:string){
    return this.httpClient.get(`${environment.baseUrl}/user/account/${id_client}`)
  }
  changePassword(formData){
    return this.httpClient.post(`${environment.baseUrl}/clients/password/update`, formData)
  }
  
}
