<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Newsletter</h1>
    </div>
</div>
<div class="container mt-5 d-flex justify-content-center">
    <div class="col-12 col-md-7">
        <p class="text-center">Suscribete a nuestro Newsletter!</p>
        <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()" >
            <div class="form-group input-group-lg">
                <input type="text" [class.is-invalid]="!subscriptionForm.controls.name.valid && subscriptionForm.controls.name.dirty" formControlName="name" class="form-control" placeholder="Nombre">
            </div>
            <div class="form-group input-group-lg">
                <input type="email" [class.is-invalid]="!subscriptionForm.controls.email.valid && subscriptionForm.controls.email.dirty" formControlName="email" class="form-control" placeholder="E-mail">
            </div>
           
            <div class="form-group">
                <button [disabled]="!subscriptionForm.valid"  class="btn-rojo">Enviar</button>
            </div>
        </form>
    </div>
</div>


<app-button-cart></app-button-cart>
