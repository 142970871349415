<!--<button  class="close" (click)="onNoClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
</button> -->
   <div class="card shadow-lg my-2 p-3 p-md-5">

        <form [formGroup]="addressForm" (ngSubmit)="onSubmit()"> 
            <div class="row">

                <div class="col-12">
                    <div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Ej: Casa, Trabajo, etc.</label>
                        <input type="text" formControlName="alias" class="form-control" placeholder="Ej: Casa, Trabajo, etc.">
                    </div>
                </div>

                <div class="col-12">
                    <app-google-address [control]="controlAddress" label="Domicilio"></app-google-address>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Departamento</label>
                        <input type="text" formControlName="dto" class="form-control" placeholder="Departamento">
                    </div>
                </div>

                <div class="col-12 col-md-6" >

                    <div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Piso</label>
                        <input type="number" formControlName="floor" class="form-control" placeholder="Piso">
                    </div>

                </div>

                <div class="col-12 col-md-12"  >
                    <div class="form-group input-group-lg">
                        <label for="wcmca_billing_last_name" class="wcmca_form_label">Comentario</label>
                        <input type="text" formControlName="address_extra_comment" class="form-control" placeholder="Ingrese algun comentario">
                    </div>
                </div>

                <div class="col-12 col-md-4">
                    <button type="submit" style="height: 40px;" class="btn-rojo">Guardar</button>
                </div>
            </div>
        </form>
    </div>

