import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MercadopagoModule } from './components/mercadopago/mercadopago.module'

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { AppComponent } from './app.component';
import { CartaComponent } from './components/food-menu/carta.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/nav/menu.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonsModule } from './commons/commons.module';
import { MaterialModule } from './commons/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SucursalesComponent, SafePipe } from './components/sucursales/sucursales.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProductoSingleComponent } from './components/product-single/producto-single.component';
import { CuentaComponent } from './components/account/cuenta.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SmsVerificationComponent } from './components/sms-verification/sms-verification.component';
import { SendCodeComponent } from './components/send-code/send-code.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContactComponent } from './components/contact/contact.component';
import { SubscriptorsComponent } from './components/subscriptors/subscriptors.component';
import { SubmenuComponent } from './components/shared/submenu/submenu.component';
import { AddressComponent } from './components/address/address.component';
import { MsgBranchRepeatComponent } from './components/shared/msg-branch-repeat/msg-branch-repeat.component';
import { ButtonCartComponent } from './components/shared/button-cart/button-cart.component';
import { Login2Component } from './components/login2/login2.component';
import { PersonalComponent } from './components/account/personal/personal.component';
import { HistoryComponent } from './components/account/history/history.component';
import { AddressAccountComponent } from './components/account/address-account/address-account.component';
import { PhonesComponent } from './components/phones/phones.component';
import { DetailCartComponent } from './components/account/detail-cart/detail-cart.component';
import { ItemsSauceComponent } from './components/account/detail-cart/items-sauce/items-sauce.component';
import { ItemsIngredientsComponent } from './components/account/detail-cart/items-ingredients/items-ingredients.component';
import { DetailItemComponent } from './components/account/detail-cart/detail-item/detail-item.component';
import { FinishCartComponent } from './components/finish-cart/finish-cart.component';

// Input Google maps
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleAddressComponent } from './components/google-address/google-address.component';

//NGRX
import { StoreModule } from '@ngrx/store';
import { appReducers } from './app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ChangePasswordComponent } from './components/account/change-password/change-password.component';
import { DialogConfirmationComponent } from './components/shared/dialog-confirmation/dialog-confirmation.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PermissionsInterceptor } from './interceptors/permissions.interceptor';
import { PhoneRequiredMessageComponent } from './components/phone-required-message/phone-required-message.component';


registerLocaleData(es);


@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    CartaComponent,
    HomeComponent,
    MenuComponent,
    SucursalesComponent,
    FooterComponent,
    ProductoSingleComponent,
    CuentaComponent,
    LoginComponent,
    RegisterComponent,
    SmsVerificationComponent,
    SendCodeComponent,
    ForgotPasswordComponent,
    CartComponent,
    CheckoutComponent,
    ContactComponent,
    SubscriptorsComponent,
    SubmenuComponent,
    AddressComponent,
    ButtonCartComponent,
    Login2Component,
    PersonalComponent,
    HistoryComponent,
    AddressAccountComponent,
    PhonesComponent,
    DetailCartComponent,
    ItemsSauceComponent,
    ItemsIngredientsComponent,
    DetailItemComponent,
    FinishCartComponent,
    MsgBranchRepeatComponent,
    ChangePasswordComponent,
    GoogleAddressComponent,
    DialogConfirmationComponent,
    RecoverPasswordComponent,
    PhoneRequiredMessageComponent
  ],
  entryComponents: [AddressComponent, DialogConfirmationComponent,  PhonesComponent, PhoneRequiredMessageComponent, MsgBranchRepeatComponent],
  providers: [
    { provide: LOCALE_ID, useValue: "es-ES" }, //your locale
    { provide: HTTP_INTERCEPTORS, useClass: PermissionsInterceptor, multi: true },
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    CommonsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MercadopagoModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    GooglePlaceModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
