
<div class="row">
  <div class="col-12">
    <h1 class="text-center">Cuenta no verificada</h1>
  </div>

  <div class="col-12 mt-3 text-center">
    <p class="text-center h4">Por favor agrega un número teléfonico y verifica tu cuenta</p>

    <button (click)="closeModal()" type="button" class="btn-rojo btn-modal-inline py-2 mt-5">Aceptar</button>
  </div>
</div>
