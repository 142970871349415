import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Client } from '../models/client.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private http: HttpClient
   ) { }

  getClient(id:any):Observable<Client>{
    return this.http.get<Client>(`${environment.baseUrl}/clients/${id}/`)
  }

  setClientAddress(formData){
    return this.http.post(`${environment.baseUrl}/clients/address/update`, formData)
  }

  updateClientAddress(formData){
    return this.http.put(`${environment.baseUrl}/clients/address/update`, formData)
  }

  setClientUpdateDataAccount(formData, id){
    return this.http.put(`${environment.baseUrl}/clients/account/data/${id}/`, formData)
  }

  setClientPhones(formData){
    return this.http.post(`${environment.baseUrl}/clients/phones/update`, formData)
  }

}
