import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { itemsSelect } from 'src/app/models/form.model';
import { ProductsService } from 'src/app/services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductSingle, ComboArmado } from 'src/app/models/product-single.model';

import { MatSnackBar } from '@angular/material/snack-bar';

//-------- services -------//
import { CartService } from '../../services/cart.service';

//-------- stores -------//
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { 
        load_product_no_confirmed, 
        remove_all_product_no_confirmed 
        } from '../../store/products/products.actions';
import { exit } from 'process';


@Component({
  selector: 'app-producto-single',
  templateUrl: './producto-single.component.html',
  styleUrls: ['../food-menu/carta.component.scss']
})

export class ProductoSingleComponent implements OnInit {

  isCombo:true;
  isProduct:true;

  id:number;
  product:ProductSingle;
  comboArmado:ComboArmado[];

  categories: any[];

  observacion: string = '';

  id_ingredient_sauce: number; // get id for product o sauces
  
  nombre:string;
  descripcion:string;
  precio:number;
  foto:string;
  is_mixto:boolean;
  ingredientes:any[];
  salsas:any[];
  sabores_mixto:itemsSelect[];
  bebidas:itemsSelect[];

  bebida_id: number;
  varieties_id: number;
  /*----- solo para mostrar el check todos -----*/
  todos_salsas: boolean = false;
  todos_ingredientes: boolean = false;
  /*----- solo para mostrar el check todos -----*/

  /*-- error message --*/
  valid_bebida = true;
  valid_varieties = true;
  /*-- error message --*/
  
  allComplete: boolean = false;
  allCompleteSalsas: boolean = false;

  @ViewChild('inputN') input: ElementRef;

  constructor(
              private productService: ProductsService,
              private activatedRoute: ActivatedRoute,
              private router        : Router,
              private _snackBar     : MatSnackBar,
              private cartService   : CartService,
              private store         : Store<AppState>,
              private _location     : Location
              ) { }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      data => {
        this.id = data.id      
        if(this.router.url == "/products.products/" + data.id) {
          this.isProduct = true;
          this.productService.getProduct(data.id).subscribe(data =>
            {
              if (data.type == 1){
                this.id_ingredient_sauce = data.id; // get value from sauces o ingredientes
              }
              this.product = data,
              this.nombre = data.name,
              this.descripcion = data.description,
              this.precio = data.price,
              this.foto = data.photo,
              this.ingredientes = data.ingredientes,
              this.salsas = data.salsas,
              this.bebidas = data.sabores_bebidas,
              this.is_mixto = data.is_mixto,
              this.sabores_mixto = data.sabores_mixto,
              this.categories = data.categories
          });
        }else if(this.router.url == "/combos.combos/" + data.id){
          this.isCombo = true;
          this.productService.getCombo(data.id).subscribe(data =>
            {
              
              this.comboArmado = data,
              this.comboArmado.forEach(product => {
                if (product.product.type == 1){
                  this.id_ingredient_sauce = product.product.id; // get value from sauces o ingredientes
                }
               
                if (product.product.ingredientes){
                  this.ingredientes = product.product.ingredientes;
                }
                if (product.product.salsas){
                  this.salsas = product.product.salsas;
                }
                this.bebidas = product.product.sabores_bebidas;
                this.is_mixto = product.product.is_mixto;
                this.sabores_mixto = product.product.sabores_mixto;
              });
              this.nombre = data[0].combo.name,
              this.descripcion = data[0].combo.description,
              this.precio = data[0].combo.price,
              this.foto = data[0].combo.photo,
              this.categories = data[0].combo.categories
          });
        }
    });
  }

  more() {
    let value = parseInt(this.input.nativeElement.value, 10);
    value = isNaN(value) ? 0 : value;
    if (value <= 98){
      value++;
      this.input.nativeElement.value = value;
    }
  }

  less() {
    var value = parseInt(this.input.nativeElement.value, 10);
    value = isNaN(value) ? 0 : value;
    value < 1 ? value = 1 : '';
    value--;
    if (value == 0) { value = 1 }
    this.input.nativeElement.value = value;
  }

  updateAllComplete(){

    let todos = true;


    /*----------
    Inicio: codigo para destildar el todos
    de los ingredientes
    -----------*/
    
    todos = true;
    this.ingredientes.forEach(t => {
      if (t[0] != '6'){
        if (t.completed == false){
          todos = false;
        }else if(!(t.hasOwnProperty("completed"))){
          todos = false;
        }
      }
    });
    this.ingredientes.find(t => {if (t[0] == '6'){
      t.completed = todos
      this.todos_ingredientes = todos;
    }})

    /*----------
    Fin: codigo para destildar el todos
    de los ingredientes
    -----------*/

    /*----------
    Inicio: codigo para destildar el todos
    de las salsas
    -----------*/
    
    todos = true;
    this.salsas.forEach(t => {
      if (t[0] != '5'){
        if (t.completed == false){
          todos = false;
        }else if(!(t.hasOwnProperty("completed"))){
          todos = false;
        }
      }
    });
    this.salsas.find(t => {if (t[0] == '5'){
      t.completed = todos
      this.todos_salsas = todos;
    }})

    /*----------
    Fin: codigo para destildar el todos
    de las salsas
    -----------*/

  }

  

  setAll(completed: boolean, id:number) {
    
    if(this.comboArmado) {
      this.comboArmado.forEach(product => {
        if (product.product.ingredientes == null) {
          return;
        }
        if(product.product.id == id) {
          
          product.product.ingredientes.forEach(t => t.completed = completed);
        }
      });
    }else {
      if(this.ingredientes == null) {
        return;
      }
      
      this.ingredientes.forEach(t => t.completed = completed);

    }
  }

  setAllSalsas(completed: boolean, id:number) {
    if(this.comboArmado) {
      this.comboArmado.forEach(product => {
        if (product.product.salsas == null) {
          return;
        }
        if(product.product.id == id) {
          product.product.salsas.forEach(t => t.completed = completed);
        }
      });
    }else {
      if(this.salsas == null) {
        return;
      }
      this.salsas.forEach(t => t.completed = completed);
    }
  }


  addCart(){

    /*
      ---
      add a news items a cart
      and set ngrx
      ---
    */

    if (this.bebidas){
      if (this.bebida_id == undefined){
        this._snackBar.open(`No selecciono una bebida`, 'Cerrar', {
          duration: 3000
        })
        this.valid_bebida = false;
        return 
      }else { this.valid_bebida = true; }
    }

    if (this.sabores_mixto){
      if (this.varieties_id == undefined){
        this._snackBar.open(`No selecciono una variedad`, 'Cerrar', {
          duration: 3000
        })
        this.valid_varieties = false;
        return 
      }else { this.valid_varieties = true; }
    }

    
    
    let item  = {}
    let items = []

    let get_items = localStorage.getItem('items');

    if (get_items) { 
      items = JSON.parse(get_items);
      /*--- set element in state manager ----*/
      this.store.dispatch(remove_all_product_no_confirmed())               // remove all item from state managed
      items.forEach(element => {
        this.store.dispatch(load_product_no_confirmed({product: element})) // set state manage product no confirmed
      })
    }


    if (this.isProduct){
      item['type'] =  'products';
    }else{
      item['type'] =  'combos';
    }
    item['item_id']      =  this.id.toString();
    item['nombre']       =  this.nombre;
    item['descripcion']  =  this.descripcion;
    item['precio']       =  this.precio.toString();
    item['quantity']     =  this.input.nativeElement.value;
    item['observacion']  =  this.observacion;
    item['drink_id']     =  this.bebida_id;
    item['comment']      =  this.observacion;
    item['categories']   =  this.categories;
    item['precio_total'] =  parseFloat(this.precio.toString()) * this.input.nativeElement.value;
    
    this.store.dispatch(load_product_no_confirmed({product: item})) // set values in ngrx 
    
    items.unshift(item); // add item to array

    /*------------------
    Inicio ingredientes 
    -------------------*/
    if (this.ingredientes){
      let temp_array = []
      this.ingredientes.forEach(element => {
        temp_array.push(element)
        if (element[0] == 6 && element.hasOwnProperty("completed")){
          temp_array = []
          temp_array.push(element)
          return;
        }
      });
      this.ingredientes = temp_array;
    }
    
    let ingredientes = []
    
    if (localStorage.getItem('ingredientes')) {ingredientes = JSON.parse(localStorage.getItem('ingredientes'))}
    
    if (this.ingredientes){
      let temp = [];
      
      for (var x=0; x < parseInt(this.input.nativeElement.value); x++){
        let i = [];
        
        i.push({'id_product': this.id_ingredient_sauce})
        this.ingredientes.forEach(element => {
          if (element.hasOwnProperty("completed")){
            i.push({'id': element[0]})
          }
        })
        temp.push([i]);
      }
      ingredientes.unshift(temp);
    }else{
     ingredientes.unshift([[{'id_product': this.id_ingredient_sauce}]]); // lo cargo vacio para identificarlo
    }

    /*------------------
    Fin ingredientes 
    -------------------*/

    /*------------------
    Inicio salsas 
    -------------------*/

    if (this.salsas){
      let temp_array_salsas = []
      this.salsas.forEach(element => {
        temp_array_salsas.push(element)
        if (element[0] == 5 && element.hasOwnProperty("completed")){
          temp_array_salsas = []
          temp_array_salsas.push(element)
          return;
        }
      });
      this.salsas = temp_array_salsas;
    }

    let salsas = []

    if (localStorage.getItem('salsas')) {salsas = JSON.parse(localStorage.getItem('salsas'))}

    if (this.salsas){
      let temp_salsas = [];
      
      for (var x=0; x < parseInt(this.input.nativeElement.value); x++){
        let i = [];
        
        i.push({'id_product': this.id_ingredient_sauce})
        this.salsas.forEach(element => {
          if (element.hasOwnProperty("completed")){
            i.push({'id': element[0]})
          }
        })
        temp_salsas.push([i]);
      }
      salsas.unshift(temp_salsas);
    }else{
      salsas.unshift([[{'id_product': this.id_ingredient_sauce}]]); // lo cargo vacio para identificarlo
    }

    /*------------------
    Fin salsas 
    -------------------*/
    this.cartService.saveLocalStorage(items, salsas, ingredientes);

    this._snackBar.open(`Pedido agregago ${this.nombre}`, 'Cerrar', {
      duration: 1000
    })
    //this.router.navigate(['carrito']);
    this._location.back();
  }


}


