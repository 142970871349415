import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { Location } from '@angular/common';

/* ----- store ------ */
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import * as actions from '../../store/auth/auth.actions';
import { PhoneRequiredBehaviorService } from 'src/app/services/phone-required-behavior.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  form: FormGroup;
  error:any;
  errorVerify:boolean;
  type = 'password';
  class_eye = 'fa fa-eye'

  pass:boolean=true;

  constructor(
              public fb: FormBuilder,
              private usersService: UsersService,
              private router:Router,
              private location: Location,
              private store: Store<AppState>
              ) {
    this.form = this.fb.group({
      username: [''],
      password: [''],
    })

  }

  changePass(){
    if (this.pass){
      this.type = 'text'
      this.class_eye = 'fa fa-eye-slash'
      this.pass = false;
    }else{
      this.type = 'password'
      this.class_eye = 'fa fa-eye'
      this.pass = true;
    }
  }

  submitForm() {

    if(this.form.value.username == ''){
      this.error = 'Por favor complete el campo usuario';
      return;
    }else if(this.form.value.password == ''){
      this.error = 'Por favor complete el campo contraseña';
      return;
    }else {
      this.error = '';
    }

    var formData: any = new FormData();
    formData.append("username", this.form.get('username').value);
    formData.append("password", this.form.get('password').value);

    this.usersService.login(formData).subscribe(
      (response) => {

        localStorage.setItem('token', response['token'])                     // add token local storage
        localStorage.setItem('client_id', response['client_id'])             // add client id local storage
        localStorage.setItem('currentUser', this.form.get('username').value) // add username local storage

        let payload = {token:response['token'], username: this.form.get('username').value};
        this.store.dispatch(actions.set_token({auth: payload}))                      // set payload in ngrx

        this.router.navigate(['/mi-cuenta'])
      },
      (error) => {
        if (error.error.error_msg == 'This is a user migrated'){
          this.error = 'Se envió un correo con su nueva contraseña, por favor tenga a bien verificar su casilla';
        }else if (error.error.error_msg == 'Phone is not verified'){
          this.errorVerify = true;
        }else{
          this.error = 'Usuario y/o contraseña inválidas.';
        }
      }
    )
  }
}
