import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent{
  form: FormGroup;
  error:string = ''; 
  errorFront:string = ''; 
  errorPhone:string = '';
  errorEmpty:string = '';

  type = 'password';
  class_eye = 'fa fa-eye'

  pass:boolean=true;

  constructor(public fb: FormBuilder,
              private usersService: UsersService,
              private router: Router) {
    this.form = this.fb.group({
      name: [''],
      lastname: [''],
      email: [''],
      phone1_areacode: [''],
      phone1: [''],
      username: [''],
      password: [''],
    })
  }

  changePass(){
    if (this.pass){
      this.type = 'text'
      this.class_eye = 'fa fa-eye-slash'
      this.pass = false;
    }else{
      this.type = 'password'
      this.class_eye = 'fa fa-eye'
      this.pass = true;
    }
  }

  submitForm() {
    if(this.errorPhone.length > 0) {
      return;
    }

    if(this.form.value.name === ''){
      this.errorEmpty = 'Por favor complete el campo nombre.';
      return;
    }else if(this.form.value.lastname === ''){
      this.errorEmpty = 'Por favor complete el campo apellido.';
      return;
    }else if(this.form.value.email === ''){
      this.errorEmpty = 'Por favor complete el campo e-mail.';
      return;
    }else if(this.form.value.username === ''){
      this.errorEmpty = 'Por favor complete el campo usuario.';
      return;
    }else if(this.form.value.password === ''){
      this.errorEmpty = 'Por favor complete el campo contraseña.';
      return;
    }else {
      this.errorEmpty = '';
    }

    var formData: any = new FormData();
    formData.append("name", this.form.get('name').value);
    formData.append("lastname", this.form.get('lastname').value);
    formData.append("email", this.form.get('email').value);
    formData.append("phone1_areacode", this.form.get('phone1_areacode').value);
    formData.append("phone1", this.form.get('phone1').value);
    formData.append("username", this.form.get('username').value);
    formData.append("password", this.form.get('password').value);

    this.usersService.register(formData).subscribe(
      (response) => {
        localStorage.setItem('emailRegister', this.form.value.email),
        this.router.navigate(['/verificacion'])
      },
      (error) => {
        if(error.error.error === 'Email already exist') {
          this.errorFront = 'E-mail ya registrado.';
        }else if(error.error.error === 'Username already exist'){
          this.errorFront = 'Nombre de usuario ya registrado';
        }else if(error.error.error === 'Cliente con numero de telefono ya existente'){
          this.errorFront = 'Número de celular ya registrado.';
        }else if(error.error.error === 'Entre el area telefonica y el numero de telefono no pueden superar o ser menor a los 10 caracteres.'){
          this.errorFront = 'Entre el área telefónica y el número de teléfono no pueden superar o ser menor a los 10 caracteres.';
        }
      }
    )
  }

  //Phone validation
  areaCode() {
    let input = this.form.controls.phone1_areacode.value;
    let input_number = this.form.controls.phone1.value;
    if(input[0] === '0') {
      this.errorPhone = 'El código de área no puede empezar con 0.'
    }else if (isNaN(input)){
      this.errorPhone = 'Ingrese caracteres válidos.';
    }else if(!(input.length == 3 || input.length == 4)) {
      this.errorPhone = 'El código de área debe contener 3 o 4 números.';
    }else {
      this.errorPhone = '';
      this.cellPhone();
    }
  }

  cellPhone() {
    let input = this.form.controls.phone1.value;
    let input_code = this.form.controls.phone1_areacode.value;
    if(input[0] === '1' && input[1] == '5') {
      this.errorPhone = 'El número de celular no puede empezar con 15.'
    }else if(isNaN(input)){
      this.errorPhone = 'Ingrese caracteres válidos.';
    }else if(!((input.length + input_code.length) == 10)) {
      this.errorPhone = 'El número de celular junto al código de área no puede ser menor o meyor a 10 números.';
    }else {
      this.errorPhone = ''; 
      this.areaCode();
    }
    
  }

}
