import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/*---- services ----*/
import { CartService } from '../../../services/cart.service';


@Component({
  selector: 'app-msg-branch-repeat',
  templateUrl: './msg-branch-repeat.component.html',
  styleUrls: ['./msg-branch-repeat.component.scss']
})
export class MsgBranchRepeatComponent {

  constructor(
    public dialogRef     : MatDialogRef<MsgBranchRepeatComponent>,
    public router        : Router,
    private modalService : NgbModal,
    @Inject(MAT_DIALOG_DATA)public data: any,
    private cart_service: CartService) {
    }

    onNoClick(): void {
      this.cart_service.removeLocalStorage(); // remove all cart
      this.router.navigate(['/carta/' + this.data['branch_id']]);
      this.modalService.dismissAll();
      this.dialogRef.close();
    }

}
