import { Component, OnInit } from '@angular/core';
import { Product, Category } from '../../models/product.model';
import { ProductsService } from '../../services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Sucursal } from '../../models/sucursal.model';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('800ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('800ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
  ]
})
export class CartaComponent implements OnInit {

  imgTodos:any = "./assets/img/cat/todos.png";
  products:Product[] = [];
  categories:Category[] = [];
  sucursales:Sucursal[] = [];
  id:number = 0;
  tipo:string;
  sucursal:number;
  sucursalNombre: string;
  id_select_category:number = 0;

  constructor(private productService: ProductsService,
              private activatedRoute: ActivatedRoute,
              public router: Router,
    ) { }

  ngOnInit(): void {
  this.activatedRoute.params.subscribe(
    data => {
      this.sucursal = data.id;
      localStorage.setItem('branch', data.id); // store branch
      this.productService.getSucursales()
        .subscribe(valor => {
          this.sucursales = valor,
          valor.forEach(item => {
            if (data.id == item.id) {
              this.sucursalNombre = item.name
            }
          })
      });
      /*this.productService.getProductsBySucursal(this.sucursal)
        .subscribe(valor => {
        this.products = valor
        });*/
    });
    this.productService.getAllCategories().subscribe(cat => {

      cat.map(element => {
        if (element.primary){
          this.id_select_category = element.id;
          this.showProductsByCategory(this.id_select_category,this.sucursal)
        }
      })
      this.categories = cat  
    }
    );


    
  }


  showProductsByCategory(id:number, suc:number) {
   this.id_select_category = id; 
   this.productService.getProductsByCategory(id, suc)
      .subscribe(valor => {
        this.products = valor
      }
    )    
  }

  checkSucursal(a:any, n:number) {
    if (a.some(item => item == n)) {
      return true;
    }
  }

  verProduct(tipo:string, id:number) {
    this.router.navigate([`/${tipo}/${id}`]);
  }

}
