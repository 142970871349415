<table class="table table-delivery" style="width:100%">
  <tbody>
    <tr>
      <th>Fecha/Hora</th>
      <th>N° Pedido</th>
      <th>Tipo pedido</th>
      <th>Total</th>
      <th>Hora aprox. de entrega</th>
      <th>Estado</th>
      <th>Sucursal</th>
      <th>Acciones</th>
    </tr>
    <tr *ngFor="let item of carts">
      <td>{{ item.cart.created | date: 'short' }}</td>
      <td>{{ item.cart.id }}</td>
      <td>{{ getDeliveryType(item.cart.type) }}</td>
      <td >${{ item.total.toFixed(2) }}</td>
      <td class="text-center">{{ getDeliveryHour(item.cart?.hour) }}</td>
      <td>{{ (!(item.cart?.confirmed))? 'Pendiente a Confirmar' : item.cart?.status?.status_desc  }}</td>
      <td>
        <ng-container *ngFor="let brand of brands">
          <ng-container *ngIf="brand?.id == item?.cart?.branch">
            {{ brand?.name }} - {{ brand?.address }}
            <a [href]="getApiWhatsapp(brand?.phone1)" class="whatsapp-button" target="_blank"><i class="fab fa-whatsapp-square"></i></a>
          </ng-container>
        </ng-container>
      </td>
      <td>
        <a class="text_link"  matTooltip="Ver detalle del pedido"  matTooltipClass="tooltip-red" [routerLink]="['/mi-cuenta/pedido/detalle/',item.cart.id]"> <i class="fa fa-eye"></i> </a> 
        <a  matTooltip="Repetir pedido"  matTooltipClass="tooltip-red" (click)="repeatCart(item.cart.id)"><i class="fas fa-redo-alt text_link"></i></a>
      </td>
    </tr>
  </tbody>
  </table>