import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http:HttpClient
  ) { }

  getPriceDelivery(){
    return this.http.get(`${environment.baseUrl}/delivery/price/`)
  }

}
