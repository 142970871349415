import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLocationArrow, faBars, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ProductsService } from '../../services/products.service';
import { Sucursal } from '../../models/sucursal.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';

/*----- components -------*/
import { MsgBranchRepeatComponent } from '../shared/msg-branch-repeat/msg-branch-repeat.component';

/*----- ngrx -----*/
import { Store }    from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { auth }     from '../../models/auth.models';
import { clear_token, set_token } from '../../store/auth/auth.actions';
import { remove_all_product_no_confirmed } from '../../store/products/products.actions'; 

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {


  @Input() collapse = false;

  clickAquiImagen:any = "./assets/img/clickaqui.png";
  imgLogo:any = "./assets/img/logo.png";
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faWhatsapp = faWhatsapp;
  location = faLocationArrow;
  faBars = faBars;
  faInfoCircle = faInfoCircle;
  sucursales:Sucursal[];
  status: boolean = false;

  /*--- if items no remove branch ---*/
  items:any = [];
  /*--------------------------------*/

  current_user:string = '';

  constructor(
              private productService: ProductsService,
              public  router: Router,
              private modalService: NgbModal,
              public  dialog: MatDialog,
              private store: Store<AppState>
              ) { }

  ngOnInit(): void {
    this.productService.getSucursales().subscribe(suc =>{
      this.sucursales = suc
    });
    if (localStorage.getItem('currentUser')){
      this.current_user = localStorage.getItem('currentUser')
      let payload = { token: localStorage.getItem('token'), username: this.current_user}
      this.store.dispatch(set_token({auth: payload})) // set username and token state manager
    }

    this.store.select('auth').subscribe(data => {
      this.current_user = data.username;
    })

    this.items = [];
    if (localStorage.hasOwnProperty('items')){
      this.items = localStorage.getItem('items');
    }



  }

  formatWhatsapp(phone){
    return `https://api.whatsapp.com/send?phone=${phone}`
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.status = changes.collapse.currentValue;
  }

  openMenu(){
    this.status = !this.status;       
  }

  open(content) {
      this.status = false;
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  verCarta(id:number, delivery_time:string) {
    this.items = [];
    
    /******* set delivery time in local storage ***********/
    delivery_time = delivery_time.split('-')[1].replace('M', '');
    localStorage.setItem('delivery_time', delivery_time)
    /******* set delivery time in local storage ***********/

    if (localStorage.hasOwnProperty('items')){
      this.items = localStorage.getItem('items');
    }
    
    if (this.items.length == 0){
      this.router.navigate(['/carta/' + id]);
      this.modalService.dismissAll();
    }else{

      if (this.controlChangeBranch(id.toString())){
        this.router.navigate(['/carta/' + id]);
        this.modalService.dismissAll();
      }else{
        const dialogRef = this.dialog.open(MsgBranchRepeatComponent, {
          width: '350px',
          height: '190px',
          data: { branch_id: id },
          disableClose:false,
          hasBackdrop:false
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result == undefined){
            this.store.dispatch(remove_all_product_no_confirmed()) // remove from state managed item no confirmed
          }
        });
      }

    }    

  }

  controlChangeBranch(current_branch){
    if (localStorage.hasOwnProperty('branch')){
      return current_branch == localStorage.getItem('branch')
    }
    localStorage.setItem('branch', current_branch)
    return true
  }

  showSucursales(){
    this.status = !this.status;
    this.router.navigate(['/sucursales'])
  }

  showContacto(){
    this.status = !this.status;
    this.router.navigate(['/contacto'])
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token'); // remove token 
    localStorage.removeItem('client_id');
    localStorage.clear();
    this.store.dispatch(clear_token());
    this.router.navigate(['/'])
  }


  loginOrAccount() {
    if(localStorage.getItem("currentUser") ){
      this.router.navigate(['/mi-cuenta/']);
    } else {
      this.router.navigate(['/login/']);
    }
    this.status = !this.status;
  }

  open_zone_delivery(){
    //alert('here redirect')
    window.location.href = "www.google.com.ar"
  }

}
