<div class="row mx-auto justify-content-center text-center">
    <div class="col-12 mt-3 ">
        <nav aria-label="breadcrumb" class="second ">
            <ol class="breadcrumb color-ol">
                <li  style="cursor: pointer;" class="breadcrumb-item font-weight-bold "><button class="btn-rojo" style="position: relative; top: -6px;" (click)="goPage('carta')" >  <i class="far fa-hand-point-left"></i> <span class="mr-md-3 mr-1"> &nbsp;Volver a la carta</span></button></li>
                <li  style="cursor: pointer;" class="breadcrumb-item font-weight-bold"><a class="black-text"  (click)="goPage('carrito')" ><span class="mr-md-3 mr-1">CARRITO</span></a></li>
                <li  style="cursor: pointer;" class="breadcrumb-item font-weight-bold"><a class="black-text"  (click)="goPage('checkout')"><span class="mr-md-3 mr-1">CHECKOUT</span></a></li>
            </ol>
        </nav>
    </div>
</div>
