import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-phone-required-message',
  templateUrl: './phone-required-message.component.html',
  styleUrls: ['./phone-required-message.component.scss']
})
export class PhoneRequiredMessageComponent {

  constructor(
    public dialogRef: MatDialogRef<PhoneRequiredMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public closeModal() {
    this.dialogRef.close();
  }

}
