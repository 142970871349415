import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CartaComponent } from './components/food-menu/carta.component';
import { HomeComponent } from './components/home/home.component';
import { SucursalesComponent } from './components/sucursales/sucursales.component';
import { ProductoSingleComponent } from './components/product-single/producto-single.component';
import { LoginComponent } from './components/login/login.component';
import { Login2Component } from './components/login2/login2.component';
import { RegisterComponent } from './components/register/register.component';
import { CuentaComponent } from './components/account/cuenta.component';
import { SmsVerificationComponent } from './components/sms-verification/sms-verification.component';
import { SendCodeComponent } from './components/send-code/send-code.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContactComponent } from './components/contact/contact.component';
import { SubscriptorsComponent } from './components/subscriptors/subscriptors.component';
import { FinishCartComponent } from './components/finish-cart/finish-cart.component';

import { DetailCartComponent } from './components/account/detail-cart/detail-cart.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';

// ----- services -----//
// import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "carta",
    children: [
       {path: ":id", component: CartaComponent},
    ]
   },
   { path: "gracias/:id",
    component: FinishCartComponent
    },
   { path: "sucursales",
     component: SucursalesComponent,
   },
   {
    path: ":id/change-password/:token",
    component: RecoverPasswordComponent
   },
   {
    path: ":tipo/:id",
     component: ProductoSingleComponent
   },
   { path: "login",
    component: LoginComponent,
   },
   { path: "login2",
    component: Login2Component,
   },
   { path: "registro",
    component: RegisterComponent,
   },
   { path: "verificacion",
   component: SmsVerificationComponent,
  },
  { path: "envio-codigo",
    component: SendCodeComponent
  },
  { path: "mi-cuenta",
    component: CuentaComponent
  },
  {
    path: "mi-cuenta/pedido/detalle/:id",
    component: DetailCartComponent
  },
  { path: "recuperar",
    component: ForgotPasswordComponent,
  },
  { path: "carrito",
    component: CartComponent
  },
  { path: "finalizar-compra",
   component: CheckoutComponent
  },
  { path: "contacto",
   component: ContactComponent,
  },
  { path: "subscripcion",
   component: SubscriptorsComponent,
  },
  ];


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})


export class AppRoutingModule { }



