import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// services 
import { SubscriptorsService } from '../../services/subscriptors.service';

@Component({
  selector: 'app-subscriptors',
  templateUrl: './subscriptors.component.html',
  styleUrls: ['./subscriptors.component.scss']
})
export class SubscriptorsComponent implements OnInit {

  durationInSeconds = 5;

  subscriptionForm = new FormGroup({
    name          : new FormControl('', [Validators.required]),
    email         : new FormControl('', [Validators.required, Validators.email])
  })

  constructor(
    private subscription_service: SubscriptorsService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  onSubmit(){
    
    const formData = new FormData();
    formData.append('name', this.subscriptionForm.controls.name.value);
    formData.append('email', this.subscriptionForm.controls.email.value);
    this.subscriptionForm.reset();
    this.subscription_service.addNewsLetters(formData)
      .subscribe(data => {
        this._snackBar.open(data['success'], 'Cerrar')
      },
      error =>  { this._snackBar.open('El mensaje no se pudo enviar', 'Cerrar')})
  }

}
