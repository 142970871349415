import { createReducer, on, Action } from '@ngrx/store';

/*---- actions -----*/
import { clear_token, set_token } from './auth.actions';

/*---- models ------*/
import { auth } from '../../models/auth.models';

class AuthInitial implements auth{

    /*---------------
    initial data for 
    reducers
    --------------*/

    token   :string = ''
    username:string = ''
    
    constructor(token:string, username:string){
        this.token = token;
        this.token = username;
    }
}

export const initialState:auth = new AuthInitial('', ''); // set initial data empty

const _authreducer = createReducer(
    initialState, 
    on(set_token, (state, {auth})=>{
        return auth
    }),
    on(clear_token, (state) => {
        return new AuthInitial('', '')
    })
);

export function authreducer(state, action){
    return _authreducer(state, action);
}

