import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/*----- app state manage ----*/
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.reducer';


@Component({
  selector: 'app-button-cart',
  templateUrl: './button-cart.component.html',
  styleUrls: ['./button-cart.component.scss']
})
export class ButtonCartComponent implements OnInit {

  public cantidad = 0;
  public items_loaded : any;

  constructor(
    private router : Router,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('items')){
      let items        = localStorage.getItem('items');
      let items_json   = JSON.parse(items);
      this.cantidad    = items_json.length;
    }

    this.store.select('products_no_confirmed').subscribe(data => {
      this.items_loaded = data;
      this.cantidad     = this.items_loaded.length;
    });
    //this.cantidad      = this.items_loaded.length; 
  }

  redirectCart(){
    this.router.navigate(['/carrito']);
  }

}
