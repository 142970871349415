import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { PhoneRequiredMessageComponent } from '../components/phone-required-message/phone-required-message.component';
import { PhonesComponent } from '../components/phones/phones.component';

@Injectable({
  providedIn: 'root'
})
export class PhoneRequiredBehaviorService {

  constructor(
    public dialog: MatDialog
  ) { }

  private readonly modalRequired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set modalRequired(status: boolean) {
    this.modalRequired$.next(status);
  }

  get modalRequired(): boolean {
    return this.modalRequired$.getValue();
  }

  public showModalMessage() {
    this.modalRequired = true;
    const dialogRef = this.dialog.open(PhoneRequiredMessageComponent, {
      width: '710px',
      height: '260px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this._showModalPhone();
    });
  }

  private _showModalPhone() {
    const dialogRef = this.dialog.open(PhonesComponent, {
      width: '710px',
      height: '320px',
      disableClose: true,
      data: {
        index_phones: 0,
        code: '',
        phone: '',
        email: '',
        disableClose: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.modalRequired = false;
    });
  }

}
