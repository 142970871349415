import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// enviromments
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendComment(formData){
    return this.http.post(`${environment.baseUrl}/contacts/web/create`, formData)
  }

}
