import { Component, PipeTransform, Pipe, OnInit } from '@angular/core';
import { ProductsService } from '../../services/products.service';
import { Sucursal, Turnos } from '../../models/sucursal.model';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faDirections } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeUrl} from '@angular/platform-browser';

import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.scss']
})
export class SucursalesComponent implements OnInit {

  titulo:string = "Sucursales";

  map:any;

  sucursales:Sucursal[];
  turnos:Turnos[];
  

  faWhatsapp = faWhatsapp;
  faPhone = faPhone;
  faDirections = faDirections;

  trustedDashboardUrl : SafeUrl;

  url:string;

  numbers:number[];

  constructor(private productServices: ProductsService,
              private sanitizer: DomSanitizer) { }
              

  ngOnInit(): void {

    /*

      branch: 1
      created: "2020-11-26T17:21:38.387629-03:00"
      friday: true
      hour_from: "07:00:00"
      hour_to: "11:00:00"
      id: 1
      modified: "2020-11-26T17:21:38.394543-03:00"
      monday: true
      name: "Mañana"
      saturday: true
      sunday: false
      thursday: true
      tuesday: true
      wednesday: true

    */

      this.map = new Map({
        target: 'sucursal_0',
        layers: [
          new TileLayer({
            source: new OSM()
          })
        ],
        view: new View({
          center: olProj.fromLonLat([7.0785, 51.4614]),
          zoom: 5
        })
      }, 
      {
        target: 'sucursal_1',
        layers: [
          new TileLayer({
            source: new OSM()
          })
        ],
        view: new View({
          center: olProj.fromLonLat([7.0785, 51.4614]),
          zoom: 5
        })
      });

    this.productServices.getSucursales().subscribe(data =>{
      this.sucursales = data; 
      data.forEach(sucursal => {

        let numberTurnos = sucursal.turnos.length;
        let cantTurnos = Array.from(Array(numberTurnos).keys())
        this.numbers = cantTurnos;

      });
    });
    
  }

}
