import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptorsService {

  constructor( private http:HttpClient ) { }
  //baseUrl:string = "http://127.0.0.1/api/v1";

  addNewsLetters(formData){
    return this.http.post(`${environment.baseUrl}/subscriptors/web/create`, formData)
  }

}
