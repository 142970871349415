import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


// services 
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  durationInSeconds = 5;

  commentForm = new FormGroup({
    name          : new FormControl('', [Validators.required]),
    email         : new FormControl('', [Validators.required, Validators.email]),
    comment       : new FormControl('', [Validators.required]),
  })

  constructor(
    private contact_service: ContactService,
    private _snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
  }

  

  onSubmit(){
    
    const formData = new FormData();
    formData.append('name', this.commentForm.controls.name.value);
    formData.append('email', this.commentForm.controls.email.value);
    formData.append('comment', this.commentForm.controls.comment.value);
    this.commentForm.reset();
    this.contact_service.sendComment(formData)
      .subscribe(data => {
        this._snackBar.open(data['success'], 'Cerrar')
      },
      error =>  { this._snackBar.open('El mensaje no se pudo enviar', 'Cerrar')})
  }

}
