import { Component, OnInit, ViewChild, ElementRef, Input, HostListener, AfterViewInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

/*----------------address form --------*/
import {AddressComponent} from '../address/address.component';

//----- services -------//
import { ClientsService } from '../../services/clients.service'
import { CartService } from '../../services/cart.service';
import { SettingsService } from '../../services/settings.service';
import { VouchersService } from '../../services/vouchers.service';

//------ NgRx ----------//
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { remove_all_product_no_confirmed } from '../../store/products/products.actions'; 

import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('2000ms', style({ opacity: 2, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 1, transform: 'translateY(10px)' })),
      ]),
    ]),
  ]
})
export class CheckoutComponent implements OnInit, AfterViewInit{


  // array receiv data ----
  items = [] 
  cart_item_detail_ids = [] 
  ingredientes = []
  salsas = []

  subtotal = 0.0;
  total = 0.0;
  addresses = [];
  index_address = 0;
  price_delivery = 0.0;
  lastname = '';
  name = '';
  index_ingredientes = 0;
  cantidad_carritos:any[] = [];
  // array receiv data ----

  select_address = -1;
  address_selection = -1;


  id_cart: any = 0 // send id cart

  /*---------------- ADDRESS ------*/
  edit: boolean = false;
  /*---------------- ADDRESS ------*/

  /*------------ MercadoPago -----*/
  init_point: string  = '';
  mp_reject : boolean = false;
  /*------------ MercadoPago -----*/

  paywith_flag: boolean;

  // form controls ----
  checkoutForm = new FormGroup({
    name                        : new FormControl('', [Validators.required]),
    lastname                    : new FormControl('', [Validators.required]),
    address_extra_comment       : new FormControl('', [Validators.required]),
    phone                       : new FormControl('', [Validators.required]),
    type                        : new FormControl(''),
    paywith                     : new FormControl('', [Validators.required]),
    comment                     : new FormControl(''),
  })
  // form controls ----

   /*--------- vouchers ---*/
    voucher: any;
    error_voucher: boolean = false;
    error_voucher_text: string;
    voucher_applicated: boolean = false;
    vouchers: any;
    discount_porcent = 0;
    discount_number  = 0;
    total_before = 0;
    code_voucher_is_apply = false;
    discount_number_or_percent = 0.0;
    voucher_msg_caps_lock = false;
    /*--------- vouchers ---*/

  /*----- address ----*/
  index = -1;
  error_address: boolean = false;
  error_address_text: string;
  /*----- address ----*/

  breakpoint: number = 705; // rezise

  token:    String;
  username: String;
  branch:   String;

  paywith_mercadopago_flag: Boolean = false;


  @ViewChild('refSelectAddress') input;

  pageYoffset = 0;
  @HostListener('window:scroll', ['$event']) onScroll(event){
    this.pageYoffset = window.pageYOffset;
    window.scroll(100,100);
  }

  @HostListener('window:click', ['$event']) onClick(event){
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.voucher_msg_caps_lock = true
     } else {
      this.voucher_msg_caps_lock = false
     }
    }
   
   @HostListener('window:keydown', ['$event'])
   onKeyDown(event){
   if (event.getModifierState && event.getModifierState('CapsLock')) {
        this.voucher_msg_caps_lock = true
      } else {
        this.voucher_msg_caps_lock = false
     }
   }
   
   @HostListener('window:keyup', ['$event'])
    onKeyUp(event){
    if (event.getModifierState && event.getModifierState('CapsLock')) {
        this.voucher_msg_caps_lock = true
      } else {
        this.voucher_msg_caps_lock = false
    }
   }
   
  constructor(
    private location: Location,
    private router: Router,
    private clientsService: ClientsService,
    private activated: ActivatedRoute,
    private cartService: CartService,
    private settingService: SettingsService,
    private vouchers_service: VouchersService,
    private _snackBar: MatSnackBar,
    public  dialog: MatDialog,
    private store: Store<AppState>,
    private scroll: ViewportScroller
  ) {

    this.paywith_flag = false;
    

   }

  openDialog(): void {

    const dialogRef = this.dialog.open(AddressComponent, {
      width: '1310px',
      height: '490px',
      data: {
        index_address: this.index_address
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      this.populateAdress(0); // populate new address
     
    });

  }


  ngOnInit(): void {

    
    const element = document.querySelector('#scrollId');
    element.scrollIntoView();

    let items         = localStorage.getItem('items');
    let salsas        = localStorage.getItem('salsas');
    let ingredientes  = localStorage.getItem('ingredientes');

    this.items        = JSON.parse(items);
    this.ingredientes = JSON.parse(ingredientes);
    this.salsas       = JSON.parse(salsas);

    this.breakpoint = window.innerWidth; // my resize windows 

    this.items.forEach(i => this.total += parseInt(i.quantity) * parseFloat(i.precio));
    
    this.subtotal = this.total;

    if (localStorage.getItem('token')){
      this.token    = localStorage.getItem('token');
      this.username = localStorage.getItem('currentUser');
      this.branch   = localStorage.getItem('branch');
    }

    if (localStorage.hasOwnProperty('voucher')){
      this.total = 0.0;
      this.vouchers = JSON.parse(localStorage.getItem('voucher')); // set data vouchers
      this.error_voucher = false;
      this.error_voucher_text = '';
      this.voucher = this.vouchers['code']; //set code voucher unique
      if (this.vouchers['type'] == 1){
        // monto fijo
        this.total_before = this.total;
        this.discount_number = this.vouchers['discount'];
        this.applyDiscountPrice(this.total, this.vouchers['discount'], this.vouchers['categories']);
      }else{
        // porcentaje
        this.total_before = this.total;
        this.discount_porcent =  this.vouchers['discount'];
        this.applyDiscountPercent(this.total, this.vouchers['discount'], this.vouchers['categories']);
      }
      this.voucher_applicated = true;
    }

    this.index = -1;
    this.populateAdress();

    this.settingService.getPriceDelivery().subscribe( data =>{
      this.price_delivery = data['price_delivery'];
      /** set delivery function **/
      if (localStorage.getItem('delivery')){
        this.checkoutForm.controls['type'].setValue(localStorage.getItem('delivery'));
        if (localStorage.getItem('delivery') == '1') { this.add_or_removeDeliveryPrice(this.price_delivery); }
      }else{
        this.checkoutForm.controls['type'].setValue('1');
        this.add_or_removeDeliveryPrice(this.price_delivery);
        localStorage.setItem('delivery', '1')
      }
      /**--------------------- */
    });

    this.activated.queryParams.subscribe(params => {
      if (params.collection_status == 'rejected'){
        this.mp_reject = true;
        this.cartService.deleteCart(parseInt(localStorage.getItem('id_cart')))
        .subscribe(data => {

        })
      }
    
    });

  }

  ngAfterViewInit() {
    
  }


  onResize(event) {
    this.breakpoint = event.target.innerWidth;
  }

  applyVoucherChange(){

    if (this.voucher_msg_caps_lock) {
      this.error_voucher = true;
      this.error_voucher_text = 'Desactive la tecla mayúscula para continuar'
      this.voucher = '';
      return false;
    }else{
      this.voucher_applicated = false;
      this.applyVoucher();
    }
    
    
  }

  applyVoucher(){

    /*--------
     apply voucher 
     to cart
     id: ''
     name: ''
     code: "55889966"
     count: 1
     discount: 25
     expiration: "2021-03-12"
     id: 1
     name: "esto es un cupon de prueba"
     only_use: false
     status: true
     type: 1
    --------*/

     this.total = 0.0
     let exists_categoris_ = false;
    
      this.vouchers_service.getVoucher(this.voucher).subscribe(data => {
        this.error_voucher = false;
        this.error_voucher_text = '';
        if (data['type'] == 1){
          // monto fijo
          this.discount_number = data['discount'];
          this.discount_porcent = 0;
          this.total_before = this.total;
          exists_categoris_ = this.applyDiscountPrice(this.total, data['discount'], data['categories']);
        }else{
          // porcentaje
          this.discount_porcent = data['discount'];
          this.discount_number  = 0;
          this.total_before = this.total;
          exists_categoris_ = this.applyDiscountPercent(this.total, data['discount'], data['categories']);
        }
        if (exists_categoris_){
          this._snackBar.open(`Cupón de descuento aplicado`, 'Cerrar', { duration: 2000 })
          this.vouchers = data;
          this.saveResults();
          if (!(this.voucher_applicated)){ this.voucher_applicated = true; } // if exists categories and apply remove button
        }else{
          this.error_voucher = true;
          this.error_voucher_text = 'Este cupón no aplica a ningúno de los productos seleccionados';
        }
        
      }, 
      error => {
        this.error_voucher = true;
        if (error.error.error == 'voucher not valid') { this.error_voucher_text = 'Cupón no valido'; }
        if (error.error.error == 'voucher is inactive') { this.error_voucher_text = 'Cupón desactivado'; }
        if (this.discount_number != 0){ this.removeDiscountPrice(this.total, this.discount_number); this.discount_number = 0; }
        if (this.discount_porcent != 0) { this.removeDiscountPercent(this.total, this.discount_porcent); this.discount_porcent = 0; }
        localStorage.removeItem('voucher')
        this.total = 0.0
        this.discount_number_or_percent = 0.0
        this.items.forEach(i =>{
          this.total += parseFloat(i.precio_total)
        })
        this.add_or_removeDeliveryPrice(this.price_delivery)
      })
  }

  saveResults(){
    this.cartService.removeLocalStorage();
    
    if (this.vouchers){
      this.cartService.saveLocalStorage(this.items, this.salsas, this.ingredientes, this.vouchers);
    }else{
      this.cartService.saveLocalStorage(this.items, this.salsas, this.ingredientes);
    }
  }

  applyDiscountPercent(total: any, discount: any, categories_voucher: any){ 
    let total_temp_with_discount = 0
    let exists_categoris_ = false;
    let exists = false;
    this.total = 0;

    this.items.forEach(element => {
      element.categories.forEach(category => {

        if (categories_voucher){
          categories_voucher.forEach(category_voucher => {

            if (category.id == category_voucher.id){
              
              exists_categoris_ = true; 
              exists = true;

            }

          });
        }

      });
      if (exists){
        total_temp_with_discount += element.precio_total //- (( element.precio_total * discount ) / 100)
      }else{
        
        this.total += parseFloat(element.precio_total);
      }
      exists = false
    })

    if (exists_categoris_) {
      this.total += total_temp_with_discount - (( total_temp_with_discount * discount ) / 100);
      this.voucher_applicated = true; // <--- this is true if applicated voucher discount
    } // apply disccount if exists
    this.total += this.price_delivery;
    this.discount_number_or_percent = ( total_temp_with_discount * discount ) / 100;
    localStorage.setItem('delivery', '1')
    return exists_categoris_
  }

  applyDiscountPrice(total: any, discount: any, categories_voucher: any){ 
    let total_temp_with_discount = 0
    let exists_categoris_ = false;
    let exists = false;
    this.total = 0;

    this.items.forEach(element => {
      element.categories.forEach(category => {

        if (categories_voucher){
          categories_voucher.forEach(category_voucher => {

            if (category.id == category_voucher.id){
              
              //this.total += total_temp_with_discount;
              exists_categoris_ = true;
              exists = true; 
            }

          });
        }

      });
      if (exists){
        total_temp_with_discount += element.precio_total //- discount
      }else{
        
        this.total += parseFloat(element.precio_total);
      }
      exists = false
    })

    if (exists_categoris_) { 
      this.voucher_applicated = true
      this.total += total_temp_with_discount - discount 
    }
    this.total += this.price_delivery;
    this.discount_number_or_percent = discount;
    localStorage.setItem('delivery', '1')
    return exists_categoris_
  }

  editAddress(){
    const dialogRef = this.dialog.open(AddressComponent, {
      width: '910px',
      height: '470px',
      data: {
        index_address        : this.addresses[this.index].index_address,
        address              : this.addresses[this.index].address,
        dto                  : this.addresses[this.index].dto,
        floor                : this.addresses[this.index].floor,
        address_extra_comment: this.addresses[this.index].address_extra_comment,
        alias: this.addresses[this.index].alias,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateAdress(this.index);
      this.select_address = this.index;
    });

  }


  populateAdress(index=-1){
    /*------------
    populate address from selection 
    ------------*/


    if (localStorage.getItem('client_id')){
      this.clientsService.getClient(localStorage['client_id']).subscribe(data => {
        
        this.lastname = data.lastname;
        this.name     = data.name;

        this.addresses = []; // clear all address 

        if (data.address3){ 
          if (data.floor3 == 'null'){ data.floor3 = '' }
          if (data.dto3 == 'null'){ data.dto3 = '' }
          this.addresses.push({'address': data.address3, 'alias': data.address_alias3, 'address_extra_comment': data.address_extra_comment3, 'dto': data.dto3, 'floor': data.floor3, 'email': data.email, 'lastname': data.lastname, 'name': data.name, 'phone': data.phone1,  'areacode': data.phone1_areacode,  'type': data.phone1_type, 'index_address': 3}) 
        }else{
          this.index_address = 3; // save the index - set address
        }

        if (data.address2){ 
          if (data.floor2 == 'null'){ data.floor2 = '' }
          if (data.dto2 == 'null'){ data.dto2 = '' }
          this.addresses.push({'address': data.address2, 'alias': data.address_alias2, 'address_extra_comment': data.address_extra_comment2, 'dto': data.dto2, 'floor': data.floor2, 'email': data.email, 'lastname': data.lastname, 'name': data.name, 'phone': data.phone1,  'areacode': data.phone1_areacode,  'type': data.phone1_type, 'index_address': 2}) 
        }else{
          this.index_address = 2; // save the index - set address
        }

        if (data.address1){ 
          if (data.floor1 == 'null'){ data.floor1 = '' }
          if (data.dto1 == 'null'){ data.dto1 = '' }
          this.addresses.push({'address': data.address1, 'alias': data.address_alias1, 'address_extra_comment': data.address_extra_comment1, 'dto': data.dto1, 'floor': data.floor1, 'email': data.email, 'lastname': data.lastname, 'name': data.name, 'phone': data.phone1,  'areacode': data.phone1_areacode,  'type': data.phone1_type, 'index_address': 1}) 
        }else{
          this.index_address = 1; // save the index - set address
        }
        
        this.setValuesForm(index); // set a default value for select address        
        this.input.nativeElement.value = index
      })
    }
  }

  setValuesForm(index){
    this.index = index;
    if (index == -1 ){
      this.edit = false;
    }else{
      this.checkoutForm.controls['name'].patchValue(this.name);
      this.checkoutForm.controls['lastname'].patchValue(this.lastname);
      this.checkoutForm.controls['address_extra_comment'].patchValue(this.addresses[index].address);
      this.checkoutForm.controls['phone'].patchValue(this.addresses[index].phone);
      this.edit = true;
      this.error_address = false;
      //this.input.nativeElement.value = index
    }
  
  }

  setValueDelivery(type){
    this.checkoutForm.controls['type'].setValue(type); // set delivery 
  }

  validatePayWith(value:any, total:any):boolean { 
    // verify to pay with grand total
    return parseFloat(value) >= parseFloat(total) 
  } 

  onSubmit(){


    if (!(this.paywith_mercadopago_flag)){
      if (!(this.paywith_flag)){
        if (!(this.validatePayWith(this.checkoutForm.controls.paywith.value, this.total))){
          this.checkoutForm.controls['paywith'].setErrors({'incorrect': true});
          this._snackBar.open(`Existen errores en el formulario`, 'Cerrar', { duration: 2000, verticalPosition: 'top' })
        }
      }else{
        this.checkoutForm.controls['paywith'].setValue(0) // set zero if not pay with money
      }
    }else{
      this.checkoutForm.controls['paywith'].setValue(0) // set zero if not pay with money
    }

    if (this.index == -1){
      this.error_address = true;
      this.error_address_text = 'Cargue una dirección válida';
      this._snackBar.open(`Existen errores en el formulario`, 'Cerrar', { duration: 2000, verticalPosition: 'top' })
    }else{
      this.error_address = false;
      this.error_address_text = '';

      if (this.checkoutForm.valid){ // if form is valid

        let formData = new FormData();
        formData.append('username', localStorage.getItem('currentUser'));
        formData.append('branch', localStorage.getItem('branch'));
        formData.append('client_id', localStorage.getItem('client_id'));
  
        formData.append('type', this.checkoutForm.controls.type.value); // if delivery or freeshipping

        if (!(this.paywith_mercadopago_flag)){
          formData.append('payment_method', 'Efectivo'); // cash 
        }else{
          formData.append('payment_method', 'MercadoPago'); // card MP
        }
        formData.append('paywith', this.checkoutForm.controls.paywith.value); // get value paywith
        formData.append('comment', this.checkoutForm.controls.comment.value); 
        formData.append('client_address', this.checkoutForm.controls.address_extra_comment.value); // get address 

        formData.append('items', JSON.stringify(this.items)); // send items 

        if (this.voucher_applicated){  // if voucher applicated send voucher ------
          formData.append('voucher', this.voucher);
          formData.append('total_with_discount', this.total.toFixed(2).toString());
        };


        this.cartService.createCart(formData).subscribe(data => {

          this.init_point = '' // --> mercadopago initial point
          if (data['preference'] != undefined){ this.init_point = data['preference']['init_point'] } // --> save mercadopago initial point

          this.id_cart = data['cart_id'];
          localStorage.setItem('id_cart', this.id_cart); //<---- save localstorage id_cart, becouse i need delete this cart 

          this.store.dispatch(remove_all_product_no_confirmed()) // remove from state managed item no confirmed
          this.addItems(this.id_cart);
          if (this.vouchers) {this.applyVoucherDiscount();} // only if voucher exists
        })
      }else{
        let totalErrors:number = 0;
        Object.keys(this.checkoutForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.checkoutForm.get(key).errors;
          if (controlErrors != null) {
             totalErrors++;
             Object.keys(controlErrors).forEach(keyError => {
              });
          }
        });

        // show all errors forms ----------------
        this.checkoutForm.controls['name'].markAsDirty();
        this.checkoutForm.controls['lastname'].markAsDirty();
        this.checkoutForm.controls['address_extra_comment'].markAsDirty();
        this.checkoutForm.controls['phone'].markAsDirty();
        this.checkoutForm.controls['paywith'].markAsDirty();
      }
    }

    
  }


  checkPin(event){
    return event.key != '.' && event.key != ','
  }

  setPayWith(completed: boolean) {
    this.paywith_flag = completed;
  }



  addItems(cart_id){
    /*--------
    Agrega items y crea los detalle del carrito
    
    items example array:
    descripcion: "asdasd"
    item_id: "1"
    nombre: "prieba de compuesto"
    precio: "24"
    quantity: "2"
    type: "combos"

    request.data['cart_id']
    request.data['item_id']
    request.data['quantity']
    request.data['type']
    --------*/
      let indice = -1;
      let cart_item_id_temp = '';

      

      this.items.forEach((item, index) => {
        let formData = new FormData();
        if (item.drink_id) { 
          formData.append('drink_id', item.drink_id);
        }
        formData.append('cart_id' , cart_id);
        formData.append('item_id' , item.item_id);
        formData.append('quantity', item.quantity);
        formData.append('type'    , item.type);
        formData.append('comment' , item.comment);
        
        this.cartService.addItemCart(formData).subscribe(data => {
          /*
           example return 
          {cart_item: 335, item: 1, cart_items_details_ids: Array(4)}
          */
                data['cart_items_details_ids'].forEach(element => {
                  /*--
                    recorre los items ids recuperados desde la primer api
                    id: 989
                    product__id: 1
                    product__name: "salsa galnic"
                  ---*/
                  if (data['cart_item'] != cart_item_id_temp){ 
                    indice += 1;
                    cart_item_id_temp = data['cart_item'];
                   }
                  let element_id_temp = '';
                  if (this.ingredientes[indice]){
                    this.ingredientes[indice].forEach(ingredient => {
                      /*------ trae un ingrediente que contiene en su primer indice el id del producto  -------*/
                      if (element_id_temp != element.id){ element_id_temp = element.id }else{ return; } // en caso de que el element id nunca haya cambiado aca lo obligo
                      ingredient.forEach(i => {
                        if (i.length){
                          i.forEach(n => {
                            /*--------- recorre todos los ingredientes ---------------*/
                            if (element.product__id == i[0].id_product){ // lo comparo para ver si es ese producto
                              if (n.id) {
                                this.addIngredients(element.id, n.id);
                              } // y envio el detalle id y el ingrediente
                            }
    
                          });
                        }
                       
                      });
                    }); 
                  } 
                 
                });  
                
                indice = -1;
                cart_item_id_temp = '';
                
                data['cart_items_details_ids'].forEach(element => {
                  /*--
                    recorre los items ids recuperados desde la primer api
                    id: 989
                    product__id: 1
                    product__name: "salsa galnic"
                  ---*/
                  if (data['cart_item'] != cart_item_id_temp){ 
                    indice += 1;
                    cart_item_id_temp = data['cart_item'];
                   }
                  let element_id_temp = '';
                  if (this.salsas[indice]){
                    this.salsas[indice].forEach(ingredient => {
                      /*------ trae un ingrediente que contiene en su primer indice el id del producto  -------*/
                      
                      if (element_id_temp != element.id){ element_id_temp = element.id }else{ return; } // en caso de que el element id nunca haya cambiado aca lo obligo
                      ingredient.forEach(i => {
                       if (i.length){
                        i.forEach(n => {
                          /*--------- recorre todos los ingredientes ---------------*/
                          if (element.product__id == i[0].id_product){ // lo comparo para ver si es ese producto
                            if (n.id) {
                              this.addSauces(element.id, n.id);
                            } // y envio el detalle id y el ingrediente
                          }
  
                        });
                       }
                      
                      });
                    }); 
                  }
                 
                });            


          this.clearStorage();
        })
      });
    
  }

  addIngredients(cart_item_detail, item){
    /*-------------------
    add ingredients for combos
    {cart_item_detail: 49, item: 1}
    -------------------*/
    let formData = new FormData();
    this.cartService.addIngredient(item, cart_item_detail, formData).subscribe(data => {})
    //this.clearStorage();
  }

  addSauces(cart_item_detail, item){
    /*-------------------
    add sauces
    -------------------*/
    let formData = new FormData();
    this.cartService.addSauce(item, cart_item_detail, formData).subscribe(data => {
    })
  }

  clearStorage(){
    /*-------------------------
      remove all local storage
    ---------------------------*/
    this._snackBar.open(`Tu Pedido fue enviado con éxito`, 'Cerrar', { duration: 2000 })
    if (this.init_point == ''){
      this.router.navigate(['gracias', this.id_cart]); // redirect to thanks page
    }else{
      window.location.href = this.init_point;
    }
    

  }

  add_or_removeDeliveryPrice(price_delivery){
    /*--------------
    this function remove or add price delivery
    ---------------*/
    if (this.checkoutForm.controls.type.value == 1){
      this.total += parseFloat(price_delivery);
      localStorage.setItem('delivery', '1')
    }else{
      this.total -= parseFloat(price_delivery);
      localStorage.setItem('delivery', '2')
    }
    
  }

  redirectCart(){
    this.router.navigate(['/carrito']);
  }
  
  applyVoucherDiscount(){
    let formData = new FormData();
    formData.append('voucher_code', this.vouchers['code']);
    formData.append('pk', this.id_cart);
    formData.append('discount', this.discount_number_or_percent.toFixed(2).toString().split('.')[0]);
    this.cartService.applyDiscountCart(formData).subscribe(data => {
    })
  }

  backUrl(){
    /*--------------
     back url navigate for edit
    --------------*/
    this.router.navigate(['/finalizar-compra'])
  }

  removeDiscountPercent(total: any, discount: any){
    this.total = total + ((this.total_before * discount) / 100);
    //this.add_or_removeDeliveryPrice(this.price_delivery)
  }
  removeDiscountPrice(total: any, discount: any){ 
    this.total = total + discount;
    //this.add_or_removeDeliveryPrice(this.price_delivery)
  }

  removeDecimal(number_decimal:any){
    return parseFloat(number_decimal).toFixed(2) // remove digits decimal field
  }

  calculateQuantityTotal(price: any, quantity: any){
    let result = price * quantity;
    return result.toFixed(2)
  }

  getAddress(address , floor , dto, alias){
    let add = address;
    if (floor){ add += ' Piso: ' + floor }
    if (dto)  { add += ' Dpto.: ' + dto }
    if (alias)  { add += ' - ' + alias }
    return add
  }

}
