<div class="example-container">
    <form class="form_position" [formGroup]="userForm" (ngSubmit)="submitChangePassword()">
       
      <mat-form-field class="example-full-width">
        <mat-label>Ingrese su contraseña actual</mat-label>
        <input formControlName="password" [class.is-invalid]="!userForm.controls.password.valid && userForm.controls.password.dirty" matInput [type]="hide ? 'password' : 'text'">
        <mat-error *ngIf="userForm.controls.password.hasError('required')">
          Este campo no puede estar vacio
        </mat-error>
       
        
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
           <i class="fa fa-eye"></i>
        </button>
      </mat-form-field>
  
      <mat-form-field class="example-full-width">
        <mat-label>Ingrese la nueva contraseña</mat-label>
        <input formControlName="password_new" [class.is-invalid]="!userForm.controls.password_new.valid && userForm.controls.password_new.dirty" matInput [type]="hide ? 'password' : 'text'">
        <mat-error *ngIf="userForm.controls.password_new.hasError('required')">
          Este campo no puede estar vacio
        </mat-error>
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <i class="fa fa-eye"></i>
        </button>
      </mat-form-field>
  
      <mat-form-field class="example-full-width">
        <mat-label>Confirme su contraseña</mat-label>
        <input formControlName="password_confirmed" [class.is-invalid]="!userForm.controls.password_confirmed.valid && userForm.controls.password_confirmed.dirty" matInput [type]="hide ? 'password' : 'text'">
        <mat-error *ngIf="userForm.controls.password_confirmed.hasError('required')">
          Este campo no puede estar vacio
        </mat-error>
        
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <i class="fa fa-eye"></i>
        </button>
      </mat-form-field>
  
      <p style="color: red;" *ngIf="error_message_pass_valid">
        La contraseña no es la correcta
      </p>
  
      <p style="color: red;" *ngIf="error_message_pass">
        Las contraseñas no coinciden
      </p>
      
      <div class="col-6 button_dissapear">
        <button class="btn-rojo">Cambiar contraseña</button>
      </div>
  
      <div class="col-12 button_mobile_dissapear">
        <button class="btn-rojo">Cambiar contraseña</button>
      </div>
  
    </form>
</div>
