<div class="titulo py-1 mb-5">
    <div class="container">
      <h1>Detalle de mi pedido</h1>
    </div>
</div>
<div class="container">

  <div class="card shadow-lg my-2 p-3">
  
  <form>
    <div class="row">
        <div class="col-12 col-md-12" >
            <div class="form-group input-group-lg">
                <!--<h2>Tu Pedido <span class="small text-muted ml-2 cursor-pointer"></span></h2>-->
            </div>
            <div class="row"> 
                <div class="col-12" *ngFor="let item of items">
                    <app-detail-item [item]="item"></app-detail-item>
                </div>
            </div>
        </div>
    </div>

    <div>
       
    </div>

        <div class="row">
            
            <div class="col-6 col-md-12 detail_price_dissapear" >
                <mat-card class="example-card">
                    <mat-card-header>
                        <h2>Precio total <span class="small text-muted ml-2 cursor-pointer"></span></h2>
                        <b>$ {{ subtotal.toFixed(2)  }}</b>
                    </mat-card-header>
                    <mat-card-actions>
                        <button type="button" style="width: 180px;" (click)="repeatCart()"  class="btn-rojo">Repetir el pedido</button>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div class="col-12 col-md-12 detail_price_mobile_dissapear" >
                <mat-card class="example-card">
                    <mat-card-header>
                        <h2>Precio total <span class="small text-muted ml-2 cursor-pointer"></span></h2>
                        <b>$ {{ subtotal.toFixed(2)  }}</b>
                    </mat-card-header>
                    <mat-card-actions>
                        <button type="button" (click)="repeatCart()"  class="btn-rojo">Repetir el pedido</button>
                    </mat-card-actions>
                </mat-card>
               
            </div>
            
        </div>
              
      </form>
  
      </div>
  </div>
  