
import { ElementRef } from '@angular/core';
import { Component, ViewChild, OnInit } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

/*--- services ------*/
import { CartService } from '../../services/cart.service';
import { VouchersService } from '../../services/vouchers.service';

/*--- stores ----*/
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { remove_all_product_no_confirmed, remove_product_no_confirmed  } from '../../store/products/products.actions';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  @ViewChild('inputN') input: ElementRef;
  faTrashAlt = faTrashAlt;

  // array receiv data ----
  items = [] 
  ingredientes = []
  salsas = []
  total = 0.0;

  token:    String;
  username: String;
  branch:   String;

  /*--------- vouchers ---*/
  voucher: string;
  error_voucher: boolean = false;
  error_voucher_text: string;
  voucher_applicated: boolean = false;
  vouchers: any;
  discount_porcent = 0;
  discount_number  = 0;
  total_before = 0;
  code_voucher_is_apply = false;
   /*--------- vouchers ---*/

  constructor(
    private router          : Router,
    private cartService     : CartService,
    private vouchers_service: VouchersService,
    private _snackBar       : MatSnackBar,
    private store           : Store<AppState>
  ) { }

  ngOnInit(){

    /*---------

    items: 
    {"type":"combos","item_id":"1","nombre":"prieba de compuesto",
    "descripcion":"asdasd","precio":"24","quantity":"2","observacion":"dddd"}

    ---------*/

    let items        = localStorage.getItem('items');
    let salsas       = localStorage.getItem('salsas');
    let ingredientes = localStorage.getItem('ingredientes');

    this.items       = JSON.parse(items);

    /*this.store.dispatch(remove_all_product_no_confirmed())           
    this.items.forEach(element => {
      this.store.dispatch(load_product_no_confirmed({product: element}))
    })*/

    this.ingredientes = JSON.parse(ingredientes);
    this.salsas       = JSON.parse(salsas);
    this.items.forEach(i =>{
      this.total += parseFloat(i.precio_total)
    })

    this.total = parseFloat(this.total.toFixed(2));

    if (localStorage.hasOwnProperty('token')){
      this.token    = localStorage.getItem('token');
      this.username = localStorage.getItem('currentUser');
      this.branch   = localStorage.getItem('branch');
    }

    if (localStorage.hasOwnProperty('voucher')){
      this.total = 0.0;
      this.vouchers = JSON.parse(localStorage.getItem('voucher')); // set data vouchers
      this.error_voucher = false;
      this.error_voucher_text = '';
      this.voucher = this.vouchers['code']; //set code voucher unique
      if (this.vouchers['type'] == 1){
        // monto fijo
        this.total_before = this.total;
        this.discount_number = this.vouchers['discount'];
        this.applyDiscountPrice(this.total, this.vouchers['discount'], this.vouchers['categories']);
      }else{
        // porcentaje
        this.total_before = this.total;
        this.discount_porcent =  this.vouchers['discount'];
        this.applyDiscountPercent(this.total, this.vouchers['discount'], this.vouchers['categories']);
      }
      this.voucher_applicated = true;
    }
    
  }

  more(i) {
    /*  --- remove quanty -----*/
    if (parseInt(this.items[i].quantity) <= 98){
      this.items[i].quantity = parseInt(this.items[i].quantity) + 1; 
      this.calculateTotal(i)
    }  
  }

  less(i) {
    /* ---- add quanty ----*/
    
    this.items[i].quantity = parseInt(this.items[i].quantity) - 1; 
    if (this.items[i].quantity <= 0){ this.items[i].quantity = 1 }
    this.calculateTotal(i)
  }

  calculate_sub(price, quantity){
   let result = quantity * price
   if (!(result.toString().includes('.'))){ return result }
   return result.toFixed(2)
  }

  calculateTotal(i){
    this.items[i].precio_total = parseFloat(this.items[i].precio.toString()) * this.items[i].quantity;
    this.items[i].precio_total = this.items[i].precio_total.toFixed(2);
    this.total = 0.0
    //this.items.forEach(x => this.total += parseFloat(x.precio_total))

    if (this.vouchers){ // if vouchers exists discount
      if (this.vouchers['type'] == 1){
        // monto fijo
        this.applyDiscountPrice(this.total, this.vouchers['discount'], this.vouchers['categories']);
      }else{
        // porcentaje
        this.applyDiscountPercent(this.total, this.vouchers['discount'], this.vouchers['categories']);
      }
    }

    this.saveResults(); //save in localstorage
    //location.reload(); // refresh page 
    this.items.forEach(i =>{
      this.total += parseFloat(i.precio_total)
    } )

    this.total = parseFloat(this.total.toFixed(2));
    
  }

  saveResults(){
    this.cartService.removeLocalStorage();

    if (this.vouchers){
      this.cartService.saveLocalStorage(this.items, this.salsas, this.ingredientes, this.vouchers);
    }else{
      this.cartService.saveLocalStorage(this.items, this.salsas, this.ingredientes);
    }
  }

  confirmCart(){
    /*--------
     redirect to checkout
    --------*/
    window.scrollTo(0, 0);
    this.router.navigate(['/finalizar-compra'])
  }

  goPage(url){
    switch (url) {
      case ('carta'):
        this.router.navigate(['/carta/'+ localStorage.getItem('branch')])
        break;
      case ('carrito'):
        this.router.navigate(['/carrito'])
        break;  
      default:
        this.router.navigate(['/finalizar-compra'])
        break;
  }
}

removeDecimal(number_decimal:any){
  return parseFloat(number_decimal).toFixed(2) // remove digits decimal field
}

  delete(index){
    this.total = 0.0

    /*--------
    remove item from cart memory
    ---------*/
    if (this.items){
      //this.store.dispatch(remove_product_no_confirmed(index)) // remove from state managed item no confirmed
      this.items.splice(index, 1);
      this.ingredientes.splice(index, 1);
      this.salsas.splice(index, 1);
      this.store.dispatch(remove_product_no_confirmed(index))// remove all item from state managed 
      if (this.items.length == 0) { 
        this.voucher = ''; 
        this.voucher_applicated = false; 
        this.store.dispatch(remove_all_product_no_confirmed())// remove all item from state managed 
      }
      this.saveResults();
    }
    //location.reload(); // refresh page 

    let items        = localStorage.getItem('items');
    let salsas       = localStorage.getItem('salsas');
    let ingredientes = localStorage.getItem('ingredientes');
    this.items       = JSON.parse(items);
    this.ingredientes = JSON.parse(ingredientes);
    this.salsas       = JSON.parse(salsas);

    this.items.forEach(i =>{
      this.total += parseFloat(i.precio_total)
    })

    this.total = parseFloat(this.total.toFixed(2));
  }

  applyVoucherChange(){
    this.voucher_applicated = false;
    this.applyVoucher();
  }

  applyVoucher(){

    /*--------
     apply voucher 
     to cart
     id: ''
     name: ''
     code: "55889966"
     count: 1
     discount: 25
     expiration: "2021-03-12"
     id: 1
     name: "esto es un cupon de prueba"
     only_use: false
     status: true
     type: 1
    --------*/

     this.total = 0.0
     let exists_categoris_ = false;
    
      this.vouchers_service.getVoucher(this.voucher).subscribe(data => {
        this.error_voucher = false;
        this.error_voucher_text = '';
        if (data['type'] == 1){
          // monto fijo
          this.discount_number = data['discount'];
          this.discount_porcent = 0;
          this.total_before = this.total;
          exists_categoris_ = this.applyDiscountPrice(this.total, data['discount'], data['categories']);
        }else{
          // porcentaje
          this.discount_porcent = data['discount'];
          this.discount_number  = 0;
          this.total_before = this.total;
          exists_categoris_ = this.applyDiscountPercent(this.total, data['discount'], data['categories']);
        }
        
        if (exists_categoris_){
          this._snackBar.open(`Cupón de descuento aplicado`, 'Cerrar', { duration: 2000 })
          this.vouchers = data;
          this.saveResults();
          if (!(this.voucher_applicated)){ this.voucher_applicated = true; } // if exists categories and apply remove button
        }
        
      }, 
      error => {
        this.error_voucher = true;
        if (error.error.error == 'voucher not valid') { this.error_voucher_text = 'Cupón no valido'; }
        if (error.error.error == 'voucher is inactive') { this.error_voucher_text = 'Cupón desactivado'; }
        if (this.discount_number != 0){ this.removeDiscountPrice(this.total, this.discount_number); this.discount_number = 0; }
        if (this.discount_porcent != 0) { this.removeDiscountPercent(this.total, this.discount_porcent); this.discount_porcent = 0; }
        localStorage.removeItem('voucher')
        this.total = 0.0
        this.items.forEach(i =>{
          this.total += parseFloat(i.precio_total)
        } )

      })
  }

  applyDiscountPercent(total: any, discount: any, categories_voucher: any){ 
    let total_temp_with_discount = 0
    let exists_categoris_ = false;
    let exists = false;
    this.total = 0;

    this.items.forEach(element => {
      element.categories.forEach(category => {

        if (categories_voucher){
          categories_voucher.forEach(category_voucher => {

            if (category.id == category_voucher.id){
              
              exists_categoris_ = true; 
              exists = true;

            }

          });
        }

      });
      if (exists){
        total_temp_with_discount += element.precio_total //- (( element.precio_total * discount ) / 100)
      }else{
        
        this.total += element.precio_total;
      }
      exists = false
    })

    if (exists_categoris_) {this.total += total_temp_with_discount - (( total_temp_with_discount * discount ) / 100);} // apply disccount if exists
    return exists_categoris_
    //this.total  = total - ((total * discount) / 100) 

  }

  applyDiscountPrice(total: any, discount: any, categories_voucher: any){ 
    let total_temp_with_discount = 0
    let exists_categoris_ = false;
    let exists = false;
    this.total = 0;

    this.items.forEach(element => {
      element.categories.forEach(category => {

        if (categories_voucher){
          categories_voucher.forEach(category_voucher => {

            if (category.id == category_voucher.id){
              
              //this.total += total_temp_with_discount;
              exists_categoris_ = true;
              exists = true; 
            }

          });
        }

      });
      if (exists){
        total_temp_with_discount += element.precio_total //- discount
      }else{
        
        this.total += element.precio_total;
      }
      exists = false
    })

    if (exists_categoris_) { this.total += total_temp_with_discount - discount }
    return exists_categoris_
  }
  
  removeDiscountPercent(total: any, discount: any){
    this.total = total + ((this.total_before * discount) / 100);
  }
  removeDiscountPrice(total: any, discount: any){ 
    this.total = total + discount;
  }

}
