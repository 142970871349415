<div class="form-group input-group-lg">
  <label class="wcmca_form_label">{{ label }}&nbsp;<abbr class="required" title="obligatorio">*</abbr></label>

  <input
    ngx-google-places-autocomplete
    [options]="optionsAutocomplete"
    (onAddressChange)="onAddressChange($event)"
    type="text"
    class="form-control"
    [formControl]="control"
    placeholder="Dirección">
</div>
