import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PhoneRequiredBehaviorService } from '../services/phone-required-behavior.service';

@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {
  constructor(
    private phoneRequiredBehaviorService: PhoneRequiredBehaviorService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 409) {
              if (!this.phoneRequiredBehaviorService.modalRequired) {
                this.phoneRequiredBehaviorService.showModalMessage();
              }
            }
          }
        }
      )
    );
  }
}
