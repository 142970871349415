import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

// enviromments
import { environment } from '../../environments/environment';

import { CartItemDetail } from '../models/car.models';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private http: HttpClient
  ) { }

  addItemCart(formData){
    return this.http.post(`${environment.baseUrl}/cart/add/item/web`, formData)
  }

  deleteCart(id_cart:number){
    return this.http.delete(`${environment.baseUrl}/cart/${id_cart}/delete`)
  }

  mercadoPagoConfirm(id_cart:number, payment_id:string){
    return this.http.put(`${environment.baseUrl}/cart/mercadopago/${id_cart}/${payment_id}`, {})
  }

  createCart(formData){
    return this.http.post(`${environment.baseUrl}/cart/create`, formData)
  }

  addIngredient(ingredient, cart_item_detail, formData){
    return this.http.post(`${environment.baseUrl}/cart/item/detail/ingredient/${ingredient}/${cart_item_detail}`, formData)
  }

  addSauce(sauce, cart_item_detail, formData){
    return this.http.post(`${environment.baseUrl}/cart/item/detail/sauce/${sauce}/${cart_item_detail}`, formData)
  }

  getItemCartDetail(cart_item){
    return this.http.get(`${environment.baseUrl}/cart/item/detail/${cart_item}`)
  }

  getHistorialCart(id_client){
    return this.http.get(`${environment.baseUrl}/user/carts/historial/${id_client}`)
  }

  getDetailCart(id_cart:any):Observable<any[]>{
    return this.http.get<any[]>(`${environment.baseUrl}/user/carts/detail/${id_cart}`)
  }
  
  getDetailCartRepeat(id_cart){
    return this.http.get(`${environment.baseUrl}/user/carts/items/repeat/${id_cart}`)
  }

  applyDiscountCart(formData){
    return this.http.put(`${environment.baseUrl}/cart/apply-discount`, formData)
  }

  addLocalStorage(){

  }

  removeLocalStorage(){
    /*------
    remove all items localStorage
    ------*/
    localStorage.removeItem('items');
    localStorage.removeItem('ingredientes');
    localStorage.removeItem('salsas');
    localStorage.removeItem('delivery');
    localStorage.removeItem('voucher');
    localStorage.removeItem('discount_price');
    
  }

  saveLocalStorage(items, salsas, ingredientes, voucher=null){
    /*----
    add items to localstorage
    -----*/
    localStorage.setItem('items',       JSON.stringify(items));
    localStorage.setItem('salsas',      JSON.stringify(salsas));
    localStorage.setItem('ingredientes',JSON.stringify(ingredientes));
    if (voucher){ localStorage.setItem('voucher',JSON.stringify(voucher)) }

  }

}
