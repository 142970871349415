<div class="titulo py-1 mb-5">
  <div class="container">
    <h1>{{sucursalNombre}}</h1>
  </div>
</div>
<div class="container">

  <div class="row categorias" *ngIf="categories">
    <div class="col-3 col-sm" *ngFor="let cat of categories">
      <div class="item" (click)="showProductsByCategory(cat.id, this.sucursal)">
        <img [ngClass]="{'imgColor': cat.id == id_select_category}" src={{cat.photo}}>
        <p [ngClass]="{'pSize': cat.id == id_select_category}">{{cat.name | titlecase}}</p>
      </div>
    </div>

  </div>

  <div class="row productos mt-5" *ngIf="products" >

    <ng-container *ngFor="let product of products">
        <div @fadeSlideInOut class="col-12 col-sm-4 mb-5"> <!-- col-sm-4 mb-3 -->
          <mat-card (click)="verProduct(product.model, product.pk)">
            <div class="row">
              <div class="col-5">
                <div class="img-producto">
                  <img src="https://durum.s3.amazonaws.com/media/{{product.fields.photo}}">
                </div>
              </div>
              <div class="col-7">
                <h2>{{product.fields.name}}</h2>
                <p style="font-weight: bold;">
                  ${{product.fields.price }}
                </p>
                <p *ngIf="product.fields.description.length < 60">
                  {{ product.fields.description }}
                </p>
                <p *ngIf="product.fields.description.length >= 60">
                  {{ product.fields.description.slice(0, 90) }} ...
                </p>
            </div>
            </div>
          </mat-card>
        </div>
    </ng-container>


  </div>
</div>

<app-button-cart></app-button-cart>