<!-- Ingredientes -->
<dt class="alg-pif-dt multicheck"> &times; Ingredientes </dt>
<!--
    (1, 'Coleslaw'),
    (2, 'Cebolla con Perejil'),
    (3, 'Tomate'),
    (4, 'Lechuga'),
    (5, 'Papas Fritas'),
    (6, 'Todas')
-->
<div *ngFor="let ingredient of items_local">
    <dd class="alg-pif-dd multicheck" *ngIf="ingredient.ingredient == '6'">Todas</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="ingredient.ingredient == '5'">Papas Fritas</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="ingredient.ingredient == '4'">Lechuga</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="ingredient.ingredient == '3'">Tomate</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="ingredient.ingredient == '2'">Cebolla con Perejil</dd> 
    <dd class="alg-pif-dd multicheck" *ngIf="ingredient.ingredient == '1'">Coleslaw</dd> 
</div>
