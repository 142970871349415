

<header [class.home_background]="router.url !== '/'" [class.home]="router.url === '/'">
  <div class="navTop py-2">
    <div class="container home_dissapear" >
      <ul>
        <li *ngFor="let sucursal of sucursales">
          <fa-icon [icon]="location"></fa-icon>
          {{sucursal.name}} <br>
          <a style="text-decorations:none; color:inherit;" [href]="formatWhatsapp(sucursal.phone1)" target="_blank">
            <fa-icon [icon]="faWhatsapp"></fa-icon> {{sucursal.phone1}}</a>
          
        </li>
    
        <li>Seguinos en<br>
          <a href="https://www.facebook.com/durumarg/" target="_blank">
            <fa-icon [icon]="faFacebook"> </fa-icon>
          </a>
          <a href="https://www.instagram.com/durumarg/" target="_blank">
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
    <nav class="navbar navbar-expand-md">
      <div class="container">
      <a class="navbar-brand" [routerLink]="'/'">
        <img src={{imgLogo}} alt="Durum">
      </a>
      <button class="navbar-toggler" type="button" (click)="openMenu()">
        <fa-icon [icon]="faBars"></fa-icon>
      </button>
    
      <div class="collapse navbar-collapse d-md-flex" [ngClass]="status ? 'show' : ''">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="btn btn-lg btn-outline-primary" (click)="open(mymodal)" class="nav-link">Carta</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="showSucursales()">Sucursales</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="showContacto()">Contacto</a>
          </li>
         
          <li class="nav-item">
            <a class="nav-link" (click)="loginOrAccount()">
              Mi cuenta</a>
          </li>

          <li *ngIf="current_user" class="nav-item">
            <a class="nav-link" (click)="logout()">
              Cerrar sesión</a>
          </li>

        </ul>
      </div>
  </div>
</nav>

  <div *ngIf="router.url === '/'" class="row d-flex align-items-center justify-content-center align-home">
    <a  (click)="open(mymodal)">
      <img class="clickaqui" src={{clickAquiImagen}} alt="Click aquí" />
    </a>
  </div>
</header>

<ng-template #mymodal let-modal >
  <div class="modal-body modal-sucursal zindex">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">×</span>
    </button>
    <div class="container">
       <p class="my-4 text-center title_sucursales">SELECCIONA TU SUCURSAL</p>
      <br>

      <!-- web page -->
      <div class="container card_dissapear" *ngFor="let sucursal of sucursales" >
        <div class="card" >
          <div class="card-header style_open" *ngIf="sucursal.is_open === true && sucursal.pedidos_hasta" >Abierto hasta las {{ sucursal.pedidos_hasta.split(':')[0] + ':' + sucursal.pedidos_hasta.split(':')[1] }} hs
          </div>
          <div class="card-header style_close"  *ngIf="sucursal.is_open === false && sucursal.pedidos_desde">Cerrado abre a  las {{ sucursal.pedidos_desde.split(':')[0] + ':' + sucursal.pedidos_desde.split(':')[1] }} hs
          </div>
          <ul class="list-group list-group-flush ">
            <li class="list-group-item branches_name" style="border: 0px;">
              <div class="row">
                <div class="col-12 col-sm-6 mb-5 branch_name_div" >
                  <h3 class="sub_title">{{ sucursal.name }}</h3>
                </div>
                <div class="col-12 col-sm-6 mb-5" >
                  <span style="font-weight: 700; position: relative; top: -4px; font-size: 12px;" >Delivery: ({{sucursal.delivery_time}}) <br></span>
                  <span style="font-weight: 700; position: relative; top: -4px; font-size: 12px;" >Pick Up (20M) <br></span>
                </div>
              </div>
            </li>
            
            <li class="list-group-item branches_address"  style="border: 0px;">
              <div class="row ">
                <div class="col-12 col-sm-6 mb-5 p_address_text" >
                  <p style="font-weight: bold; text-align: center; font-style:italic;">{{ sucursal.address }}</p>
                </div>
                <div class="col-12 col-sm-6 mb-5" >
                  <a target="_blank" href="{{ sucursal.url_zone_delivery_map }}"  style="font-weight: bold; font-style:italic; color: rgb(116, 116, 143);" >Ver zona de reparto</a>
                </div>
              </div>
            </li>
            <li class="list-group-item branches_button"  style="border: 0px;">
              <div class="row">
                <button *ngIf="sucursal.is_open === false" class="btn-rojo" (click)="verCarta(sucursal.id, sucursal.delivery_time)">Hacer Pre Pedido </button>
                <button *ngIf="sucursal.is_open === true" class="btn-rojo" (click)="verCarta(sucursal.id, sucursal.delivery_time)">Hacer Pedido</button>
              </div>
            </li>
          </ul>
          
        </div>
        <br>
      </div>

       <!-- web page -->

       <!-- mobile -->
       <div class="container card_mobile_dissapear" *ngFor="let sucursal of sucursales" >
        <div class="card" >
          <div class="card-header style_open" *ngIf="sucursal.is_open === true && sucursal.pedidos_hasta" >Abierto hasta las {{ sucursal.pedidos_hasta.split(':')[0] + ':' + sucursal.pedidos_hasta.split(':')[1] }} hs
          </div>
          <div class="card-header style_close"  *ngIf="sucursal.is_open === false && sucursal.pedidos_desde">Cerrado abre a  las {{ sucursal.pedidos_desde.split(':')[0] + ':' + sucursal.pedidos_desde.split(':')[1] }} hs
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item branches_name"  style="border: 0px;">
              <div class="row branch_name_div_mobile" >
                <div class="col-12 col-sm-6 mb-5 " >
                  <h2 class="sub_title">{{ sucursal.name }}</h2>
                  
                </div>
              
              </div>
            </li>

            <li class="list-group-item branches_address"  style="border: 0px;">
              <div class="row ">
                <div class="col-12 col-sm-6 mb-5 p_address_text_mobile" >
                  <p style="font-weight: bold; text-align: center; font-style:italic;">{{ sucursal.address }}</p>
                </div>
              </div>
            </li>


            <li class="list-group-item"  style="border: 0px;">
              
              <span style="font-weight: bold; position: absolute; top: -9px; font-size: 14px;" >Delivery: ({{sucursal.delivery_time}}) <br></span>
              <span style="font-weight: bold; position: absolute; top: 8px; font-size: 14px;" >Pick Up (20M)  <br></span>

            </li>
            
            <li class="list-group-item branches_address" >
              <a target="_blank" href="{{ sucursal.url_zone_delivery_map }}" style="font-weight: bold; font-style:italic; color: rgb(116, 116, 143);" >Ver zona de reparto</a>
            </li>
            <li class="list-group-item branches_button"  style="border: 0px;">
              <div class="row">
                <button *ngIf="sucursal.is_open === false" class="btn-rojo" (click)="verCarta(sucursal.id, sucursal.delivery_time)">Hacer Pre Pedido</button>
                <button *ngIf="sucursal.is_open === true" class="btn-rojo" (click)="verCarta(sucursal.id, sucursal.delivery_time)">Hacer Pedido</button>
              </div>
            </li>
          </ul>
          
        </div>
        <br>
      </div>
       <!-- mobile -->


      <!-- <div class="row">
        <div class="col-12 col-sm-12 mb-5" *ngFor="let sucursal of sucursales" style="height: 200px; position: relative;">
              
          <h3 class="style_open" *ngIf="sucursal.is_open === true && sucursal.pedidos_hasta" >Abierto hasta las {{ sucursal.pedidos_hasta.split(':')[0] + ':' + sucursal.pedidos_hasta.split(':')[1] }}</h3>
          <h3 class="style_close" *ngIf="sucursal.is_open === false && sucursal.pedidos_desde">Cerrado abre a  las {{ sucursal.pedidos_desde.split(':')[0] + ':' + sucursal.pedidos_desde.split(':')[1] }}</h3>

          <div class="row">
            <div class="col-12 col-sm-6 mb-5 branch_name_div" >
              <h3 class="sub_title">{{ sucursal.name }}</h3>
            </div>
            <div class="col-12 col-sm-6 mb-5" >
              <span style="font-weight: bold; font-size: 11px;" >Delivery: ({{sucursal.delivery_time}}) Pick Up (20m)<br></span>
            </div>
          </div>

          <div class="row branches_address">
            <div class="col-12 col-sm-6 mb-5 p_address_text" >
              <p style="font-weight: bold; text-align: left; font-style:italic;">{{ sucursal.address }}</p>
            </div>
            <div class="col-12 col-sm-6 mb-5" >
              <a href="#" style="font-weight: bold; font-style:italic;" >Ver zona de reparto</a>
            </div>
          </div>

          <div class="row branches_button">
            <button *ngIf="sucursal.open_or_close == 'Cerrado'" class="btn-rojo" (click)="verCarta(sucursal.id)">Hacer Pre Pedido</button>
            <button *ngIf="sucursal.open_or_close == 'Abierto'" class="btn-rojo" (click)="verCarta(sucursal.id)">Hacer Pedido</button>
          </div>


            

              <p>{{sucursal.address}} - {{sucursal.location_name}}<br>
              {{sucursal.phone1}}</p>

              <div class="info">
                <div *ngIf="sucursal.is_open === true">
                  <p class="open_close">{{sucursal.open_or_close}}<br>
                    <span *ngIf="sucursal.open_or_close == 'Abierto'">Tiempo de delivery: {{sucursal.delivery_time}}<br></span>
                    <span *ngIf="sucursal.pedidos_desde">
                      Abrimos a las {{ sucursal.pedidos_desde.split(':')[0] + ':' + sucursal.pedidos_desde.split(':')[1] }} 
                    </span><br>
                    <span *ngIf="sucursal.pedidos_hasta">
                      Pedidos hasta las {{ sucursal.pedidos_hasta.split(':')[0] + ':' + sucursal.pedidos_hasta.split(':')[1] }}
                    </span><br>
                  </p>
                  <div>
                    <button *ngIf="sucursal.open_or_close == 'Cerrado'" class="btn-rojo" (click)="verCarta(sucursal.id)">Hacer Pre Pedido</button>
                    <button *ngIf="sucursal.open_or_close == 'Abierto'" class="btn-rojo" (click)="verCarta(sucursal.id)">Hacer Pedido</button>
                  </div>
                </div>
                <p *ngIf="sucursal.is_open === false" class="open_close">Sucursal cerrada<br>
                  Reapertura en {{sucursal.reopen_time}}<br>
                  <span>Sucursal cerrada por {{sucursal.close_reason}}</span>
                </p>
              </div>


        </div>
      </div>-->
    </div>
  </div>
</ng-template>