import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/*-------- material -----------*/
import {TooltipPosition} from '@angular/material/tooltip';

/*-------- services ----------*/
import { CartService } from '../../../services/cart.service';

@Component({
  selector: 'app-detail-cart',
  templateUrl: './detail-cart.component.html',
  styleUrls: ['./detail-cart.component.scss']
})
export class DetailCartComponent implements OnInit {

  public items       : any;
  public id_cart     : any;
  public cart        : any;
  public cart_detail : any;
  public drink_id    : number;
  public subtotal = 0.0;

  constructor(
    private cart_service: CartService,
    private router: Router,
    private activated: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activated.paramMap.subscribe(params =>  {
      this.cart_service.getDetailCartRepeat(params.get('id')).subscribe(data => {
        this.id_cart = params.get('id');
        this.items = data;
        this.items.forEach(element => {
          if (element.combo){
            this.subtotal += element.combo.price * element.quantity;
          }else{
            this.subtotal += element.product.price * element.quantity;
          }
        });
      })
    })
  }

  repeatCart(){
    
     /*
     description: repeat cart from historial
     only cart, no ingredientes and sauces
    */
    
     let items = []

     this.cart_service.getDetailCartRepeat(this.id_cart).subscribe(data => {
       this.cart = data;
       
       this.cart.forEach(element => {
         let item  = {}
         item['precio_total'] = 0.0
           if (element['product']){
             item['type']         =  'products';
             item['item_id']      =  element['product']['id'];
             item['nombre']       =  element['product']['name'];
             item['descripcion']  =  element['product']['description'];
             item['precio']       =  element['product']['price'];
             item['quantity']     =  element['quantity'];
             item['observacion']  =  element['cart']['comment'];
             item['drink_id']     =  this.drink_id;
             item['comment']      =  element['cart']['comment'];
             item['precio_total'] =  (element['product']['price'] * element['quantity']);
             items.push(item); // add item to array
           }else{
             item['type'] =  'combos';
             item['item_id']      =  element['combo']['id'];
             item['nombre']       =  element['combo']['name'];
             item['descripcion']  =  element['combo']['description'];
             item['precio']       =  element['combo']['price'];
             item['quantity']     =  element['quantity'];
             item['observacion']  =  element['cart']['comment'];
             item['drink_id']     =  this.drink_id;
             item['comment']      =  element['cart']['comment'];
             item['precio_total'] =  (element['combo']['price'] * element['quantity']);
             items.push(item); // add item to array
           }
           localStorage.setItem('branch', element['cart']['branch']);
           this.cart_service.saveLocalStorage(items, [], []);
           this.router.navigate(['carrito']);  
       });
     })
  
  }

}
